.content {
  composes: contentWithoutSubNav from "../../helpers.css";
}

.testCountBadge {
  display: inline-block;
  color: var(--color-gray-400);
  border-radius: 2em;
  font-weight: bold;
  float: right;
  text-align: center;
  margin-left: 0.5em;
}

.subNavigation {
  @media (min-width: 975px) {
    padding-top: 0;
  }
}

.contentHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.mainHeading {
  font-family: var(--font-family-serif);
  font-size: 2rem;
  margin: 0 0 0 0;
  font-weight: normal;
}

.searchBox {
  flex: 1 1 15em;
  order: 0;
  max-width: 25em;
}

.searchBox_hidden {
  /* XXX: element must still take up space on the page */
  visibility: hidden;
}

.navAndSearch {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 1rem;
}

.tests {
  margin-top: 2rem;
}

.alertHeading {
  font-size: 1.25rem;
  margin: 0 0 0.5rem 0;
  line-height: 1;
  color: var(--color-text-on-white);
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: var(--color-text-gray-on-background);
  padding: 5rem 1rem;
  text-align: center;
}

.emptyState__icon {
  font-size: 4rem;
  opacity: 0.2;
}

.emptyState__text {
  font-size: 1.25rem;
  opacity: 0.4;
}

.emptyStateArrow {
  color: var(--color-gray-700);
  font-size: 1.125em;
  position: relative;
  text-align: center;
  pointer-events: none;
}

.emptyStateArrow:after {
  content: "";
  background-image: url(./arrow-start.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 5em;
  width: 5em;
  margin-left: 4em;
  display: none;
}

@media (min-width: 375px) {
  .emptyStateArrow {
    padding-right: 8em;
    padding-top: 2.8em;
    text-align: right;
    position: relative;
    top: -4rem;
  }

  .emptyStateArrow:after {
    display: block;
    position: absolute;
    right: 2em;
    margin-left: 0;
    bottom: -0.75em;
  }
}

@media (min-width: 500px) {
  .emptyStateArrow {
    padding-right: 10em;
  }

  .emptyStateArrow:after {
    right: 4em;
  }
}

.pagination {
  margin-top: 2rem;
}
