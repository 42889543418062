.content {
  padding: 1rem;
}

.title {
  margin: 0;
  font-size: 1.5rem;
}

.buttonGroup {
  margin-top: 1rem;
}
