@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.statusIndicator {
  position: fixed;
  bottom: 1rem;
  max-width: calc(100vw - 2rem);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 25rem;
  padding: 1.25rem 2rem 1.25rem 1rem;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.5rem;
  font-weight: bold;
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  background: var(--color-primary-2);
  z-index: 100;
  box-shadow:
    0px 6px 4px -4px rgba(0, 0, 0, 0.04),
    0px 6px 28px 0px rgba(0, 0, 0, 0.06);

  & svg {
    animation: spin 1s linear infinite;
  }

  & button {
    margin-left: auto;
    background: none;
    border: none;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.statusIndicator_success {
  background: var(--color-teal-500);

  & svg {
    animation: none;
  }
}
