.modalContentForm {
  padding: 0 1.25rem 1.25rem;
  display: grid;
  grid-template-columns: 1fr;
}

.modalContentFormContentWrapper,
.sampleTestBlocker {
  /* Move both to the same grid cell to make sampleTestBlocker overlay */
  grid-column: 1;
  grid-row: 1;
}

.sampleTestBlocker {
  display: flex;
  flex-direction: column;
}

.sampleTestBlocker:before {
  content: "";
  min-height: 10rem;
  display: block;
  width: 100%;
  flex-grow: 1;
  background: linear-gradient(180deg, rgba(255, 254, 255, 0) 0%, white 100%);
}

.sampleTestBlockerCard {
  position: relative;
  background: var(--color-blue-200);
  border-radius: var(--border-radius-2);
  color: var(--color-blue-600);
  cursor: initial;
  gap: 1rem;
  padding: 0.75rem 1rem 0.75rem 3.5rem;
  box-sizing: border-box;
  width: 100%;
}

.sampleTestBlockerTitle {
  color: var(--color-blue-600);
  font-weight: 600;
}

.sampleTestBlockerDescription {
  margin-top: 1rem;
  color: var(--color-blue-600);
  font-size: var(--font-size-small);
  max-width: 27em;
}

.sampleTestBlockerButton {
  margin-top: 1rem;
}

.sampeTestBlockerIcon {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.modalContent {
  padding: 0 1.25rem 1.25rem;
}

.modalContent:focus {
  outline: 0;
}

.modalContentBuyCredits {
  padding: 0 1.25rem 1.5rem 1.25rem;
}

.tabPanel {
  padding: 1.25rem;
  outline: 0;
}

.tabPanel > :first-child {
  margin: 0;
}

.tabPanelByot {
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
}

.criteria {
  margin: 2rem 0 0 0;
  padding: 0;
  border: 0;
  min-width: auto;
}

.criteriaLegend {
}

.criteriaOptions {
  margin-top: 1rem;
}

.targetingWarning {
  margin: 2rem 0 0 0;
}

.checkboxItem {
}

.checkboxItem + .checkboxItem {
  margin-top: 0.75rem;
}

.targetingSummary {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.targetingSummaryItem {
  font-size: var(--font-size-small);
  line-height: var(--line-height-button);
  display: inline-block;
  padding: 0.6em 1.25em;
  box-sizing: border-box;
  border-radius: var(--border-radius-button);
  color: var(--color-blue-600);
  border: 1px solid var(--color-blue-300);
}

.summary {
  margin-top: 2rem;
}

.summaryItem {
  font-size: var(--font-size-small);
  padding: 1em 0;
  display: flex;
  gap: 0.5rem;
}

.summaryItem_total {
  font-size: 1rem;
  font-weight: bold;
}

.summaryItem + .summaryItem {
  border-top: 1px solid var(--color-border-on-light);
}

.summarySecondaryText {
  color: var(--color-blue-500);
}

.summaryItemLabel {
}
.summaryItemValue {
  margin-left: auto;
  white-space: nowrap;
}

.contactOwnerToBuy {
}

/*.summaryCredits,
.summaryPayment,
.summaryTotal {
    margin: .25rem 0 0 0;
}

.summaryCredits {
    color: var(--color-gray-500);
    font-size: 0.875rem;
}

.summaryPayment {
    font-size: 0.875rem;
}

.summaryTotal {
    font-weight: bold;
    font-size: 1em;
}*/

.footer {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.repeatType {
  flex: 1;
  display: flex;
  align-items: center;
}

/* Explore plans box */
.explorePlansBox {
  padding: 1.5rem;
  background-color: rgba(10, 10, 45, 0.09);
  border-radius: var(--border-radius-card);
}

.explorePlansBox h1 {
  margin: 0.5rem 0 0 0;
  font-size: 1.334rem;
}

.explorePlansBox p {
  font-size: var(--font-size-small);
  margin: 1rem 0 0 0;
}

.explorePlansBoxButton {
  margin: 1.5rem 0 0 0;
}

.flag {
  --inline-icon-height: 1.25em;
  --inline-icon-width: 1.25em;
  border-radius: 50%;
  margin-right: 0.5em;
}

@media (max-width: 32rem) {
  .repeatType {
    flex-basis: 100%;
    flex-shrink: 0;
  }

  .repeatTypeDropDown {
    max-width: none;
    flex-basis: 100%;
  }
}

.repeatTypeNoSubscription,
.repeatTypeNoSubscriptionLearnMore {
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
}

.repeatTypeNoSubscriptionLearnMoreIcon {
  margin-left: 0.25rem;
  font-size: 0.8em;
}

.repeatTypeNoSubscriptionTooltipText {
  background-image: url(./icon-lock.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 1.25rem;
}

.repeatTypeDropDown {
  font-size: 0.875rem;
  width: 10.5rem;
}

.repeatTypeTooltip {
  margin: 0 0.5rem;
  flex-shrink: 0;
}

.footerButtons {
  margin-left: auto;
}

.secondModalWindow {
  margin-top: 1rem;
}

.footerThankYou {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem 1rem;
}

.invitationDescription {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.enableInvitationLink {
  margin-top: 1.5rem;
}

.invitationLinkWrapper {
  margin-top: 1.5rem;
  display: flex;
}

.invitationLinkCopyButton {
  margin-left: 1rem;
  flex-shrink: 0;
}

.exampleEmail {
  font-family: monospace;
  font-size: var(--font-size-small);
  background: var(--color-gray-200);
  margin-bottom: 1rem;
  margin-top: 1.1rem;
  text-align: left;
  padding: 1rem;
}

.invitationExampleEmailToggle {
  margin-top: 1rem;
}

/* Targeting with external panel */
.targetingPartnersIntro {
}

.targetingPartner {
  margin-top: 0.5rem;
}

.targetingPartner p {
  margin: 0.5rem 0 0 0;
}

.targetingPartner + .targetingPartner {
  margin-top: 2rem;
}

.partnerLearnMore {
  margin-top: 0.75rem;
}

.logoTestingTime,
.logoUserInterviews {
  width: 100%;
  display: block;
}

.logoTestingTime {
  max-width: 193px;
}

.logoUserInterviews {
  max-width: 215px;
}

.targetingHelp {
  font-size: var(--font-size-small);
  display: inline-block;
  color: var(--color-blue-400);
}

.footerTargetingPartners {
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 1rem 1rem 1.5rem;
}

.screenerPreview {
}

.screenerPreview > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkRecruitViaPartnerPanels {
  margin-top: 1rem;
  font-size: var(--font-size-small);
}

.availableSessions {
  margin: 1rem 0 0 0;
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.moreSpecificTargetingLink {
  margin-top: 1rem;
  font-size: var(--font-size-small);
}

.editScreenerLink {
  font-size: var(--font-size-small);
  color: var(--color-blue-500);
}

.changeTargeting {
  margin-top: 1rem;
  display: block;
  font-size: var(--font-size-small);
}

.infoBoxHaventReceivedTrialSessions a {
  color: inherit;
}

.infoBoxHaventReceivedTrialSessions {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  justify-content: space-between;
}
