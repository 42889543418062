.modal {
  width: 100%;
  max-width: 30rem;
}

.modalContent {
  padding: 0 1.25rem 0 1.25rem;
}

.modalContent > :first-child {
  margin-top: 0;
}

.footer {
  padding: 0 1rem 1rem 1rem;
  margin-top: 2rem;
}

.subline {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}
