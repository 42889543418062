/* Import default global styles from library */
/* Note: also imports default global variables */
@import "resources/fonts/ub_marketing_page.css";

html {
  font-size: 16px;
}

body {
  letter-spacing: 0;
  font-weight: 500;
  font-style: normal;
  color: var(--color-text-body);
  line-height: 1.4;
  font-family: var(--font-family-base);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga", "kern";
  -moz-font-feature-settings: "liga", "kern";
  font-kerning: auto;
  direction: ltr;
  background: var(--color-background);
  min-width: 10em;
  position: relative;
}

button {
  /* iOS Safari */
  color: inherit;
}

a {
  color: #0074c2;
}

/* Removes inner shadow and rounded borders */
input {
  background-image: linear-gradient(transparent, transparent);
}

hr {
  margin: 1.5rem 0;
  border: 0;
  border-bottom: 2px solid var(--color-gray-300);
}

.card {
  background: white;
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-card);
  transition: all 0.05s ease-in;
}

@media (min-width: 975px) {
  html {
    font-size: 18px;
  }
}
