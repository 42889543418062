.actionButton {
  background: none;
  border: 0;
  height: 2rem;
  width: 2rem;
  margin-left: 0.5rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  transition:
    background-color 150ms ease 0s,
    color 150ms ease 0s;
}

.actionButton:hover {
  background: var(--color-blue-200);
}

.actionButtons {
  white-space: nowrap;
}

.actionButtons .actionButton {
  color: var(--color-blue-200);
}

.step:hover .actionButtons .actionButton {
  color: var(--color-blue-500);
}

.step {
  position: relative;
  display: block;
  background: white;
  /*border: 2px solid var(--color-blue-300);*/
  border-radius: var(--border-radius-card);
  padding: 0 1.5rem 1rem 1.5rem;
  /*z-index: 1; -> this is problematic for the dropdown, it disappers under the next step if too long */
}

.stepHandle {
  font-size: 16px; /* FontAwesome pixel perfect */
  color: var(--color-blue-400);
  text-align: center;
  padding: 0.25rem 0;
  position: relative;
  z-index: 1;
}

.stepHandle_disabled {
  opacity: 0;
  pointer-events: none;
}

.taskHeader {
  display: flex;
  justify-content: space-between;
  line-height: 1;
}

.errorMessage + .taskHeader {
  margin-top: 1rem;
}

.textArea {
  margin-top: 1rem;
  width: 100%;
  max-width: none;
}

.textArea:disabled {
  border-color: var(--color-blue-100);
  color: var(--color-text-body);
  background: none;
}

.typeDropDown {
  width: 16rem;
}

.typeDropDown_disabled * div:first-child {
  color: var(--color-text-body);
}

.typeDropDownIcon {
  margin-right: 0.5rem;
}

.typeDropDownIcon_disabled {
  color: var(--color-text-body);
}

.ratingScaleStart,
.ratingScaleEnd {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.ratingScaleEnd {
}

.ratingScaleInput {
  margin-top: 1rem;
  max-width: none;
  width: auto;
  flex-grow: 1;
}

.ratingScaleInput_disabled {
  background: none;
  border-color: var(--color-blue-100);
  color: var(--color-text-body);
}

.ratingScaleDropDownWithLabel {
  margin-top: 1rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  align-self: stretch;
  margin-right: 1rem;
}

.ratingScaleDropDownWithLabel > span {
  display: block;
  width: 7em;
}

.ratingScaleDropDown {
  align-self: stretch;
}

/* XXX: Horrible... */
.ratingScaleDropDownWithLabel_disabled * div:not(:has(svg)) {
  color: var(--color-text-body);
}

/* Multiple Choice/Single Choice */

.multipleChoiceAnswer,
.singleChoiceAnswer {
  margin-top: 1rem;
}

.multipleChoiceAnswer_disabled *,
.singleChoiceAnswer_disabled * {
  /* XXX: Not great */
  color: var(--color-text-body) !important;
}

/* Redirect */

.redirectInput {
  margin-top: 1rem;
  max-width: none;
}

.redirectInput_disabled {
  background: none;
  border-color: var(--color-blue-100);
  color: var(--color-text-body);
}

.stepWrapper:last-child .stepSeparator {
  visibility: hidden;
}

.stepWrapper_isDragging:last-child .stepSeparator {
  display: block;
}

.stepSeparator_disabled {
  opacity: 0;
  pointer-events: none;
}

.error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--color-text-error);
}

.error_addAnswer {
  padding-left: 3.4rem;
}

.checkboxAskCompleted {
  margin-top: 1rem;
}

.checkboxAskCompleted_disabled {
  color: var(--color-blue-300);

  /* XXX: This is horrible */
  & * {
    border-color: var(--color-blue-100) !important;
  }
}

.countResults {
  margin-top: 1rem;
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
}
