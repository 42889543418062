.root {
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.tooltipIcon_default {
  color: var(--color-gray-500);
}

.manuallyEnabled .tooltip,
.tooltipIcon:hover + .tooltipWrapper .tooltip.tooltip_small {
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.1s ease-out,
    top 0.1s ease-out;
  transition-delay: 0.3s;
  top: 0.5em;
}

/* Only devices supporting hover should show the tooltip on hover */
@media (hover) {
  .tooltipIcon:hover + .tooltipWrapper .tooltip {
    /* Same code as for rule ".manuallyEnabled .tooltip" */
    opacity: 1;
    visibility: visible;
    transition:
      opacity 0.1s ease-out,
      top 0.1s ease-out;
    transition-delay: 0.3s;
    top: 0.5em;
  }
}

.tooltipWrapper {
  position: absolute;
  bottom: 0;
  height: 0;
}

.tooltip {
  all: initial;
  font-family: inherit;
  background: var(--color-blue-600);
  color: white;
  opacity: 0;
  transition:
    opacity 0.1s ease-in,
    top 0.1s ease-in,
    visibility 0.1s step-end;
  visibility: hidden;
  padding: 1rem;
  position: absolute;
  font-size: 0.875rem;
  line-height: 1.4;
  z-index: 1;
  box-shadow:
    0 6px 4px -4px rgba(0, 0, 0, 0.04),
    0 6px 28px rgba(0, 0, 0, 0.06);
  width: 16rem;
  box-sizing: border-box;
  top: 0.5em;
  cursor: initial;
  text-align: left;
  white-space: normal;
  border-radius: 6px;
  /* max-width: 100vw; */ /* Set automatically in js to account for scrollbar width */
}

.tooltip_small {
  padding: 0.2em 0.7em;
  border-radius: 6px;
  width: max-content;
}

.tooltip:before {
  content: "";
  background: transparent;
  height: 0.5em;
  position: absolute;
  top: -0.5em;
  left: 0;
  right: 0;
}

.close {
  border: 0;
  padding: 0;
  top: 0.5rem;
  right: 0.5rem;
  position: absolute;
  color: var(--color-gray-600);
  cursor: pointer;
  overflow: hidden;
  text-indent: -10rem;
  background: url(./close.svg) no-repeat;
  background-size: contain;
  height: calc(1.375rem / 2);
  width: calc(1.375rem / 2);
}
