.content {
  composes: content from "../../helpers.css";
}

.card {
  padding: var(--padding-card);
}

.heading {
  margin: 0;
}
