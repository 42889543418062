.barGraph {
  font-size: 0.875rem;
  color: var(--color-blue-600);
  padding-right: 2em;
}

.barsGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0.25rem 1rem;
}

.barLabel {
  text-align: right;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.barBar {
  background: var(--color-primary-1);
  height: 1em;
  border-radius: var(--border-radius-1);
  flex-shrink: 0;
  flex-grow: 0;
}

.barValue {
  color: var(--color-blue-400);
}
