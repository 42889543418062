/* Using margin-top for Stack has the downside that items with display: none will also count... 
 so if the first item is hidden mt is applie to the second element... */
/* Or Maybe using display: none; should be avoided to be honest, this is not so helpful */

.stack_default > * + * {
  margin-top: var(--spacing-stack-default);
}

.stack_cards > * + * {
  margin-top: 2rem;
}

.stack_small > * + * {
  margin-top: 1rem;
}

.stack_xsmall > * + * {
  margin-top: 0.5rem;
}

.stack_large > * + * {
  margin-top: 2rem;
}

.stack_xlarge > * + * {
  margin-top: 2.5rem;
}
