.root {
  font-size: 0.875rem;
  display: inline-block;
  padding: 0;
  text-decoration: none;
  height: var(--main-navigation-height);
  line-height: var(--main-navigation-height);
  box-sizing: border-box;
  color: var(--color-blue-600);
}

.root--active {
  color: var(--color-blue-900);
  position: relative;
}

.root--active::before {
  content: "";
  display: block;
  background: var(--color-primary-1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 1em;
  height: 3px;
}

.root a,
.root button {
  font-weight: 600;
  padding: 0 1em;
  margin: 0;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  border: 0;
  cursor: pointer;
  height: var(--main-navigation-height);
  line-height: var(--main-navigation-height);
  white-space: nowrap;
}

.root a:hover,
.root button:hover {
  color: var(--color-blue-600);
  /*background: var(--color-blue-050);*/
}

.root--active a:hover,
.root--active button:hover {
  color: var(--color-blue-900);
  background: none;
}
