.creditsGrid {
  display: grid;
  gap: 0.75em;
  grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
}

.creditsItem {
  min-height: 3.3em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--color-blue-400);
  border-radius: var(--border-radius-card);
  border: 2px solid var(--color-border-gray);
  font-size: var(--font-size-small);
  font-weight: bold;
  padding: 0.75em;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.creditsItem:focus {
  outline: 0;
}

.creditsItem:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.creditsItemSelected {
  border-color: var(--color-blue-500);
  background-color: var(--color-blue-050);
  color: var(--color-blue-500);
}

.creditsItem:hover {
  border-color: var(--color-blue-500);
}

.creditsItemLeft,
.creditsItemRight {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  text-align: left;
}

.creditsItemRight {
  text-align: right;
}

.boldBigText {
  font-size: calc((1em - var(--font-size-small)) + 1em);
}

.blackText {
  color: var(--color-primary-2);
}

.purchaseAndMoreCreditsContainer {
  display: grid;
  gap: 1.25rem;
  margin-top: 1.5rem;
}

.purchaseCreditsButton {
  min-width: 100%;
  order: 1;
}

.needMoreCreditsText {
  font-size: var(--font-size-small);
  order: 2;
}

@media (min-width: 975px) {
  .purchaseAndMoreCreditsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .purchaseCreditsButton {
    order: 2;
    min-width: calc(33% - 0.375em);
  }

  .needMoreCreditsText {
    order: 1;
  }
}
