.taskListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  overflow: hidden;
  gap: 1rem;
}

.taskListItemHeadingDescriptionContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.taskListItemHeading {
  text-transform: capitalize;
}

.listItemDescription {
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.listItemCount {
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
  background: var(--color-blue-100);
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  font-weight: 700;
  transition:
    color 0.25s,
    background 0.25s;
}

.tagListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  overflow: hidden;
  gap: 0.5rem;
}

.tagListItemValue,
.testerListItemName {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.tagListItemValue_italic {
  font-style: italic;
}

.testerListItem {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 0.75rem;
}

.testerListItemHeadingDescriptionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.testerListItemMeta {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testerListItemStars {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  color: var(--color-blue-400);
  font-size: var(--font-size-medium);
  font-weight: 700;
  margin-right: 1.25rem;
}

/* TODO: export correct size */
.testerListItemStars svg {
  transform: scale(1.3);
  margin-right: 0.25rem;
}

.filters {
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.heading {
  color: var(--color-blue-400);
}

.clearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: var(--border-radius-button);
  border: none;
  color: var(--color-blue-300);
  cursor: pointer;
  text-decoration: underline;
  padding: 0.2rem;
  transition:
    color 0.25s,
    background-color 0.25s;
}

.clearButton:hover {
  color: var(--color-blue-500);
  background: var(--color-blue-100);
}
