.root {
  box-shadow: var(--box-shadow-slight);
  padding: 0.4em 0.7em 0.4em 1.7em;
  border-radius: var(--border-radius-button);
  width: 100%;
  max-width: 25em;
  border: 2px solid transparent;
  box-sizing: border-box;
  background-color: white; /* Safari default color for type="search" is gray */
  background-image: url(search.svg);
  background-size: 0.9em;
  background-repeat: no-repeat;
  background-position: 0.5em center;
}

.root::placeholder {
  opacity: 1;
  color: var(--color-text-placeholder);
}

.root:focus {
  outline: none;
  box-shadow: var(--box-shadow-wider);
  border-color: var(--color-background-cyan-bravo);
}

.container {
  position: relative;
}

.container_clearButtonVisible .root {
  padding-right: 1.8em;
}

.clearButton {
  font-size: 0.875em;
  position: absolute;
  top: 50%;
  margin-top: -1em;
  right: 0.25em;
  border: 0;
  background: none;
  color: var(--color-blue-500);
  cursor: pointer;
  padding: 0.5em;
}

.clearButton:hover {
  color: var(--color-blue-700);
}
