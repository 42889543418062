.content {
  padding: 2rem 1rem;
  margin: 0 auto;
  max-width: 42rem;
}

.testForm2 {
  margin-top: 2rem;
}

.backLink {
  cursor: pointer;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 48px;
  padding-top: 32px;
  border-top: 1px solid var(--color-border-transparent);
}

.footer > * {
  margin-left: 1rem;
  margin-top: 1rem;
}

.info {
  position: relative;
  padding: 1rem 1rem 1rem calc(1rem + 1.5em);
  margin-top: 1.5rem;
  background: var(--color-gray-50);
  border: 2px solid var(--color-gray-300);
  border-radius: var(--border-radius-card);
}

.infoAlert {
  background: #ffeac1;
  border: 2px solid #f7cd7b;
}

.info__icon {
  margin-right: 0.5em;
  position: absolute;
  top: calc(1rem + 0.2em);
  left: calc(1rem);
}

.getSubscriptionLink {
  color: inherit;
}

.templateSuccessInfo {
  display: flex;
  background: var(--color-secondary-2-darker);
  border-radius: var(--border-radius-2);
  padding: 1.25rem;
  font-size: 1rem;
  gap: 1rem;
  align-items: center;
  color: var(--color-gray-600);
}
