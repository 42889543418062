.root {
  padding: var(--padding-button);
  padding-left: 0;
  padding-right: 0;
  color: var(--color-logo-cyan);
  background: none;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--font-size-uppercase);
  line-height: var(--line-height-button);
}

.root:focus {
  outline: 0;
}

.root + .root {
  margin-left: 1em;
}

.fullWidth {
  width: 100%;
}
