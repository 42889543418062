.root {
  margin: 0;
  border-collapse: collapse;
  border: 0;
  width: 100%;
  border-top: 1px solid var(--color-border-gray);
  border-bottom: 1px solid var(--color-border-gray);
  font-size: 0.875rem;
}

.root td {
  vertical-align: middle;
  border-bottom: 1px solid var(--color-border-gray);
  padding: 1rem 0 1rem 1rem;
}

/*.root tr:first-child td {
  padding-top: 1.6em;
}

.root tr:last-child td {
  padding-bottom: 1.6em;
}*/

.root td:first-child {
  padding-left: 0;
}
