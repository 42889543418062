.content {
  composes: content from "../../helpers.css";
}

.formContent {
  composes: formContent from "../../helpers.css";
}

.cardHeading1 {
  margin: 0 0 1.5rem;
}

.cardHeading2 {
  font-size: 1rem;
  margin: 0 0 1.5rem;
}

.emailForm {
}

.passwordForm {
  margin-top: 2rem;
}
