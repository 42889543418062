.explorePlansBox {
  padding: 1.5rem;
  background-color: rgba(10, 10, 45, 0.09);
  border-radius: var(--border-radius-card);
}

.explorePlansBox h1 {
  margin: 0.5rem 0 0 0;
  font-size: 1.5rem;
}

.explorePlansBox p {
  margin: 1rem 0 0 0;
}

.button {
  margin: 1.5rem 0 0 0;
}
