.aiToggleButton {
  /* Container styles */
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  background: var(--color-blue-600);
  border-radius: var(--border-radius-button);
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  user-select: none;
}
.aiToggleButton_checked {
  background: linear-gradient(272deg, #15e0af 1.25%, #777dfb 98.32%);
}

.aiToggleButton:before {
  /* Button styles */
  content: "";
  background: var(--color-blue-700);
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: calc(var(--border-radius-button) - 2px);
  z-index: 0;
}

.aiToggleButton:hover:before {
  background: var(--color-blue-600);
}

.aiToggleButton span {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-blue-400);
  gap: 0.5rem;
}

.aiToggleButton:focus-within {
  outline: 2px solid var(--color-blue-500);
  outline-offset: 2px;
}

.aiToggleButton_checked span {
  /* Checked state styles */
  color: #29ccbf;
}

.aiToggleButton input {
  all: unset;
  height: 1rem;
  width: 1rem;
  background: url(./unchecked.svg) no-repeat;
  background-size: contain;
}

.aiToggleButton input:checked {
  background-image: url(./checked.svg);
}

/* Optionally add hover or focus effects */
.aiToggleButton button:hover {
  background-color: #eaeaea;
}
