.root {
}

.root > * + * {
  margin-top: 0.25rem;
}

.error {
  font-size: 0.875em;
  color: var(--color-text-error);
}
