/**
 * @license
 * MyFonts Webfont Build ID 4109392, 2021-06-25T03:58:23-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirNextLTPro-DemiIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi-italic/
 * 
 * Webfont: AvenirNextLTPro-MediumIt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium-italic/
 * 
 * Webfont: AvenirNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-medium/
 * 
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 * 
 * 
 * Webfonts copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=ecf36d23-dce5-4694-aff3-c23aa2dc1c95&fontids=6149637,6149646,6149670,6149673");

@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 600;
  src:
    url("fontcom/6149637/6afe4676-059a-4aa8-b891-29856bbcba22.woff2")
      format("woff2"),
    url("fontcom/6149637/a9817c16-2a79-49bf-8543-6c846d73a52f.woff")
      format("woff");
}

@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 600;
  font-style: italic;
  src:
    url("fontcom/6149646/e9540909-1e28-4c00-9423-10e43c828f31.woff2")
      format("woff2"),
    url("fontcom/6149646/691d198c-3c78-40b5-9ce3-c92eda0bab0f.woff")
      format("woff");
}

@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 500;
  src:
    url("fontcom/6149670/f5d4f93b-454a-43e0-9ae8-7f1271b5c0fd.woff2")
      format("woff2"),
    url("fontcom/6149670/72556e8c-c677-48d8-81ff-3eb8fc50033b.woff")
      format("woff");
}

@font-face {
  font-family: "AvenirNextLTPro";
  font-weight: 500;
  font-style: italic;
  src:
    url("fontcom/6149673/b7a66ef0-2d88-45d3-bc84-e2d6013878d9.woff2")
      format("woff2"),
    url("fontcom/6149673/351c1f33-8a8e-41c9-920c-0031d3983321.woff")
      format("woff");
}

@font-face {
  font-family: "Tiempos Text";
  font-weight: 400;
  font-style: normal;
  src: url("tiempos-text-web-regular.woff2") format("woff2");
}
