.tabs {
  background: var(--color-blue-600);
  display: flex;
  flex-direction: column;
  color: var(--color-blue-100);
}

.tabList {
  width: 100%;
  flex-shrink: 0;
  height: var(--main-navigation-height);
  box-shadow: var(--box-shadow-slight);
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
}

.tabButton {
  cursor: pointer;
  border: 0;
  background: none;
  text-decoration: none;
  color: inherit;
  padding: 0.4rem 0.5rem 0.5rem 0.5rem;
  font-size: var(--font-size-small);
  border-radius: var(--border-radius-button);
  white-space: nowrap;
  position: relative;
}

.tabButton:focus-visible {
  outline: 1px solid var(--color-blue-400);
}

.tabButton span {
  display: none;
}

.tabButton_selected {
  background: var(--color-blue-900);
}

.tabButton span {
  display: block;
}
