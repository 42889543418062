.button-text {
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background: none;
  padding: var(--padding-button);
  color: var(--color-gray-600);
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--font-size-uppercase);
  line-height: var(--line-height-button);
}

.button {
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  border: none;
  border-radius: var(--border-radius-button);
  background: var(--color-button-gray);
  padding: var(--padding-button);
  color: inherit;
  font-weight: 600;
  font-size: var(--font-size-uppercase);
  box-shadow: var(--shadow-button);
  line-height: var(--line-height-button);
}

a.button {
  display: inline-block;
  text-decoration: none;
  color: white;
}

a.button:hover {
  text-decoration: none;
}

.button--primary {
  background: var(--color-primary-2);
  color: white;
}

.button--primary:disabled {
  background: var(--color-blue-300);
}

.button:focus {
  outline: 0;
}

.button--upload {
  background: #c40f30;
}

.button--fullwidth {
  width: 100%;
}

.button--stop {
  background: #c40f30;
}

.button--stop:hover {
  background: #d21742;
}

.button-link {
  color: #2980b9;
  cursor: pointer;
  text-decoration: underline;
  border: 0;
  font-size: 1em;
  padding: 0;
  background: none;
}

.button-link--warn {
  color: red;
}

/* ErrorMessage */

.ErrorMessage {
  border-left: 2px solid var(--color-border-error);
  color: var(--color-text-error);
  padding: 0.2em 1em 0.2em 0.6em;
  display: flex;
  margin-bottom: 2rem;
}

.ErrorMessage__Icon {
}

.ErrorMessage__Message {
  margin-left: 0.5em;
}

/* Input Text */

.InputText {
  width: 100%;
  max-width: 20em;
  padding: 1em 1em;
  box-sizing: border-box;
  border: 2px solid var(--color-border-gray);
  border-radius: var(--border-radius-button);
}

.InputText:focus {
  outline: 0;
  /*border-color: var(--color-background-cyan-bravo);*/
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

.InputText--error,
.InputText:focus .InputText--error {
  outline: 0;
  border-color: var(--color-border-error);
}

.InputText--fullWidth {
  max-width: none;
}

/* Form Field */

.FormField {
  margin: 0 0 1.5rem 0;
}

.FormField__input + *,
.FormField__flex + * {
  margin-top: 0.25rem;
}

.FormField__flex .FormField__input + * {
  margin-top: 0;
}

.FormField__error {
  font-size: 0.875em;
  color: var(--color-text-error);
}

.FormField__flex {
  display: flex;
}

.FormField__flex .FormField__input {
  min-width: 0;
}

/* Label */
.Label {
  display: block;
  margin-bottom: 0.25em;
  margin-top: -0.2em;
  font-size: 0.875em;
  color: var(--color-gray-900);
  font-weight: bold;
}
