.logoBanner {
  left: 0;
  right: 0;
  position: absolute;
  top: 2rem;
  padding: 0 2rem;
  box-sizing: border-box;
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
