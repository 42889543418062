.faqs {
  margin-top: 3.5rem;
  margin-bottom: 5rem;
  overflow: hidden;
  border-top: 2px solid var(--color-secondary-2-darker);
}

.faqs__title {
  margin: 3rem 0 0 0;
}

@media (min-width: 42rem) {
  .faqs__col {
    width: calc(50% - 0.5rem);
    float: left;
    margin-right: 0.5rem;
  }

  .faqs__col + .faqs__col {
    margin-right: 0;
    margin-left: 0.5rem;
  }
}

.faqs__heading {
  margin: 0;
}

.faqs__heading {
  margin-top: 2rem;
}

.faqs__heading + p {
  margin-top: 0.25rem;
}

.faqs__logos {
  text-align: left;
}

.faqs__logos > img {
  display: inline-block;
  margin: 0 1rem 1rem 0;
}
