.toggleButton {
  /*--toggle_button-color-1: var(--color-teal-600);*/
  --toggle_button-color-1: var(--color-primary-1);
  --toggle_button-color-2: var(--color-blue-200);
  --toggle_button-color-3: white;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

/* Hide default HTML checkbox */
.toggleButton input {
  display: none;
}

/* The slider */
.slider {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  width: 3em;
  height: 1.7em;
  top: -0.1em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle_button-color-2);
  transition: 0.4s;
}

.slider + .label {
  margin-left: 0.5em;
}

.disabled .slider {
  opacity: 0.7;
  cursor: default;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.3em;
  width: 1.3em;
  left: 0.2em;
  bottom: 0.2em;
  background-color: var(--toggle_button-color-3);
  transition: 0.4s;
  box-shadow: var(--box-shadow-slight);
}

input:checked + .slider {
  background-color: var(--toggle_button-color-1);
}

input:focus + .slider {
  box-shadow: 0 0 0.1em var(--toggle_button-color-1);
}

input:checked + .slider:before {
  transform: translateX(1.3em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1.7em;
}

.slider.round:before {
  border-radius: 50%;
}

.disabled {
  color: var(--color-gray-500);
}
