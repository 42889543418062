.shareClip {
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.header h4 {
  margin: 2rem;
  font-size: 24px;
  font-weight: 700;
}

.header button {
  background: none;
  align-items: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: none;
  margin: 1rem;
  color: var(--color-blue-500);
  transition: 0.25s;
}

.header button:hover {
  cursor: pointer;
  color: var(--color-blue-600);
  background: var(--color-blue-100);
}

.header button:active {
  background: var(--color-blue-200);
}

.body {
  padding: 0 1rem;
}

.video {
  display: flex;
  position: relative;
  width: 100%;
  aspect-ratio: 1.618/1;
  background: var(--color-blue-900);
  border-radius: var(--border-radius-card);
  overflow: hidden;
}

.video:hover {
  cursor: pointer;
}

.videoDuration {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 12px;
  color: var(--color-gray-100);
  background: var(--color-gray-900);
  border-radius: 0.125rem;
  padding: 0.125rem 0.25rem;
  z-index: 1;
}

.videoPlayIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%) scale(1.5);
}

.videoPoster {
  filter: brightness(0.85);
  transition: filter 0.25s;
}

.video:hover .videoPoster {
  filter: brightness(0.75);
}

.startAtPreviewLinkContainer {
  display: flex;
  justify-content: space-between;
  margin: 2rem 1.5rem 0 1.5rem;
}

.previewLink {
  color: var(--color-blue-400);
}

.previewLink:hover {
  color: var(--color-blue-600);
}

.startAtCheckbox_disabled {
  color: var(--color-blue-400);
}

.startAtInput {
  border: none;
  outline: none;
  width: 3rem;
  height: 24px;
  text-align: center;
  box-sizing: border-box;
  border-bottom: 2px solid var(--color-gray-100);
  color: var(--color-blue-900);
}

.startAtInput_disabled {
  color: var(--color-blue-400);
}

.footer {
  margin-top: 2.5rem;
  padding: 1.5rem;
  border-top: 1px solid #e9dfdf;
  background: var(--color-background);
  border-bottom-right-radius: var(--border-radius-card);
  border-bottom-left-radius: var(--border-radius-card);
}

.footerSubsection {
  border-top: 1px solid #e9dfdf;
  margin-top: 1.5rem;
}

.copyLinkButton {
  border: none;
  background: var(--color-blue-900);
  color: var(--color-gray-100);
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: var(--border-radius-button);
  padding: 0.75rem 1rem;
  transition: background-color 0.25s;
  flex-shrink: 0;
  gap: 0.5rem;
}

.copyLinkButton:hover {
  cursor: pointer;
  background: var(--color-blue-700);
}

.copyLinkButton:active {
  background: var(--color-blue-900);
}

.copyLinkButton:disabled {
  background: var(--color-blue-200);
  color: var(--color-gray-400);
  cursor: default;
}

.disablePublicLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1.5rem 2rem 0 2rem;
  font-size: 13px;
  color: var(--color-gray-600);
}
.disablePublicLinksButton {
  background: none;
  color: #ff4018;
  border: none;
  text-decoration: underline;
  margin-top: 0.25rem;
}

.disablePublicLinksButton:hover {
  cursor: pointer;
}

.disablePublicLinksIcon {
  margin-right: 0.25rem;
  transform: scale(0.85);
}

.selectAccessIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4eae5;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  flex-shrink: 0;
  transition:
    background-color 0.5s,
    border-color 0.5s;
  border: 3px solid #e8deda;
  box-sizing: border-box;
  color: var(--color-blue-900);
}

.selectAccessIconContainer > svg {
  transform: scale(1.25);
}

.selectAccessIconContainer_public {
  background: #14e0af;
  border-color: #12d3a6;
}

.footerIconSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectAccessCopyLinkButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
}

.selectAccess {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.selectAccessDescription {
  font-size: var(--font-size-small);
  color: var(--color-gray-500);
}

.selectAccessDropdownTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.selectAccessDropdownTrigger:hover {
  cursor: pointer;
}

.selectAccessOption {
  padding: 0.5rem 1rem;
  transition: 0.25s;
  border-radius: var(--border-radius-card);
}

.selectAccessOption:not(:first-child) {
  margin-top: 0.25rem;
}

.selectAccessOption:hover {
  background: var(--color-blue-100);
  cursor: pointer;
}

.selectAccessDropdownTrigger:hover {
  cursor: pointer;
}

.selectAccessOption_active {
  background: #c7f6e7;
}

.selectAccessOption_active:hover {
  background: #a7f1d9;
}

.selectAccessOptionsContainer {
  padding: 0.25rem;
}

.selectAccessIconLabelContainer {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

@media (min-width: 550px) {
  .selectAccessCopyLinkButtonContainer {
    flex-direction: row;
    align-items: center;
  }
  .body {
    padding: 0 1.75rem;
  }
  .startAtPreviewLinkContainer {
    margin: 2rem 2.5rem 0 2.5rem;
  }
  .footer {
    padding: 2.5rem;
  }
}
