.root {
  border-left: 2px solid var(--color-border-info);
  color: var(--color-text-info);
  padding: 0.2em 1em 0.2em 0.6em;
  display: flex;
  margin-bottom: 2rem;
}

.icon {
}

.message {
  margin-left: 0.5em;
}
