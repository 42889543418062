.item {
  color: var(--color-blue-300);
  display: flex;
  user-select: text;
  cursor: text;
  --timeline-item-offset-top: -0.4rem;
  padding-top: calc(var(--timeline-item-offset-top) * -1);
}

.item + .item {
  margin-top: var(--timeline-item-offset-top);
}

.timeContent,
.content {
  position: relative;
  top: var(--timeline-item-offset-top);
}

.title {
  font-weight: bold;
}

.item *::selection {
  background: var(--color-blue-200);
  color: var(--color-blue-600);
}

.downloadClip {
  margin-top: 0.5rem;
}

/*
.downloadClip {
    display: none;
}

.content:hover .downloadClip {
    display: block;
    position: absolute;
    right: 0rem;
    top: 0rem;
}
*/

.time {
  user-select: none;
  width: 4em;
  border-right: 2px solid var(--color-blue-500);
  position: relative;
  flex-shrink: 0;
}

.item:last-child .time {
  border-right: 2px transparent;
}

.time:before {
  content: "";
  background: var(--color-blue-600);
  border: 2px solid var(--color-blue-500);
  height: 8px;
  width: 8px;
  display: block;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: -5px;
  box-sizing: border-box;
}

.timeContent {
  display: flex;
  align-items: center;
}

.time {
  transition: color 200ms ease;
}

.timeWithValidTimestamp:hover {
  cursor: pointer;
  color: var(--color-blue-200);
}

.timeWithValidTimestamp:hover .playIcon {
  opacity: 1;
}

.playIcon {
  font-size: 0.5rem;
  margin-left: 0.25rem;
  opacity: 0;
  transition: opacity 200ms ease;
}

.content {
  padding-left: 1rem;
  padding-bottom: 2rem;
  position: relative;
  width: 100%;
}

.item_transcript .content {
  padding-bottom: 1rem;
}

.response {
  margin-top: 1rem;
}

.responseSkipped {
  font-style: italic;
  margin-top: 0.5rem;
}

/* Current item and Past item in timeline styling */

.current {
  color: var(--color-blue-100);
}

.current .time:before {
  background: var(--color-blue-100);
  border-color: var(--color-blue-100);
}

.past {
  color: var(--color-blue-300);
}

.past .time:before {
  background: var(--color-blue-200);
  border-color: var(--color-blue-200);
}

.past .time {
  border-color: var(--color-blue-200);
}

.past + .item .time:after {
  border-color: var(--color-blue-200);
}

.task {
}
