.root {
  position: relative;
  max-width: 20em;
}

.input {
  display: block;
  width: 100%;
  padding: 1em 1em;
  box-sizing: border-box;
  border: 2px solid var(--color-border-gray);
  border-radius: var(--border-radius-button);
}

.input:focus {
  outline: 0;
  /*border-color: var(--color-background-cyan-bravo);*/
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

.error,
.root:focus .error {
  outline: 0;
  border-color: var(--color-border-error);
}

.fullWidth {
  max-width: none;
}

.toggleShowPassword {
  position: absolute;
  width: 1.25em;
  right: 1.2em;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggleShowPassword img {
  width: 100%;
}

.input_withShowPasswordToggle {
  padding-right: 3.25em;
}

.input_withShowPasswordToggle {
  padding-right: 3.25em;
}
