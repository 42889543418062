.formFieldTermsAndConditions {
}

.checkbox {
  font-size: var(--font-size-small);
}

.checkbox,
.checkbox a {
  color: var(--color-blue-600);
}
