.questions {
  counter-reset: question-number;
}

.questionsTitle {
  position: relative;
  font-weight: normal;
  font-size: 1rem;
  margin: 0;
}

.pageSelector + .questions,
.questionsTitle:not(:first-child) {
  margin-top: 2rem;
}

.pageSelector {
  font-size: 0.875rem;
  margin-left: -0.66rem;
}

.questionsTitle,
.answer,
.answerTesters {
  padding-left: 2.5rem;
}

.questionsTitle:before {
  counter-increment: question-number;
  content: counter(question-number);
  background: var(--color-blue-600);
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  line-height: 1.75rem;
  white-space: nowrap;
  border-radius: 1.75rem;
  font-size: 0.875rem;
  color: white;
  display: block;
  position: absolute;
  left: 0;
  top: -0.2rem;
}

.answer {
  position: relative;
}

.answerIcon {
  color: var(--color-blue-500);
  position: absolute;
  top: 0.175rem;
  left: calc(2.5rem - 1.25em - 0.5rem);
}

.answer {
  font-size: 0.875rem;
  margin-top: 1.5rem;
}

.answerTesters + .answer {
  margin-top: 1.75rem;
}

.answerType {
  color: var(--color-blue-400);
}

.answerTesters {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
