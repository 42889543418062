.noteControl {
  margin-top: 0.5rem;
  background: var(--color-blue-800);
  border-radius: var(--border-radius-button);
  border: 1px solid var(--color-blue-800);
}

.noteControl:focus-within {
  border-color: var(--color-blue-400);
}

.timestampControls {
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  border-top: 1px solid var(--color-blue-700);
  border-radius: 0 0 var(--border-radius-button) var(--border-radius-button);
  display: flex;
  align-items: baseline;
  gap: 1rem;
  font-size: var(--font-size-small);
}

.timestampControlsReplayButton {
  border: none;
  outline: none;
  background: none;
  color: var(--color-blue-300);
  display: flex;
  margin-left: auto;
  align-items: baseline;
  gap: 0.25rem;
  cursor: pointer;
  padding: 0;
}

.timestampControlsReplayButton:disabled {
  color: var(--color-blue-400);
}

.timestampControlsReplayButton > svg {
  height: 1em;
  width: 1em;
  position: relative;
  top: 0.125em;
}

.playerControlsWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.playerControls {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: var(--color-blue-700);
  border-radius: var(--border-radius-button);
}

.basePlayerControls {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  padding: 0 0.35rem;
}

.basePlayerControls > button {
  background: none;
  outline: none;
  border: none;
  color: var(--color-blue-200);
  min-width: 0;
  width: fit-content;
  padding: 0;
  margin: 0;
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.25rem;
}

.verticalDivider {
  height: 1.5rem;
  width: 2px;
  border-radius: 10rem;
  background: var(--color-blue-600);
}

.horizontalDivider {
  height: 2px;
  width: 1rem;
  border-radius: 10rem;
  background: var(--color-blue-500);
  margin: auto 0;
}

.inputContent {
  line-height: normal;
  font-size: 0.845rem;
  box-sizing: border-box;
  width: 100%;
  height: 6em;
  display: block;
  border: 0;
  background: none;
  padding: 0.5rem;
  color: inherit;
  resize: none;
  border-radius: var(--border-radius-button) var(--border-radius-button) 0 0;
}

.inputContent:disabled {
  color: var(--color-blue-500);
}

.inputContent:focus {
  outline: none;
}

.inputContent::placeholder {
  color: var(--color-blue-500);
}

.inputTimestamp {
  color: var(--color-blue-300);
  white-space: nowrap;
  outline: none;
  padding: 0;
  border: none;
  background: none;
  line-height: inherit;
  font-family: monospace;
}

.inputTimestamp::placeholder {
  color: var(--color-blue-500);
}

.inputTimestamp:disabled {
  pointer-events: none;
  color: var(--color-blue-500);
}
