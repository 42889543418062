.testTabCardContentInfoContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.image {
  width: 10rem;
  max-width: 100%;
}

.image + .title {
  margin-top: 1.75rem;
}

.title {
  font-family: var(--font-family-serif);
  font-size: 1.5rem;
}

.title + .content {
  margin-top: 1rem;
}
