.content {
  composes: content from "../../helpers.css";
}

.content > * {
  margin-top: 2rem;
}
.content > :first-child {
  margin-top: 0;
}

.explorePlansBox {
}

.usageBox {
}

.subscriptionBox {
}

.cardDetailsAndBillingAddress {
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.billingAddressCompanyName {
  color: var(--color-blue-600);
}

.buttonSaveBillingEmail {
  margin-left: 1rem;
}

/* Invoices */

.invoicesBoxTitle {
  font-size: 1.5rem;
  margin: 0;
}

.invoicesBox {
  padding: 1.5rem;
}

.invoices {
  margin-top: 1.5rem;
}

.invoice {
  padding: 1.5rem 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-on-light);
  gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.invoiceDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.invoiceDate {
  white-space: nowrap;
}

.invoiceName,
.invoicePrice {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width: 640px) {
  .invoice {
    width: auto;
    flex-wrap: nowrap;
  }

  .invoiceDetails {
    flex-grow: 1;
    display: grid;
    gap: 1rem;
    grid-template-columns: 7rem 3fr 1fr;
    width: 100%;
  }
}
