:root {
  --filters-width: 22.5rem;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  padding: 0 0.175rem;
  min-width: 1rem;
  box-sizing: border-box;
  border-radius: var(--border-radius-1);
  background-color: #007461;
  color: #c7f6e7;
  font-size: 0.75rem;
  font-weight: 700;
}

.triggerButton {
  font-size: var(--font-size-small);
  display: flex;
  background: var(--color-blue-100);
  color: var(--color-blue-600);
  padding: 0.6rem;
  gap: 0.5rem;
  border: none;
  border-radius: var(--border-radius-button);
  /* HACK add InlineIcon component */
  align-items: center;
  transition: background 0.25s;
}

.triggerButton svg {
  height: 1rem;
  width: 1rem;
}

.triggerButton:hover {
  cursor: pointer;
  background: var(--color-blue-200);
}

.triggerButton_active svg {
  color: #007461;
}

.triggerButton_active:hover {
  background: #a7f1d9;
}

.triggerButton_active {
  background: #c7f6e7;
}

.content {
  width: var(--filters-width);
  padding-top: 0.5rem;
}

.categoryLabelClearButtonContainer {
  margin: 0.5rem 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.categoryLabel {
  font-weight: 700;
}

.clearSelectionButton {
  background: none;
  border: none;
  color: var(--color-blue-600);
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.option {
  display: flex;
  align-items: center;
  list-style: none;
  color: black;
  cursor: pointer;
  transition: background 0.25s;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-button);
  overflow-x: hidden;
  gap: 0.25rem;
}

/* HACK fix this. I'm not sure how to vetically align
this checkbox inside the class above. */
.option > div {
  margin-top: 0;
  top: 0;
}

.option:hover {
  background: var(--color-blue-100);
}

/* HACK: This is a little dangerous. */
/* The span is the count element. */
.option:hover * span {
  background: var(--color-blue-400);
  color: var(--color-blue-100);
}

.option:not(:first-child) {
  margin-top: 0.5rem;
}

.option_active {
  background: #c7f6e7;
}

.option_active:hover {
  background: #a7f1d9;
}

.option:has(:focus-visible) {
  box-shadow: inset 0 0 0 2px var(--color-background-cyan-bravo);
}

.optionChildrenContainer {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
}

.options {
  margin: 0.5rem;
  max-height: calc(var(--filters-width) * 1);
  overflow-y: auto;
}

.options::-webkit-scrollbar {
  /* width: 0.75rem; */
  width: 13px;
}

.options::-webkit-scrollbar-thumb {
  background-color: #14e0af;
  padding-left: 5px;
  border: 5px solid white;
  border-radius: 10px;
}

.options::-webkit-scrollbar-button {
  display: none;
}
