.navChips {
  display: flex;
  gap: 0.5rem;
}

.item {
  background-color: #ebe1dd;
  display: inline-block;
  padding: 0.5em 0.7em 0.5em 1em;
  border-radius: 2em;
  text-decoration: none;
  font-weight: 600;
  font-size: var(--font-size-small);
  color: var(--color-blue-500);
}

.item_active {
  background-color: #c4efe0;
  color: var(--color-teal-600);
}

.count {
  font-size: 0.875em;
  opacity: 1;
  line-height: 1.5;
  margin-left: 0.5rem;
  display: inline-block;
  color: #ebe1dd;
  border-radius: 1em;
  padding: 0 0.3em;
  min-width: 1.5em;
  text-align: center;
  background: var(--color-blue-500);
}

.item_active .count {
  color: #c4efe0;
  background: var(--color-teal-600);
}
