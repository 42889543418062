.differingTestStepInfo {
  display: inline-block;
  position: relative;
}

.topInfo {
  font-size: 0.75rem;
  color: var(--color-blue-400);
}

.topInfo + * {
  margin-top: 1rem;
}

.icon {
  color: var(--color-secondary-1);
}
