div .checkbox {
  /* div to increase specificy */
  font-size: 1em;
  margin-top: 0.75rem;
}

.skipButton {
  font-size: 0.875rem;
  color: var(--color-gray-500);
  margin-top: 1rem;
  cursor: pointer;
}

.undoButton {
}

.textarea {
  height: 6em;
}
