.root {
  border-left: 2px solid var(--color-border-error);
  color: var(--color-text-error);
  padding: 0.2em 1em 0.2em 0.6em;
  display: flex;
}

.icon {
}

.message {
  margin-left: 0.5em;
}

.nomargin {
  margin-bottom: 0;
}
