.playerControlBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-blue-900);
  opacity: 1;
  z-index: 1;
  transition: opacity 0.2s ease;
  user-select: none;
}

.playerControlBar_hidden {
  opacity: 0;
}

.playerControlBar_hidden:hover {
  opacity: 1;
}

.playerControlBar_fixed {
  position: relative;
}

.controls {
  display: flex;
  gap: 12px;
  padding: 0 0.5rem 0.5rem 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 31rem) {
  .controls {
    gap: 18px;
  }
}

.progressAndDuration {
  white-space: nowrap;
  user-select: text;
  margin-right: auto;
}

.settingsPlaybackRates {
  display: block;
}

.controlPlaybackRate {
  display: none;
}

@media (min-width: 31rem) {
  .settingsPlaybackRates {
    display: none;
  }

  .settingsControl_playbackRatesOnly {
    display: none;
  }

  .controlPlaybackRate {
    display: block;
  }
}

.buttonPlaybackRate {
  border: 0;
  cursor: pointer;
  background: none;
  color: inherit;
  font: inherit;
}

.controlWithPopup {
  position: relative;
}

.controlPopup,
.settingsControlPopup {
  display: none;
  position: absolute;
  bottom: 100%;
  background: var(--color-blue-700);
  border-radius: var(--border-radius-1);
  z-index: 1;
}

.controlPopup {
  left: 50%;
  transform: translate(-50%, 0);
}

.settingsControlPopup {
  right: 0.5rem;
  bottom: 100%;
}

.controlPopup_volume {
  padding: 0.5rem;
}

.controlPopup_active {
  display: block;
}

@media (hover: hover) {
  .controlWithPopup:hover .controlPopup {
    display: block;
  }
}

.controlPopup_playbackRate {
  padding: 0.5rem 0;
}

.buttonControlPopupPlaybackRate,
.buttonSettingsSubtitles,
.buttonSettingsAirplay {
  border: 0;
  cursor: pointer;
  background: none;
  color: inherit;
  font: inherit;
  width: 100%;
  text-align: left;
  padding: 0.25rem 1rem;
  white-space: nowrap;
}

@media (hover: hover) {
  .buttonControlPopupPlaybackRate:hover,
  .buttonSettingsSubtitles:hover,
  .buttonSettingsAirplay:hover {
    background-color: var(--color-blue-600);
  }
}

.buttonControlPopupPlaybackRate_active {
  background-color: var(--color-blue-500);
}

.settingsControlPopup {
  padding: 0.5rem 0;
  width: min-content;
}

.settingsControlPopup_active {
  display: block;
}

.volumeSlider {
  width: 1rem;
  height: 10rem;
}

.volumeSliderThumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--color-blue-500);
  transition: none;
}

.volumeSliderTrack {
  left: 25%;
  right: 25%;
  border-radius: 0.25rem;
}

/* 
    The component does support selecting the 
    tracks using a '-{number}', but this does
    not work using css modules.
*/
.volumeSliderTrack:nth-child(1) {
  background: white;
}

.volumeSliderTrack:nth-child(2) {
  background: var(--color-blue-800);
}

.buttonPlay,
.buttonRewind,
.buttonForward5s,
.buttonBack5s,
.buttonPause,
.buttonMute,
.buttonEnterFullscreen,
.buttonExitFullscreen,
.buttonSettings {
  position: relative;
  font-size: 24px;
  width: 1.25em;
  height: 1.25em;
  border: 0;
  padding: 0;
  background: none;
  overflow: hidden;
  flex-shrink: 0;
  text-indent: -999px;
  border-radius: var(--border-radius-1);
  cursor: pointer;
}

@media (hover: hover) {
  .buttonPlay:hover,
  .buttonRewind:hover,
  .buttonForward5s:hover,
  .buttonBack5s:hover,
  .buttonPause:hover,
  .buttonMute:hover,
  .buttonEnterFullscreen:hover,
  .buttonExitFullscreen:hover,
  .buttonSettings:hover {
    background-color: var(--color-blue-500);
  }
}

.buttonSettings_active {
  background-color: var(--color-blue-500);
}

.buttonPlay:after,
.buttonRewind:after,
.buttonForward5s:after,
.buttonBack5s:after,
.buttonPause:after,
.buttonMute:after,
.buttonEnterFullscreen:after,
.buttonExitFullscreen:after,
.buttonSettings:after {
  content: "";
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(./player-icon-sprite.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto 1em;
}

.buttonMute_1:after {
  background-position: -16em 0;
}

.buttonMute_2:after {
  background-position: -14em 0;
}

.buttonMute_muted:after {
  background-position: -20em 0;
}

.buttonRewind:after {
  background-position: -6em 0;
}

.buttonForward5s:after {
  background-position: -12em 0;
}

.buttonBack5s:after {
  background-position: -10em 0;
}

.buttonPause:after {
  background-position: -2em 0;
}

.buttonEnterFullscreen:after {
  background-position: -22em 0;
}

.buttonExitFullscreen:after {
  background-position: -24em 0;
}

.buttonSettings:after {
  background-position: -26em 0;
}
