.root {
  display: inline-block;
  position: relative;
}

.button {
  background: #efefef;
  border: 0;
  margin: 0;
  padding: 0.5em 0.5em;
}

.button::after {
  content: "\25BC";
}

.items {
  display: none;
  min-width: 100%;
}

.items--open {
  display: block;
  position: absolute;
  width: auto;
  top: 100%;
  background: #efefef;
}

.item {
  cursor: pointer;
}

.item:hover {
  background: lightyellow;
}
