.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0.3rem;
}

.radio {
  position: absolute;
  visibility: hidden;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.content .radioIndicator {
  background: white;
  display: block;
  box-shadow:
    0 0 0 0.28em white,
    0 0 0 0.4em var(--color-blue-400);
  border-radius: 10em;
  flex-shrink: 0;
  height: 0.75em;
  width: 0.75em;
  margin-right: 1rem;
}

.radio:checked + .content .radioIndicator {
  position: relative;
  background: var(--color-logo-cyan);
  box-shadow:
    0 0 0 0.28em white,
    0 0 0 0.4em var(--color-logo-cyan);
  /*&:before {
    background: var(--color-logo-cyan);
    content: '';
    display: block;
    position: absolute;
    border-radius: 10em;
    top: 0.16em;
    left: 0.16em;
    right: 0.16em;
    bottom: 0.16em;
  }*/
}

.checked {
  font-weight: bold;
}
