.meter {
  --meter-height: 0.5rem;
  background: var(--color-blue-050);
  border-radius: var(--meter-height);
  padding: 0;
  box-sizing: border-box;
}

.meter meter {
  background: none;
  width: 100%;
  height: var(--meter-height);
  display: block;
  border: 0;
}

.meter meter::-webkit-meter-bar {
  height: var(--meter-height);
  border: 0;
  background-color: transparent;
  background-image: none;
}

.meter meter::-webkit-meter-optimum-value {
  border-radius: var(--meter-height);
  background-color: var(--color-primary-1);
  background-image: none;
  transition: all 0.15s ease;
}

.meter meter:-moz-meter-optimum::-moz-meter-bar {
  border-radius: var(--meter-height);
  background-color: var(--color-primary-1);
  background-image: none;
  transition: all 0.15s ease; /* Does not seem to work */
}
