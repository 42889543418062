.usageBox {
  padding: 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
}

.usageItems {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 1.5rem;
}

.usageBoxItem {
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
}

/* Credits full width on mobile */
.usageBoxItem:first-child {
  flex-basis: 100%;
}

@media (min-width: 975px) {
  .usageBoxItem:first-child {
    flex-basis: 0;
  }
}
