.listItem {
  margin-top: 0.5rem;
  border-radius: 0.75rem;
  transition:
    background 0.25s,
    filter 0.25s;
  position: relative;
}

.listItem_fetching {
  opacity: 0.5;
}

.listItem:hover > .listItemOverflowMenu button {
  opacity: 1;
}

.listItem:hover .listItemVideoThumbnailFallbackIcon {
  opacity: 0;
}

.listItem:hover .listItemVideoThumbnailGeneratingFeedback {
  opacity: 0;
}

.listItemVideoThumbnailFallbackIcon {
  transition: opacity 0.25s;
  opacity: 1;
}

.listItem:hover > .listItemVideoThumbnail img {
  filter: brightness(0.8);
}

.listItem:hover > .listItemVideoThumbnail .listItemVideoThumbnailPlayIcon {
  opacity: 1;
}

.listItem:hover {
  cursor: pointer;
  background: var(--color-blue-100);
}

.listItem::before {
  content: "";
  position: absolute;
  width: 15%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    var(--color-blue-100),
    var(--color-blue-100)
  );
  right: 0;
  top: 0;
  border-radius: 0.75rem;
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.25s,
    background 0.25s;
}

.listItem:hover::before {
  opacity: 1;
}

.listItem:active {
  filter: brightness(0.95);
}

.listItem_menuOpen {
  background: var(--color-blue-100);
  z-index: 2;
}

.listItem_menuOpen::before {
  opacity: 1;
}

.listItem_menuOpen > .listItemOverflowMenu button {
  opacity: 1;
}

.listItem:hover * .listItemVideoThumbnailPlayIcon {
  opacity: 1;
}

.listItemNoteMetaContainer {
  margin-right: auto;
  overflow: hidden;
  width: 100%;
}

.listItemNote + .listItemMetaContainer {
  margin-top: 0.35rem;
}

.listItemMetaContainer {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
}

.listItemMeta {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listItemMeta_ai span {
  color: transparent;
  background: linear-gradient(90deg, #5e64e9 0%, #0ec99c 100%);
  background-clip: text;
}

.listItemNote {
  color: var(--color-gray-900);
}

.listItemNoteHashtag {
  display: inline-block;
  color: var(--color-blue-400);
}

.listItemLink {
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem;
}

.listItemVideoThumbnail {
  align-self: flex-start;
  width: 100%;
  aspect-ratio: 1.618/1;
  flex-shrink: 0;
  overflow: hidden;
  background: var(--color-blue-900);
  position: sticky;
  border-radius: 0.25rem;
  transition: background 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listItemVideoThumbnailImage {
  transition: filter 0.25s;
}

.listItem:hover * .listItemVideoThumbnailImage {
  filter: brightness(0.8);
}

.listItemVideoDuration {
  font-size: 12px;
  color: var(--color-gray-100);
  background: var(--color-gray-900);
  position: absolute;
  right: 0.25rem;
  bottom: 0.25rem;
  border-radius: 0.25rem;
  padding: 0 0.125rem;
  z-index: 1;
}

.listItemPublicTooltip {
  position: static;
}

.listItemVideoThumbnailPlayIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 1;
}

.listItemVideoThumbnailGeneratingFeedback {
  transition: opacity 0.25s;
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
}

.listItemOverflowMenu {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.listItemOverflowMenu button {
  transition: opacity 0.25s;
  color: var(--color-gray-100);
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
}

.listItemOverflowMenuItem_destructive {
  color: var(--color-text-error);
}

.header {
  font-size: 1.5rem;
  font-family: var(--font-family-serif);
  font-weight: normal;
  margin: 0;
}

.pagination {
  margin-top: 3rem;
}

.filters + .listItem {
  margin-top: 1.5rem;
}

.emptyClipsPlaceholder {
  margin-top: 1.5rem;
}

@media (min-width: 600px) {
  .listItemLink {
    flex-direction: row;
    gap: 1.5rem;
  }
  .listItemVideoThumbnail {
    width: 7.5rem;
  }
  .listItemOverflowMenu {
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .listItemOverflowMenu button {
    opacity: 0;
    color: var(--color-gray-900);
    background: none;
    backdrop-filter: none;
  }
  .listItemOverflowMenu button:hover {
    background: var(--color-gray-200);
  }
}
