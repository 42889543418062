.root {
  display: inline-block;
}

.root > *:last-child {
  border-radius: 0 0.25em 0.25em 0;
}
.root > *:first-child {
  border-radius: 0.25em 0 0 0.25em;
}

.fullWidth {
  max-width: none;
}
