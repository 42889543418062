.devToolsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.smallPrint {
  margin-top: 1.25rem;
  color: var(--color-blue-600);
  font-size: var(--font-size-small);
}

.smallPrint button,
.smallPrint a {
  font-weight: 600;
  color: var(--color-primary-2);
  text-decoration: underline var(--color-primary-1) 0.1em;
  text-underline-offset: 0.3em;
}

.svgIconMail {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin: 1.75rem auto 0 auto;
  width: 100%;
  padding: 0;
  max-width: 10em;
  text-align: center;
  font-size: 2rem;
  font-weight: normal;
  line-height: 120%;
  font-family: var(--font-family-serif);
}

.subtitle {
  padding: 0;
  margin: 1rem auto 0 auto;
  max-width: 20em;
  color: var(--color-blue-600);
  text-align: center;
  font-family: var(--font-family-base);
  font-weight: normal;
}

.card {
  box-sizing: border-box;
  width: 100%;
  max-width: 30rem;
  padding: 2rem;
  margin: 0 auto;
}

.cardFooter {
  margin-top: 3rem;
  border-top: 1px solid var(--color-border-on-light);
}

.logoutButton {
  margin-left: auto;
}

@media (min-width: 426px) {
  .card {
    padding: 2.5rem;
  }
}
