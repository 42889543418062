.content {
  composes: content from "../../helpers.css";
}

.formContent {
  composes: formContent from "../../helpers.css";
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.cardHeading1 {
  margin: 0 0 1.5rem;
  font-weight: normal;
}

.cardHeading2 {
  font-size: 1rem;
  margin: 0 0 1.5rem;
}

.emailForm {
  margin-bottom: 2rem;
}

.impersonateButton {
  margin-top: 1rem;
}
