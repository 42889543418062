.content {
  composes: content from "../../helpers.css";
}

.cardCreditsList,
.cardRedeem {
  padding: var(--padding-card);
}

.headingAndCredits {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  flex-wrap: wrap;
}

.headingAndCredits + p {
  margin: 1.75rem 0 0 0;
}

.buyCreditsGrid {
  margin: 2rem 0 0 0;
}

.yourCredits {
  color: var(--color-blue-600);
}

.creditsAvailable {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-primary-2);
}

.heading {
  margin: 0 auto 0 0;
  font-size: 1.5rem;
}

.vatInfo {
  margin-top: 2rem;
  color: var(--color-gray-600);
  text-align: center;
  font-size: var(--font-size-small);
}

.byotLinkWrapper {
  text-align: center;
  margin-top: 3rem;
}

.giftCode {
  text-align: center;
  padding-top: 1.5rem;
}

.showRedeemGiftCode {
  font-size: 0.875rem;
  color: var(--color-blue-500);
}
