.root {
  display: inline;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: var(--color-link);
  text-decoration: underline;
  cursor: pointer;
}

.root:focus {
  outline: 0;
}

.root:focus-visible {
  outline: 1px solid currentColor;
}

.fullWidth {
  width: initial;
}
