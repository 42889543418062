.content {
  padding: 1rem;
  width: 21rem;
}

.title {
  margin: 0;
  font-size: 1.5rem;
}

.title + p {
  margin: 0.5rem 0 0 0;
}

.titleSmall {
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
}

.buttonGroup {
  margin-top: 1rem;
}
