.playerProgress {
  position: relative;
  user-select: none;
}

.seekThumbnail {
  position: absolute;
  bottom: 1.25rem;
}

.slider {
  width: 100%;
  height: 1rem;
  cursor: pointer;
}

/* .sliderMark {
    position: relative;
    top: 25%;
    height: 50%;
    width: .2em;
    background-color: var(--color-primary-1);
}

.sliderMarkInner {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--color-primary-1);
    padding: .25rem;
    border-radius: .25rem;
    display: none;
}

.sliderMark:hover .sliderMarkInner {
    background-color: var(--color-primary-2);
    display: block;
} */

.sliderThumb {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--color-blue-200);
}

div.sliderThumb:focus {
  outline: none;
  background-color: white;
}

.sliderTrack {
  top: 25%;
  bottom: 25%;
  border-radius: 0.25rem;
}

@media (hover: none) {
  .sliderThumb {
    width: 2rem;
    height: 2rem;
  }
  .slider {
    height: 2rem;
  }
  .sliderTrack {
    border-radius: 1rem;
  }
  .seekThumbnail {
    position: absolute;
    bottom: 2.25rem;
  }
}

/* first track */
.sliderTrack:first-child {
  background-color: var(--color-blue-100);
}

/* second (last) track */
.sliderTrack + .sliderTrack {
  background: var(--color-blue-600);
}
