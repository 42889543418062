.taskResponseItem {
}

.responseContent {
  min-width: 0;
}

.responseInfo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
  border-radius: var(--border-radius-button);
  padding: 0.5rem 0.5rem;
  text-decoration: inherit;
  color: inherit;
}

.responseInfo:hover {
  background: var(--color-blue-050);
  cursor: pointer;
}

.responseInfo:hover .avatar {
  --avatar-background-color: var(--color-blue-200);
}

.taskResponseItem_new:before {
  content: "";
  height: 0.5rem;
  width: 0.5rem;
  left: -0.75rem;
  border-radius: 0.5rem;
  margin-top: -0.25rem;
  top: calc(2.667rem / 2 + 0.5rem);
  background: var(--color-primary-1);
  position: absolute;
}

@media (min-width: 30rem) {
  .taskResponseItem_new:before {
    height: 0.75rem;
    width: 0.75rem;
    left: -1.25rem;
    border-radius: 0.75rem;
    margin-top: -0.375rem;
  }
}

.details {
  color: var(--color-blue-400);
  font-size: 0.875rem;
  display: flex;
  gap: 0.5rem;
  margin-top: -0.1rem;
}

.detailsDot {
  margin: 0 0.25em;
}

.playVideo {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: flex-start;
  gap: 0.334rem;
}

.responseInfo:hover .playVideo {
  color: var(--color-text-body);
}

.responseInfo:hover .playVideoLabelHover,
.playVideoLabel {
  display: block;
}

.responseInfo:hover .playVideoLabel,
.playVideoLabelHover {
  display: none;
}

.comment {
  font-size: 0.875rem;
  display: flex;
  align-items: flex-start;
  font-weight: normal;
  gap: 0.334rem;
  cursor: pointer;
  border-radius: var(--border-radius-button);
  padding: 0.5rem; /* padding for hover */
  margin-left: 1rem;
  position: relative;
  text-decoration: inherit;
  color: inherit;
}

.commentText {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1px; /* Fixes shadow from tag invisible due to overflow hidden */
}

@media (min-width: 30rem) {
  .comment {
    margin-left: calc(
      0.5rem + 2.668rem + 1rem - 0.5rem
    ); /*padding + avatarWidth + gap - padding for hover*/
  }
}

.comment:hover {
  background: var(--color-blue-050);
}

.comment:hover .commentIconPlay {
  color: var(--color-text-body);
}

.noteOrClipAuthor {
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  max-width: 50%;
  padding-left: 0.5rem;
  box-sizing: border-box;
  top: 0.5rem;
  right: 0.5rem;
  color: var(--color-blue-400);
  background: var(--color-blue-050);
}

.comment:hover .noteOrClipAuthor {
  display: block;
}

.commentIconItem {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: -0.125em;
  margin-bottom: -0.125em;
  color: var(--color-gray-400);
}

.comment:hover .commentIconItem {
  /* display:none doesn't work for gradients. Would have to
    add unique ids for each svg instance. */
  width: 0;
  opacity: 0;
}

.commentIconPlay {
  color: var(--color-blue-400);
  display: none;
}

.comment:hover .commentIconPlay {
  display: inline-block;
}

.commentAutomatedInsightLabel {
  background: linear-gradient(90deg, #5e64e9 0%, #0ec99c 100%);
  background-clip: text;
  color: transparent;
}

.noteOrClipHashtag {
  display: inline-block;
  color: var(--color-gray-400);
}
