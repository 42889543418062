.addStepButton {
  font-size: 0.875em;
  padding: 0.33rem 1rem;
  color: var(--color-blue-600);
  background: white;
  border-radius: 100rem;
  text-transform: none;
  transition: var(--transition-button);
}

.addStepButton:hover {
  background-color: var(--color-button-gray-hover);
  box-shadow: var(--shadow-button-hover);
  transition: var(--transition-button-hover);
}

.stepSeparator {
  text-align: center;
  padding: 0.5rem 0;
  position: relative;
  opacity: 0;
  transition:
    0.2s opacity,
    box-shadow ease-out;
}

.stepSeparator:hover,
.stepSeparator:has(.addStepButton:focus-visible) {
  transition: 0.2s opacity ease-in;
  opacity: 1;
}

.stepSeparator .addStepButton {
  position: relative;
  z-index: 1;
}

.stepSeparator:before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  display: block;
  position: absolute;
  margin-top: -1px;
  border-bottom: 2px dashed var(--color-secondary-2-darker);
}
