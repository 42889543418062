.avatarGroup {
  --avatar-overlap-offset: 0.75em;
  display: flex;
  width: fit-content;
  padding-right: var(--avatar-overlap-offset);
}

.avatarGroup > div {
  box-sizing: border-box;
  border: 2px solid var(--avatarGroup-border-color, white);
  margin-right: calc(var(--avatar-overlap-offset) * -1);
}
