.wrapper {
}

.wrapper_isDraggingOver .stepSeparator:hover {
  opacity: 0;
}

.stepSeparator_disabled {
  opacity: 0;
  pointer-events: none;
}

.selectExampleTaskButton {
  font-size: 0.75em;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-decoration: none;
  font-weight: 600;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.selectExampleTaskButton:after {
  content: "";
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background-image: url(expand-more.svg);
  background-repeat: no-repeat;
  background-size: 1rem;
  vertical-align: bottom;
}

.selectExampleTaskButton_active:after {
  background-image: url(expand-less.svg);
}

.taskTypeSelect {
  margin-top: 3rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  width: 100%;
}

.taskTypeSelectItems {
  padding-bottom: 1rem;
  padding-top: 0;
  margin: -0.5rem -0.5rem 0 -0.5rem;
}

.taskTypeSelectItems:after {
  /* clearfix */
  content: "";
  clear: both;
  display: table;
}

.taskTypeSelect__item {
  text-align: center;
  width: calc(20% - 1rem);
  margin: 0.5rem;
  min-width: 6rem;
  white-space: nowrap;
  cursor: pointer;
  background: white;
  border: 1px solid var(--color-blue-200);
  border-radius: var(--border-radius-card);
  font-size: 0.875em;
  float: left;
  box-sizing: border-box;
  line-height: 2.5;
  padding: 0.5em 0.5em 0 0.5em;
}

.taskTypeSelect__item:hover {
  border-color: var(--color-blue-300);
}

.taskTypeSelect__item--active,
.taskTypeSelect__item--active:hover {
  border-color: var(--color-blue-200);
  background: var(--color-secondary-2);
}

.taskTypeSelect__item--active:before {
  filter: brightness(0.92);
}

.taskTypeSelect__item:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 79%;
  background-repeat: no-repeat;
  background-size: contain;
}

.taskTypeSelect__itemCustom {
}

.taskTypeSelect__itemDefault:before {
  background-image: url(task-icon-default.svg);
}

.taskTypeSelect__itemEcommerce:before {
  background-image: url(task-icon-ecommerce.svg);
}

.taskTypeSelect__itemBooking:before {
  background-image: url(task-icon-booking.svg);
}

.taskTypeSelect__itemSaas:before {
  background-image: url(task-icon-saas.svg);
}

.taskTypeSelect__itemCompany:before {
  background-image: url(task-icon-company.svg);
}

.restoreTaskButton {
  font-size: 0.875em;
  margin-left: 0.5rem;
}

.restoreTaskButtonIcon {
  margin-right: 0.2em;
  font-size: 0.9em;
}

.tempTitle {
  line-height: 2rem;
  font-weight: bold;
}

.addStepButtonBelow {
  font-size: 0.875em;
  padding: 0.33rem 1rem;
  color: var(--color-blue-600);
  background: white;
  border-radius: 100rem;
  text-transform: none;
  transition: var(--transition-button);
}

.addStepButtonBelow:hover {
  background-color: var(--color-button-gray-hover);
  box-shadow: var(--shadow-button-hover);
  transition: var(--transition-button-hover);
}

.addStepButtonBelow {
  display: block;
  margin: -1rem auto 0 auto;
}
