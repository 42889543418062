.video {
  --width-video-sidebar: 24rem;
  /* If this is updated, update media query as well */
  /* Used in JS to query if mobile player is active */
  --is-mobile-player: 1;
  display: flex;
  flex-direction: column;
  background: var(--color-blue-900);
}

.video *:focus {
  outline-color: var(--color-blue-100);
}

.header {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: var(--main-navigation-height);
  color: var(--color-blue-100);
}

.backLinkAndTitle {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;
  overflow: hidden;
}

.shareButton {
  margin-right: 1rem;
}

.videoPlayer {
  height: calc(100vh - 1.5 * var(--main-navigation-height));
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

/* Desktop player */
@media (min-width: calc(24rem * 1.8 + 24rem / 1.8)) {
  .video {
    /* Used in JS to query if mobile player is active */
    --is-mobile-player: 0;
    display: grid;
    grid-template-columns: 1fr var(--width-video-sidebar);
    grid-template-rows: auto 1fr;
    height: 100vh;
  }

  .tabs {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  .videoPlayer {
    grid-column: 1;
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;
    max-height: none;
    max-width: none;
    margin: 0;
  }
}

.title {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1em;
  line-height: 1.2;
  font-weight: 500;
}

.backLink {
  text-decoration: none;
  padding: 0;
  margin-left: 1rem;
  background: none;
  border: 0;
  cursor: pointer;
  color: var(--color-blue-400);
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.backLink:hover {
  color: var(--color-blue-200);
}

.backLink:focus-visible {
  outline: 2px solid var(--color-blue-100);
}

.backLinkIcon {
  height: 1.25em;
  width: 1.25em;
  display: block;
}

.iconLocked {
  color: var(--color-blue-400);
  height: 1em;
  width: 1em;
  margin-left: 0.2em;
}

.tabButtonAiAvailable {
  background: linear-gradient(135deg, #9494ff, var(--color-logo-cyan));
  height: 0.5em;
  width: 0.5em;
  border-radius: 0.5em;
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  text-indent: -99em;
  overflow: hidden;
}

/* Details */

.detailsSection {
  padding: 1rem 0 0 0;
  margin: 1rem 0 0 0;
  border-top: 1px solid var(--color-blue-600);
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailsSection:first-child {
  border: none;
  padding-top: 0;
}

.detailsUrl {
  margin-bottom: 1rem;
}

.detailsSection .detailsRating {
  display: block;
  margin-left: -0.1em;
}

.detailsUrl,
.detailsDeviceDetails,
.detailsTime,
.detailsTesterCountry,
.detailsEmail,
.detailsRating span:last-child {
  opacity: 0.5;
}

.detailsEmail {
  text-decoration: none;
}

.detailsEmail:hover {
  text-decoration: underline;
}

.detailsUrl,
.detailsTitle,
.detailsTitleLink,
.detailsRating {
  white-space: nowrap;
}

.detailsTitleLink,
.video .detailsTitleLink {
  color: #efefef;
  text-decoration: none;
}

.detailsTitleLink:hover,
.video .detailsTitleLink:hover {
  text-decoration: underline;
}

.iconList .shareVideoButton,
.iconList .reportProblemButton,
.iconList .showScreenerResponseButton,
.iconList a {
  color: #fff;
}

.deleteVideoButton,
.deleteVideoIcon {
  color: #fff;
}

.iconList_item {
  display: flex;
}

.iconList_item + .iconList_item {
  margin: 0.5rem 0 0 0;
}

.iconList_icon {
  flex-grow: 0;
  padding-right: 0.5em;
}

.iconList_content {
  width: 100%;
}

.shareVideoButton + .sharedVideo {
  margin-top: 0.5rem;
}

.sharedVideoInputWrapper {
  display: flex;
  position: relative;
  margin-bottom: 0.25rem;
}

.inputSharedVideo {
  font-size: 16px;
  display: block;
  color: white;
  width: 100%;
  min-width: 0;
  border: 1px solid var(--color-blue-500);
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
  background-color: var(--color-blue-900);
}

.inputSharedVideo:focus {
  outline: 0;
}

.buttonCopyUrl {
  margin-left: 0.5rem;
}

.sharedVideo__info {
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
}

.unshareVideoButton {
  color: var(--color-blue-400);
}

.sidebarContent {
  --container-padding-top: 1rem;
  --container-padding-bottom: 1rem;
  --container-padding-left: 1rem;
  --container-padding-right: 1rem;
  font-size: 0.875rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--container-padding-top) var(--container-padding-right)
    var(--container-padding-bottom) var(--container-padding-left);
  background: var(--color-blue-700);
  height: 100%;
  color: var(--color-blue-100);
}

.sidebarContent::-webkit-scrollbar {
  height: 1.25em;
  width: 1.25em;
  background: transparent;
}

.sidebarContent::-webkit-scrollbar-thumb {
  background: var(--color-blue-800);
  -webkit-border-radius: 1em;
  border: 0.3em solid var(--color-blue-700);
}

.sidebarContent::-webkit-scrollbar-corner {
  background: var(--color-blue-900);
}

/* Add note */

.addNote {
  padding: 0.5rem;
  box-shadow: 0 0 16px rgba(10, 10, 45, 0.5);
  background: var(--color-blue-600);
  margin-top: auto;
  /* Shadow above notes */
  z-index: 1;
  position: sticky;
  bottom: 0;
}

.addNote_hint {
  font-size: 14px;
  margin-left: 0.5rem;
  color: var(--color-blue-400);
}

.addNote_note {
  font-size: 0.845rem;
  box-sizing: border-box;
  width: 100%;
  height: 6em;
  display: block;
  border: 0;
  background-color: var(--color-blue-900);
  padding: 0.5rem;
  color: inherit;
  resize: none;
  margin-bottom: 0.5rem;
  border-radius: var(--border-radius-button);
}

.addNote_note::placeholder {
  color: var(--color-blue-400);
}

.addNote_note:focus {
  outline: 0;
}

.addNote_inputTimestamp {
  color: var(--color-blue-400);
  margin-left: 0.5rem;
  /* Should we stick to px? */
  /* https://m2.material.io/design/typography/the-type-system.html#type-scale */
  font-size: 14px;
  border: transparent;
  border-bottom: 1px solid var(--color-blue-400);
  border-style: dotted;
  min-width: 0;
  width: min-content;
  outline: none;
  width: 2.5rem;
  text-align: center;
  background: none;
}

.addNote_inputAndAdjustTimestamp {
  width: 2.8em;
  background-color: var(--color-blue-900);
  border: 0;
  color: #fff;
  text-align: center;
  padding: 0 0.5rem;
  outline: none;
  font-size: 14px;
}

.addNote_inputAndAdjustTimestamp_container {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
  /* TODO convert to em or make content + padding work */
  height: 1.85rem;
  aspect-ratio: 4/1;
  background: var(--color-blue-900);
  border-radius: var(--border-radius-button);
}

.addNote_label {
  font-size: 12px;
  color: var(--color-blue-400);
  margin: 0.25rem 0;
}

.addNote_buttonLink_secondary {
  font-size: 12px;
  color: var(--color-blue-300);
}

.addNote_buttonLink_primary {
  margin: 0.5rem;
  font-size: 14px;
  color: white;
}

.addNote_inputAndAdjustTimestamp:disabled {
  color: var(--color-blue-600);
}

.addNote_horizontalDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(2px * 7);
  height: 2px;
  margin-bottom: calc((2.5rem / 2) - 1px);
  background: var(--color-blue-500);
  border-radius: 1rem;
}

.addNote_inputAndAdjustTimestamp_button {
  background: var(--color-blue-600);
  color: var(--color-blue-200);
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: var(--transition-button);
  box-shadow: none;
  font-size: 12px;
  height: inherit;
  aspect-ratio: 1/1;
}

.addNote_inputAndAdjustTimestamp_button:hover {
  background: var(--color-blue-600);
  color: var(--color-blue-100);
  transition: var(--transition-button-hover);
  filter: brightness(0.95);
}

.addNote_button {
  min-width: 0;
  white-space: nowrap;
  text-align: center;
}

.addNote_buttonSecondary {
  color: white;
  background: var(--color-blue-500);
}

.addNote_buttonSecondary:hover {
  /* TODO clean up styles. Study system/Button/styles */
  color: white;
  background: var(--color-blue-500);
  filter: brightness(0.95);
}

.transcriptCreateMessage,
.noTranscriptAvailable,
.generateAutomatedInsights {
  background: var(--color-blue-200);
  border-radius: var(--border-radius-2);
  padding: 1rem;
  color: var(--color-blue-800);
  font-weight: 600;
}

@keyframes animate-gradient {
  0% {
    background-position: 50% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 50% 50%;
  }
}

.generateAutomatedInsightsButton {
  background: linear-gradient(
    135deg,
    #9494ff,
    var(--color-logo-cyan),
    #9494ff,
    var(--color-logo-cyan)
  );
  border: 0;
  font-size: 1em;
  padding: var(--padding-button);
  border-radius: var(--border-radius-button);
  color: white;
  background-size: 300% 300%;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background-position: 50% 50%;
}

.generateAutomatedInsightsButton:disabled {
  cursor: default;
}

.generateAutomatedInsightsButton_pending {
  animation: animate-gradient 5s ease-in-out infinite;
}

.showAutomatedInsights {
  font-size: 0.75rem;
  text-align: center;
  color: var(--color-blue-400);
}

.showAutomatedInsightsButton {
  background: none;
  border: 0;
  font-size: 1em;
  display: inline-block;
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.hashtag {
  color: var(--color-blue-400);
}

/* Add note */

.addNoteFooter {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Footer */

.addNoteFooterToggleButton {
  font-size: 0.75rem;
}

.squareIcon20Inline {
  display: inline-block;
  height: 1.111rem;
  width: 1.111rem;
  vertical-align: baseline;
  margin-bottom: calc(
    (-1.111rem / 2) + 0.33em
  ); /* Center to baseline + some fontsize */
}

.upgradeNowMessageAddNote {
  margin-top: 1rem;
  margin-bottom: 2rem; /* Intentional exception to the margin-top rule :-) */
  font-size: var(--font-size-small);
}
