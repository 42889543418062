.fullscreenModal {
  background-color: var(--color-secondary-2);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  overflow: auto;
  will-change: opacity;
  align-items: flex-start;
}

.close {
  position: absolute;
  left: 1rem;
  top: 1rem;
  line-height: 1.25rem;
  background: url(./close.svg) no-repeat;
  background-size: 1.25rem;
  color: var(--color-blue-600);
  border: 0;
  padding-left: 2rem;
  cursor: pointer;
}

.enter {
  opacity: 0;
}

.enterActive {
  transition: opacity 200ms ease;
  opacity: 1;
}

.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  transition: opacity 200ms ease;
  opacity: 0;
}

.exitDone {
  opacity: 0;
}
