/* The switch - the box around the slider */
.switch {
  display: flex;
  align-items: center;
  position: relative;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: relative;
  display: flex;
  width: fit-content;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.25em solid var(--color-blue-900);
  background-color: var(--color-blue-900);
  transition: 0.4s;
}

input:disabled ~ .slider {
  background-color: var(--color-blue-700);
}

input:disabled ~ .slider > .label {
  color: var(--color-gray-400);
}

.slider + .label {
  margin-left: 0.5em;
}

.disabled .slider {
  opacity: 0.7;
  cursor: default;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 0;
  bottom: 0;
  background-color: var(--color-blue-300);
  transition: 0.4s;
}

input:checked ~ .slider:before {
  left: 1em;
}

/* Rounded sliders */
.slider.round {
  border-radius: 1.7em;
}

.slider.round:before {
  border-radius: 50%;
}

.disabled {
  color: var(--color-gray-500);
}

.label {
  font-size: 0.75rem;
}

.iconContainer {
  width: 1em;
  height: 1em;
  z-index: 1;
  transition: 0.4s color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconContainer > svg {
  font-size: 10px;
}

.iconContainer:nth-child(1) {
  color: var(--color-gray-900);
}

.iconContainer:nth-child(2) {
  color: var(--color-gray-300);
}

input:checked ~ .slider > .iconContainer:nth-child(2) {
  color: var(--color-gray-900);
}

input:checked ~ .slider > .iconContainer:nth-child(1) {
  color: var(--color-gray-300);
}
