.modalHeader {
  position: relative;
  padding-top: 2rem;
}

.heading {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 1.25rem;
}

.tabs {
  border-bottom: 2px solid var(--color-border-on-light);
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0 0 0;
  padding: 0 1.25rem;
}

.tab {
  font-size: 0.875em;
  padding: 0.5em 0;
  margin-left: 2rem;
  border: 0;
  background: 0;
  cursor: pointer;
  font-weight: 600;
  color: var(--color-blue-500);
  margin-bottom: -2px;
  border-bottom: 3px solid transparent;
}

.tab:first-child {
  margin-left: 0;
}

.tabActive {
  color: inherit;
  border-bottom: 3px solid var(--color-logo-cyan);
}

.buttonCloseIcon {
  border: 0;
  padding: 0;
  top: 0.75rem;
  right: 0.75rem;
  position: absolute;
  color: var(--color-gray-600);
  cursor: pointer;
  overflow: hidden;
  text-indent: -10rem;
  background: url(./close.svg) no-repeat;
  background-size: contain;
  height: 1.375rem;
  width: 1.375rem;
}
