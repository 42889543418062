.customerTesterSwitch {
}

.title {
  font-family: var(--font-family-base);
  text-align: center;
  max-width: 7em;
  font-size: 3rem;
  line-height: 1.25;
  margin: 0 auto;
  color: var(--color-primary-2);
  white-space: nowrap;
  position: relative;
  font-weight: bold;
  width: fit-content;
}

.title:after {
  content: "";
  position: absolute;
  display: block;
  height: 2em;
  left: -0.2em;
  right: -0.2em;
  bottom: -1.82em;
  background-size: contain;
  background-image: url(./underline.svg);
  background-repeat: no-repeat;
}

.cardContainer {
  margin-top: 3rem;
  width: 100%;
  display: grid;
  justify-content: center;
  gap: 1rem;
  grid-template-columns: minmax(0, 400px);
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding: 2rem 3rem 3rem 3rem;
}

.cardBody > img {
  height: 150px;
  margin-top: 1.5rem;
}

.cardTitle {
  color: var(--color-primary-2);
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
  letter-spacing: 0.06rem;
  text-transform: uppercase;
}

.cardDescription {
  color: var(--color-primary-2);
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 1.5rem 0 0 0;
  max-width: 10em;
}

.cardButton {
  margin-top: 3rem;
  padding: 0.75rem 1.5rem;
  width: 100%;
}

@media (min-width: 600px) {
  .cardContainer {
    grid-template-columns: repeat(2, minmax(0, 350px));
  }
}

@media (min-width: 750px) {
  .cardContainer {
    gap: 2rem;
  }
  .title {
    max-width: 10em;
    font-size: 60px;
  }
}
