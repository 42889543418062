.root {
}

.label {
  position: relative;
  cursor: default;
  box-sizing: border-box;
  display: flex;
  align-items: baseline;
}

.checkbox {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.inputCheck {
  flex-shrink: 0;
  box-sizing: border-box;
  display: block;
  font-size: 1em;
  width: 1.25em;
  height: 1.25em;
  margin-left: 0.15rem;
  border-radius: 0.25rem;
  border: 2px solid var(--color-blue-300);
  position: relative;
  margin-top: calc(
    -1.25em
  ); /* neutralize height to prevent flex container from beeing higher then label */
  top: calc(2px + (1.25em - 4px) / 2 - 0.36em);
  margin-right: 0.5em;
  transition: 0.1s;
}

.checkbox:focus-visible + .inputCheck {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.inputCheck::before {
  content: "";
  display: inline-block;
  width: 0.625em;
  height: 0.25em;
  border-bottom: 0.1875em solid #fff;
  border-left: 0.1875em solid #fff;
  transform: scale(0) rotate(-45deg);
  position: absolute;
  top: calc(0.35em - 2px);
  left: calc(0.2375em - 2px);
  transition: 0.1s;
}

.checkbox:checked ~ .inputCheck {
  background-color: var(--color-logo-cyan);
  border-color: var(--color-logo-cyan);
}

.checkbox:disabled ~ .inputCheck {
  border-color: var(--color-gray-200);
}

.checkbox:checked:disabled ~ .inputCheck {
  background-color: var(--color-gray-200);
}

.checkbox:checked ~ .inputCheck::before {
  transform: scale(1) rotate(-45deg);
}

.root_error .checkbox:not(:checked) ~ .inputCheck {
  border-color: var(--color-border-error);
}
