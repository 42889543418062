.root {
  cursor: pointer;
  border-radius: var(--border-radius-button);
  border: 1px solid var(--color-button-primary);
  background: var(--color-button-primary);
  padding: var(--padding-button);
  color: white;
  font-weight: 600;
  white-space: nowrap;
  font-size: var(--font-size-uppercase);
  box-shadow: var(--shadow-button);
  line-height: var(--line-height-button);
  transition: var(--transition-button);
  display: inline-block;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.025em;
}

.root:hover {
  background-color: var(--color-button-primary-hover);
  border-color: var(--color-button-primary-hover);
  transition: var(--transition-button-hover);
  box-shadow: var(--shadow-button-hover);
}

.root:disabled {
  box-shadow: none;
  color: var(--color-blue-300);
  background: var(--color-blue-050);
  border: 1px solid var(--color-blue-050);
  transition: none;
  cursor: default;
}

.root:disabled:hover {
  color: var(--color-blue-300);
  background: var(--color-blue-050);
  border: 1px solid var(--color-blue-050);
  cursor: default;
}

.root:focus {
  outline: 0;
}

.root:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.fullWidth {
  width: 100%;
}

.big {
  border-radius: var(--border-radius-2);
  padding: var(--padding-button-big);
}
