.root {
  cursor: pointer;
  border: 0;
  border-radius: var(--border-radius-button);
  background: var(--background-color-button-warning);
  color: var(--color-button-warning);
  padding: var(--padding-button);
  font-weight: 600;
  font-size: var(--font-size-uppercase);
  box-shadow: var(--shadow-button-warning);
  line-height: var(--line-height-button);
  transition: var(--transition-button);
}

.root:hover {
  background: var(--background-color-button-warning-hover);
  box-shadow: var(--shadow-button-warning-hover);
  transition: var(--transition-button-hover);
}

.root:disabled {
  background: #77c4bd;
}

.fullWidth {
  width: 100%;
}
