.infoBox {
  font-size: 0.875rem;
  background: #feb;
  border: 2px solid #eecc64;
  color: #8c6a00;
  border-radius: var(--border-radius-2);
  padding: 0.75rem 1rem;
  text-align: left;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 0.75rem;
}

.infoBox_subtle {
  color: var(--color-blue-600);
  background: var(--color-blue-050);
  border: 2px solid var(--color-blue-200);
}

.infoBox_subtleOnBackground {
  color: var(--color-blue-500);
  background: var(--color-secondary-2-darker);
  border: 0;
}

.info {
  flex-basis: 100%;
}
