.keyMetrics {
  /* grid */
  display: grid;
  /* one column */
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .keyMetrics {
    grid-template-columns: repeat(2, 1fr);
  }
}

.statCard {
  padding: 1.25rem;
  border: 1px solid var(--color-border-on-light);
  border-radius: var(--border-radius-2);
  display: flex;
  gap: 1rem;
  align-items: center;
}

.statCardIcon {
  color: var(--color-primary-2);
}

.statCardIcon_disabled {
  color: var(--color-blue-300);
}

.statCardValue {
  font-weight: 600;
}

.statCardLabel {
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
}

.statCardLabel_disabled,
.statCardLabel_disabled * svg {
  color: var(--color-blue-300);
}

.reportButton {
  width: 100%;
  /*span all grid columns*/
  grid-column: 1 / -1;
}
