.root {
  padding: 0;
  color: var(--color-primary-2);
  background: none;
  border: 0;
  height: 2rem;
  width: 2rem;
  border-radius: var(--border-radius-button);
  cursor: pointer;
}

.root:hover {
  background: var(--color-secondary-2-darker);
}

.root:disabled {
  color: var(--color-blue-400);
}

.root:disabled:hover {
  color: var(--color-blue-400);
  background: none;
  cursor: default;
}
