.subscriptionBox {
  padding: 1.5rem;
}

.header {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.titleAndStatus {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
}

.status {
  display: block;
  padding: 0.12em 0.7em;
  background-image: url(./line.svg);
  background-size: 100% 100%;
  white-space: nowrap;
}

.actions {
  display: flex;
  gap: 1rem;
}

.plan,
.totals {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem 0;
  margin-top: 2rem;
  border-top: 2px solid var(--color-border-on-light);
}

.plan + .plan,
.plan + .totals {
  margin-top: 0;
}

.planName {
}

.planInfo:before {
  content: "·";
  margin: 0 10px 0 6px;
}

.planInfo,
.planOutdated {
  color: var(--color-blue-600);
}

.planPrice {
  margin-left: auto;
}

.nextChargeDate {
  color: var(--color-blue-600);
}

.totalsPrice {
  font-weight: 600;
  margin-left: auto;
  font-size: 1.5rem;
}

.contactSupportWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
