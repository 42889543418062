.root {
  padding: 2em;
  width: 20em;
  margin: auto;
}

.message {
  margin: 1rem 0;
  text-align: center;
}

.messageInfo {
  font-size: 0.875rem;
  color: var(--color-text-gray);
}

.centercenter {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.logo-container {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  font-size: 1.2em;
}
