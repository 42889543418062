.modalContent {
  box-sizing: border-box;
  padding: 1.5rem;
  width: 30rem;
  max-width: 100%;
}

.modalContent_withModalHeader {
  padding-top: 0;
}
