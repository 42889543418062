.root {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--main-navigation-height);
  padding-top: 0;
  white-space: nowrap;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 1000;
  /*box-shadow: var(--shadow-button);*/
  border-bottom: 2px solid var(--color-secondary-2-darker);
}

.root {
  position: fixed;
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.logo img {
  height: 1.5rem;
  width: 1.5rem;
}
