.content {
  padding: 4rem 1.5rem 1.5rem 1.5rem;
}

.title {
  margin: 0;
  font-family: var(--font-family-serif);
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
}

.typeCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.typeCard {
  background: white;
  border-radius: var(--border-radius-1);
  width: 15rem;
  text-align: center;
  padding: 3rem 2rem 2.5rem 2rem;
  box-shadow: var(--box-shadow-card);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
}

.typeCard p {
  color: var(--color-blue-600);
}

.typeCard h2 {
  font-size: 1.25rem;
}

.typeCard p,
.typeCard h2 {
  margin: 0;
}

.typeCard h2 + p {
  margin-top: 0.5rem;
}

.icon180 {
  height: 150px;
  width: 150px;
}

.button {
  margin-top: auto;
}
