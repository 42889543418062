.root {
  position: relative;
  //z-index: 999;
  display: inline-block;
}

.triggerButton,
.triggerButtonPrimary {
  padding: var(--padding-button);
  padding-right: 2rem;
  background-image: url(expand-more.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
  background-color: var(--color-button-gray);
  border-radius: var(--border-radius-button);
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--color-blue-600);
  font-weight: 600;
  font-size: 0.875rem;
  display: inline-block;
  cursor: pointer;
  box-shadow: var(--shadow-button);
  line-height: var(--line-height-button);
  height: 100%;
  transition: var(--transition-button);
  white-space: nowrap;
}

.triggerButton:focus,
.triggerButtonPrimary:focus {
  outline: 0;
}

.triggerButton:hover,
.triggerButtonPrimary:hover {
  background-color: var(--color-button-gray-hover);
  box-shadow: var(--shadow-button-hover);
  transition: var(--transition-button-hover);
}

.triggerButtonPrimary {
  color: white;
  border: 0;
  background-color: var(--color-button-primary);
  background-image: url(expand-more-white.svg);
}

.triggerButtonPrimary:hover {
  background-color: var(--color-button-primary-hover);
}

.triggerSelect {
  color: inherit; /* iOS button is blue by default */
  width: 100%;
  text-align: left;
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  background-image: url(expand-more.svg);
  background-position: right 0.5em center;
  background-repeat: no-repeat;
  background-size: 1em;
  background-color: #fff;
  border-radius: 0.2rem;
  border: 2px solid var(--color-border-gray);
  display: inline-block;
  cursor: pointer;
  line-height: var(--line-height-button);
  height: 100%;
  transition: var(--transition-button);
  white-space: nowrap;
}

.triggerSelect:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

.triggerSelect:hover {
  transition: var(--transition-button-hover);
}

.open .triggerSelect {
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

.content {
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-dropdown);
  background: white;
  position: absolute;
  margin-top: 0.5rem;
  right: 0;
  z-index: 1;
  width: fit-content;
}

.contentAlignLeft {
  right: initial;
  left: 0;
}

.fullWidth {
  max-width: none;
}
