.insightsSection {
  padding: var(--container-padding-top) var(--container-padding-right)
    var(--container-padding-bottom) var(--container-padding-left);
}

.header {
  display: flex;
  gap: 1em;
}

.headerIcon {
  align-self: center;
}

.header h2 {
  font-family: var(--font-family-serif);
  font-weight: normal;
}

.showMore {
  margin-top: 1rem;
  width: 100%;
}

.accordionHeading {
  margin: 0;
}

.accordionItem {
  margin-top: 1rem;
  box-sizing: border-box;
  border-radius: var(--border-radius-2);
  border: 1px solid var(--color-border-transparent);
}

.accordionHeading {
  font-size: 1rem;
}

.accordionTrigger {
  padding: 1rem;
  background: none;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  line-height: inherit;
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.accordionTrigger[aria-expanded="false"]:focus-visible {
  outline: var(--focus-outline);
  outline-offset: calc(var(--focus-outline-offset) + 1px);
  border-radius: calc(var(--border-radius-2) - 1px);
}

.accordionTrigger[aria-expanded="true"]:focus {
  outline: none;
}

.accordionTrigger[aria-expanded="true"]:focus-visible .accordionIcon {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
  border-radius: var(--border-radius-1);
}

.accordionTriggerTitle {
  user-select: text;
}

.accordionTrigger[aria-expanded="true"] {
  padding-bottom: 0;
}

.accordionContent {
  padding: 0 1rem 1rem 1rem;
}

.accordionInitialsAndIcon {
  display: flex;
  margin-left: auto;
  gap: 0.75rem;
}

.accordionInitials {
  display: flex;
}

.accordionItem_expanded .accordionIcon {
  transform: rotate(180deg);
}

.accordionIcon {
  align-self: center;
}

.noteOrClipItem {
  margin: 0.5rem calc(var(--container-padding-left) * -1) 0.5rem
    calc(var(--container-padding-right) * -1);
}

.avatarGroup {
  --avatar-overlap-offset: 1.25em;
  font-size: 0.75rem;
  align-self: center;
}

.placeholderContent {
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-border-transparent);
}

.header + .placeholderContent {
  margin-top: 2.5rem;
}

.placeholderContent + .placeholderContent {
  margin-top: 1rem;
}
