.root {
  padding: 1rem;
}

.active {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.active ~ .root {
  color: var(--color-gray-500);
}

.root + .root:before {
  content: "›";
  position: relative;
  left: -1rem;
  color: var(--color-gray-700);
}

/*
.active:after{
    content: '';
    position: absolute;
    background: var(--color-logo-cyan);
    height: 4px;
    border-radius: 100px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -3px;
}
*/
