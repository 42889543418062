.testerTargetingSummary {
  font-size: 0.875rem;
  color: var(--color-blue-400);
}

.flag {
  height: 1.25em;
  width: 1.25em;
  border-radius: 1em;
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: sub;
}
