.root {
  margin: 0;
  padding: 0 1rem;
  box-sizing: border-box;
}

@media (min-width: 975px) {
  .root {
    width: var(--sub-navigation-width);
    position: fixed;
    float: none;
    padding: 0 2rem;
  }
}

.root hr {
  border: 0;
  border-top: 2px solid var(--color-border-gray);
  margin: 0.5em 0;
}
