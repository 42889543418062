.input {
  display: block;
  width: 100%;
  max-width: 20em;
  padding: 1em 1em;
  box-sizing: border-box;
  border: 2px solid var(--color-border-gray);
  border-radius: var(--border-radius-button);
}

.input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

.error,
.input:focus .error {
  outline: 0;
  border-color: var(--color-border-error);
}

.fullWidth {
  max-width: none;
}
