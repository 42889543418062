.form {
  width: 100%;
  max-width: 24rem;
  margin: 0 auto;
  box-sizing: border-box;
  composes: formContent from "../../helpers.css";
}

.form-title {
  text-align: center;
  font-size: 2rem;
  margin-top: 0;
}

.buttonPromo {
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1em;
}
