.videoStar {
  all: unset;
  cursor: pointer;
  opacity: 0.8;
}

.videoStar:hover {
  opacity: 1;
}
.videoStar:active {
  animation: pop 0.3s ease;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
