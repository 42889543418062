.root {
  font-size: var(--font-size-small);
  display: inline-block;
  text-decoration: none;
  height: var(--main-navigation-height);
  line-height: var(--main-navigation-height);
  font-weight: 600;
  padding: 0 1rem 0 1rem;
  position: relative;
}

.menu {
  border-radius: var(--border-radius-button);
  background: white;
  /*border: 2px solid #efefef;*/
  line-height: 1.5;
  position: absolute;
  width: 13em;
  min-height: 10em;
  right: 1rem;
  top: calc(100% + 1rem);
  box-shadow: var(--shadow-dropdown);
  padding: 0.5rem;
}

/*.menu:before {
    content: '';
    background: white;
    width: 100%;
    height: 1em;
}*/

.menuTrigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 1.5rem;
  border: 1px solid var(--color-blue-200);
  padding: 0.25rem 1.5rem 0.25rem 0.33rem;
  background-image: url(expand-more.svg);
  background-repeat: no-repeat;
  background-size: 1rem;
  margin-top: 0.7rem;
  border-radius: 2rem;
  background-position: right 0.25rem center;
}

.menuOpen .menuTrigger {
  box-shadow: var(--box-shadow-slight);
}
