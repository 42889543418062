.root {
  display: inline;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-text-body);
  font-weight: bold;
  text-decoration-color: var(--color-primary-1);
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
}

.root:focus {
  outline: 0;
}

.root:focus-visible {
  outline: 1px solid currentColor;
}

.fullWidth {
  width: initial;
}
