.root:hover {
  background: var(--color-blue-100);
  border-radius: var(--border-radius-1);
}

.disabled:hover {
  background: none;
}

.root {
  font-size: var(--font-size-small);
  white-space: nowrap;
  padding: 0.5rem 0.88rem;
  cursor: pointer;
  line-height: 1.2;
}

.root + .root {
  margin-top: 0.2rem;
}

.rootLink {
  color: inherit;
  display: block;
  text-decoration: none;
}

.icon {
  margin-right: 0.5rem;
}

.caution {
  color: var(--color-text-error);
}

.disabled {
  color: var(--color-blue-400);
  cursor: default;
}
