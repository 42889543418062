.pageSelector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.next,
.previous {
  background: none;
  border: 0;
  overflow: hidden;
  height: 1.5em;
  width: 1.5em;
  background-size: 75%;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -999em;
  border-radius: var(--border-radius-button);
}

.next:disabled,
.previous:disabled {
  opacity: 0.25;
}

.next:hover,
.previous:hover {
  cursor: pointer;
  background-color: var(--color-blue-100);
}

.next:disabled:hover,
.previous:disabled:hover {
  cursor: default;
  background-color: transparent;
}

.next {
  background-image: url("./caret-right.svg");
}

.previous {
  background-image: url("./caret-left.svg");
}
