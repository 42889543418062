.screenerModalContent {
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.screenerResultsInfo {
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
}

.footer {
  display: flex;
}

.question {
  margin-top: 1.5rem;
  border-radius: 0.75rem;
  padding: 1rem;
  background: var(--color-blue-050);
}

.select {
  margin-top: 1rem;
}

.feedbackBox {
  border-radius: var(--border-radius-button);
  background: var(--color-blue-200);
  font-size: 0.875rem;
  color: var(--color-blue-600);
  padding: 0.75rem 1rem;
  text-align: left;
  align-items: flex-start;
}

.selectExistingTest {
  font-size: var(--font-size-small);
}

.addAnotherQuestionButton {
  --inline-icon-size: 0.889rem; /* equals 16px for our default desktop 18px font size */
  margin: 1rem;
  background: var(--color-blue-050);
  border: none;
  color: var(--color-blue-600);
  font-weight: bold;
  border-radius: var(--border-radius-2);
  font-size: var(--font-size-uppercase);
  padding: var(--padding-button);
  line-height: 1.66;
  cursor: pointer;
}

.addAnotherQuestionButton:hover {
  background: var(--color-blue-100);
}
