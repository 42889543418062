.contentTabs {
  border: 2px solid var(--color-blue-600);
  width: fit-content;
  border-radius: var(--border-radius-button);
  padding: 2px;
  font-size: var(--font-size-small);
  display: flex;
  gap: 2px;
}

.contentTabsItem {
  background: none;
  border: 0;
  color: var(--color-blue-300);
  padding: calc(0.5rem - 2px);
  border-radius: calc(var(--border-radius-button) - 2px - 2px);
  cursor: pointer;
}

.contentTabsItem:hover {
  background: color-mix(
    in srgb,
    var(--color-blue-700),
    var(--color-blue-300) 6%
  );
}

.contentTabsItem_active,
.contentTabsItem_active:hover {
  background: var(--color-blue-600);
  color: var(--color-blue-200);
}

.contentTabsItem:focus-visible {
  outline: 1px solid var(--color-blue-500);
  outline-offset: -1px;
}

.count {
  color: var(--color-blue-500);
}
