.taskResultsRatingScale {
}

.result {
  font-size: 0.875rem;
  color: var(--color-blue-400);
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.barGraphRatingScale {
  margin-top: 1.75rem;
}

.ratingVis {
  display: flex;
  align-items: center;
  gap: 0.25em;
  height: calc(1.125rem * 1.4);
}

.ratingVisDot {
  border-radius: var(--border-radius-1);
  background: var(--color-blue-100);
  height: 1em;
  width: 1em;
  flex-grow: 0;
  flex-shrink: 0;
}

.ratingVisDot_filled {
  background: var(--color-primary-1);
}

.rating {
  white-space: nowrap;
}

.taskResponseItem_outdated .ratingVisDot_filled {
  background: var(--color-blue-300);
}

.responses {
  padding: 0 var(--task-result-container-padding)
    var(--task-result-container-padding)
    calc(var(--task-result-container-padding) - 0.5rem);
}
