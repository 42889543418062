.account img {
  display: block;
}

.userIcon {
  font-size: 1.5rem;
  display: block;
}

.account {
  margin-left: auto;
}

.releaseInfo {
  font-weight: normal;
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-bottom: 0.2rem;
  color: var(--color-blue-300);
  font-size: var(--font-size-small);
}

.releaseInfoLink {
  color: var(--color-blue-300);
  text-decoration: none;
}

.releaseInfoLink:hover {
  text-decoration: underline;
}

.accountItemsDivider {
  border-color: var(--color-border-on-light);
  border-width: 1px;
  margin: 0.5rem 0;
}

.itemAlt,
a.itemAlt {
}

.hasNews:after {
  content: "";
  background-color: var(--color-teal-600);
  height: 0.5em;
  width: 0.5em;
  vertical-align: middle;
  border-radius: 1em;
  margin-left: 0.5rem;
  display: inline-block;
}

.mainNavigationBreadcrumbs {
  font-size: 0.875rem;
  margin-left: 1em;
  overflow: hidden;
  position: relative;
}

.mainNavigationBreadcrumbs:after {
  content: "";
  height: var(--main-navigation-height);
  width: 1rem;
  right: 0;
  top: 0;
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.mainNavigationBreadcrumbsSeparator,
.mainNavigationBreadcrumbsItem {
  display: inline-block;
  height: var(--main-navigation-height);
  line-height: var(--main-navigation-height);
  margin-right: 0.75rem;
  font-weight: 600;
}

.mainNavigationBreadcrumbsItemIcon {
  height: 1.334rem;
  width: 1.334rem;
  position: relative;
  bottom: -0.35rem;
  margin-right: 0.33rem;
}

.mainNavigationBreadcrumbsSeparator {
  margin-right: 0.5rem;
  color: var(--color-blue-400);
}

.mainNavigationBreadcrumbsSeparatorIcon {
  height: 0.667rem;
  width: 0.334rem;
  position: relative;
  bottom: -0.06em;
}

.mainNavigationBreadcrumbsItem a {
  display: inline-block;
  height: var(--main-navigation-height);
  color: var(--color-blue-600);
  text-decoration: none;
}

.headerInfo {
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
  margin-left: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
}

@media (min-width: 32rem) {
  .headerInfo {
    display: block;
  }
  .headerInfo + .account {
    margin-left: 0;
  }
}

.headerInfo .buyLink {
  color: var(--color-teal-500);
  text-decoration: none;
  font-weight: bold;
}

.headerInfo .buyLink:hover {
  text-decoration: underline;
}
