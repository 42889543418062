.taskResultsSummaryBox {
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.5rem 0 0;
}

.taskTypeLabel {
  text-align: right;
  padding: 0.25rem 0.75rem 0.25rem 1.25rem;
  background: #eeeeff;
  color: var(--color-blue-500);
  border-radius: 0 var(--border-radius-1) var(--border-radius-1) 0;
  font-weight: 700;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
}

.overflowMenuTriggerButton {
  background: none;
  border: 0;
  cursor: pointer;
  color: var(--color-blue-400);
}

.responsesInfo {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-top: 1.25rem;
  font-size: 0.875rem;
  color: var(--color-blue-400);
}

.responsesInfoShowAll {
  color: var(--color-text-body);
}

.responsesInfoTesters {
  white-space: nowrap;
}

.responsesInfoTesters_withOutdated {
  color: var(--color-secondary-1);
}

.taskContent {
  margin-top: 1.5rem;
  font-size: 1.125rem;
}

.taskContent {
  padding: 0 var(--task-result-container-padding);
}

.showTestersOfLatesVariationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 14rem;
  padding: 0.5em 1em;
  box-sizing: border-box;
  font-size: var(--font-size-small);
  border-bottom: 1px solid var(--color-border-transparent);
}

.showTestersOfLatesVariationContainer + * {
  margin-top: 0.5rem;
}
