.tabs {
  display: flex;
  gap: 0.5rem;
}

.tab {
  --active-icon-color: var(--color-blue-100);
  display: flex;
  align-items: center;
  border: 0;
  border-radius: var(--border-radius-button);
  background: none;
  color: var(--color-blue-300);
  padding: 0.4rem 0.5rem;
  font-size: var(--font-size-small);
  gap: 0.4rem;
  cursor: pointer;
}

.tabIcon {
  color: var(--color-blue-300);
  font-size: 1.25rem;
}

.tab_active {
  cursor: default;
  color: var(--color-blue-100);
  background: var(--color-blue-900);
}

.tab_active .tabIcon {
  color: var(--active-icon-color);
}

.tempTitle {
  font-size: var(--font-size-small);
}
