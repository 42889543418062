.test {
  padding: 1.25rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
}

.test:first-child {
  margin-top: 0;
}

.testContent {
  flex-grow: 1;
  overflow: hidden;
}

.title {
  font-weight: normal;
  font-size: 1.25rem;
  margin: 0;
}

.additionalInfos {
}

.additionalInfosTesting {
  display: inline-block;
  margin-right: 1em;
  font-size: 0.875rem;
  color: var(--color-blue-500);
}

.additionalInfosTestingItem {
  display: inline-block;
}

.additionalInfosTestingItem + .additionalInfosTestingItem:before {
  content: "·";
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.additionalInfosNewSession {
  display: inline-block;
  color: var(--color-teal-600);
  font-size: 0.875rem;
}

.additionalInfosNewSession:before {
  content: "";
  height: 0.4em;
  width: 0.4em;
  border-radius: 0.4em;
  background: var(--color-teal-600);
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}

.overflow-menu {
}

.statusSymbol {
  flex-shrink: 0;
  border: 3px solid var(--color-blue-200);
  border-radius: 2.8em;
  height: 2.8em;
  width: 2.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-teal-600);
  font-size: 0.75rem;
  margin-right: 1.25rem;
}

.statusSymbol img {
  opacity: 0.8;
}

.statusSymbolCheck {
  border-color: var(--color-teal-600);
}

.statusSymbolDraft,
.statusSymbolArchive {
  color: var(--color-gray-400);
}

.statusSymbolDraft > *,
.statusSymbolArchive > * {
  font-size: 0.875rem;
}

.link {
  position: absolute;
  display: block;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
