.content {
  composes: contentWithoutSubNav from "../../helpers.css";
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: var(--color-text-gray-on-background);
  padding: 5rem 1rem;
  text-align: center;
}

.emptyState__icon {
  font-size: 4rem;
  opacity: 0.2;
}

.emptyState__text {
  font-size: 1.25rem;
  opacity: 0.4;
}

.videos {
  margin-top: 2rem;
  padding: 1px 1.5rem 0.5rem 1.5rem;
}

.videoList {
  margin-top: 0.5rem;
}

.video-new--new::before {
  content: "";
  display: inline-block;
  background: var(--color-logo-cyan);
  border-radius: 100em;
  height: 0.8em;
  width: 0.8em;
}

.dropDownDialogContent {
  padding: 1rem;
  min-width: 12em;
}

.filterStatus {
  background: var(--color-blue-100);
  color: var(--color-blue-600);
  text-transform: capitalize;
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: var(--border-radius-button);
}

.filterStatus__reset {
  background: none;
  color: var(--color-blue-600);
  border: 0;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  margin-right: 0.5rem;
}

.filterStatus__reset:focus {
  outline: 0;
}

.filterStatus__reset:hover {
  color: var(--color-blue-900);
}

.filterStatus__label {
  display: inline;
  font-weight: bold;
}

.filterStatus__item {
  display: inline;
}

.filterStatus__item + .filterStatus__item:before {
  content: ", ";
}

.filterStatus__testTitle {
  text-transform: none;
}

.tdLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contentHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 0.5rem;
  /*border-bottom: 2px solid var(--color-gray-300);
    padding-bottom: 2rem;*/
}

.pagination {
  margin-top: 2rem;
}

.mainHeading {
  font-family: var(--font-family-serif);
  font-size: 2rem;
  margin: 0 0 0 0;
  font-weight: normal;
}

.searchBox {
  flex: 1 1 15em;
  order: 0;
  max-width: 25em;
}

.navAndSearch {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 1rem;
}
