/* Note */

.note {
  position: relative;
  cursor: pointer;
  transition:
    color,
    opacity 0.1s ease-in;
  padding: 0.65rem 0.5rem;
  margin: 0 -0.5rem;
  border-radius: var(--border-radius-button);
}

.note_highlighted {
  color: #ffffff;
  background: var(--color-blue-600);
}

.note p {
  margin: 0;
}

.note .note_timecode + p {
  margin-top: 0;
}

.note_timecode {
  font-size: 0.857em;
  color: var(--color-blue-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  overflow: hidden;
}

.noteTimestamps {
  flex-shrink: 0;
}

.noteUser {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.noteUser_automatedInsight {
  font-weight: bold;
  background: linear-gradient(135deg, #9494ff, var(--color-logo-cyan));
  background-clip: text;
  color: transparent;
}

.note_hidden {
  display: none;
}

.note:hover .noteActions {
  opacity: 1;
}

.noteActions {
  will-change: opacity;
  opacity: 0;
  position: absolute;
  right: var(--border-radius-button);
  top: -0.5rem;
  background: var(--color-blue-700);
  border-radius: var(--border-radius-button);
  border: 2px solid var(--color-blue-600);
  padding: 0.25rem;
  z-index: 1;
}

.noteActionsButton {
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: var(--color-blue-400);
}

.noteActionsButton + .noteActionsButton {
  margin-left: 0.5rem;
}

.noteActionsButton_share + .noteActionsButton::before {
  content: "";
  margin: 0 0.5rem -0.1em 0;
  display: inline-block;
  height: 0.9em;
  border-left: 2px solid var(--color-blue-600);
}

.noteActionsButton:hover {
  color: var(--color-blue-300);
}

.noteActionsButton:disabled {
  color: var(--color-blue-600);
}

.squareIcon20Inline {
  display: inline-block;
  height: 1.111rem;
  width: 1.111rem;
  vertical-align: baseline;
  margin-bottom: calc(
    (-1.111rem / 2) + 0.33em
  ); /* Center to baseline + some fontsize */
}

.hashtag {
  color: var(--color-blue-400);
}

.iconClip {
  color: var(--color-primary-1);
}

.iconNote {
  color: #777dfb;
}
