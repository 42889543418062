.root {
  position: relative;
  max-width: 70rem;
  margin: 0 auto;
}

@media (min-width: 975px) {
  .root {
    position: relative;
    max-width: 70rem;
    margin: 0 auto;
  }
}
