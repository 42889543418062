.root {
  display: block;
  width: 100%;
  max-width: 20em;
  padding: 1em 1em;
  box-sizing: border-box;
  border: 2px solid var(--color-border-gray);
  border-radius: var(--border-radius-button);
}

.root:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

.error,
.root:focus .error {
  outline: 0;
  border-color: var(--color-border-error);
}

.fullWidth {
  max-width: none;
}

.readOnly {
  background: #f5f5f5;
  color: #666;
}

.readOnly:focus {
  outline: 0;
  box-shadow: none;
}

.root::placeholder {
  color: var(--color-gray-400);
  opacity: 1;
}

.root::-moz-placeholder {
  opacity: 1;
}
