.content {
  composes: contentWithoutSubNav from "../../helpers.css";
}

.videos,
.tabClips {
  margin-top: 1.5rem;
  padding: 1rem;
}

@media (min-width: 30rem) {
  .videos,
  .tabClips {
    padding: 2rem;
  }
}

@media (min-width: 42rem) {
  .content {
    padding-top: 3.5rem;
  }
  .videos,
  .tabClips {
    margin-top: 2rem;
    padding: 4rem;
  }
}

.videoList {
  margin-top: 0.5rem;
}

.pendingDeliveryListItem + .videoListItem {
  border-top: 1px solid var(--color-border-transparent);
}

.repeatOrder + .videoListItem {
  margin-top: 2rem;
  border-top: 1px solid var(--color-border-transparent);
}

.pagination {
  margin-top: 2rem;
}

/* Content Header */

.contentHeader {
  display: grid;
  gap: 1rem;
  grid-template-columns: 48px 1fr;
  padding-left: 0.5rem;
  padding-right: 0;
}

.testTitle,
.testIcon {
  align-self: center;
}

.testIcon {
  display: none;
}

.testTitle {
  grid-column: 1 / span 2;
}

.testDetails {
  grid-column: span 2;
  max-width: none;
}

.tabNavigation {
  display: none;
  margin: 2rem auto 0;
}

.tabClassName {
  min-width: 0;
}

.menuNavigation {
  margin: 1.5rem 0.5rem 0 0.5rem;
  width: stretch;
}

@media (min-width: 42rem) {
  .contentHeader {
    display: grid;
    column-gap: 2rem;
    row-gap: 0.5rem;
    grid-template-columns: 96px auto auto;
  }

  .testTitle {
    grid-column: auto;
    align-self: flex-end;
  }

  .testDetails {
    grid-column: 2;
    overflow: hidden;
    align-self: flex-start;
    max-width: 25rem;
  }

  .testIcon {
    display: block;
    width: 96px;
    grid-row: span 2;
    align-self: center;
  }

  .tabNavigation {
    display: flex;
    margin: 3rem auto 0;
  }

  .menuNavigation {
    display: none;
  }

  .tabClassName {
    min-width: 9rem;
  }
}

.testTitle {
  font-family: var(--font-family-serif);
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0.2em; /* Fixes 'g', 'y', 'j', … not getting fully displayed */
  margin: 0 0 -0.2em 0;
}

.testDetails {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 0.5rem;
}

@media (min-width: 42rem) {
  .testDetails {
    flex-direction: row;
    gap: 0.75rem;
  }
}

.testDetailsItem {
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
  display: flex;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
}

.testDetailsItem_url {
  min-width: 0;
}

.testDetailsItem_url > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.testDetailsItem img {
  display: inline-block;
  vertical-align: middle;
}

.titleTesters {
  font-size: 1.5rem;
  font-family: var(--font-family-serif);
  font-weight: normal;
  margin: 0;
}

.showScreeners {
  background: none;
  border: 0;
  padding: 0;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-blue-400);
  cursor: pointer;
  display: flex;
  gap: 0.25em;
  align-items: center;
}

.showScreeners:hover {
  color: inherit;
}

.cardFetchingError {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

/* XXX: This button margin should maybe handled inside the empty state component */
.emptyStateButton {
  margin-top: 0.75rem;
}

.repeatOrder {
  margin-top: 1.5rem;
}

.pendingDeliveryListItem + .repeatOrder {
  margin-top: 0;
  padding-top: 2rem;
  border-top: 1px solid var(--color-border-transparent);
}

.repeatOrderInner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.75rem 1rem;
  background: var(--color-blue-050);
  border-radius: var(--border-radius-1);
}

.repeatOrder_paused .repeatOrderInner {
  padding-bottom: 1.25rem;
}

.repeatOrderContent {
  flex-basis: 1px;
  flex-grow: 1;
  min-width: 15rem;
  max-width: 100%;
}

.repeatOrderIcon {
  margin-top: 0.2765rem;
  width: 1.778rem;
  height: 1.778rem;
}

.repeatOrderCancel {
  margin: 0 auto;
  white-space: nowrap;
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
  align-self: center;
}

.repeatOrderTitle {
  color: var(--color-blue-500);
  font-size: var(--font-size-small);
  font-weight: bold;
}

.repeatOrderTargetingSummary {
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
}

.repeatOrderPauseMessage {
  color: var(--color-secondary-1);
  font-size: var(--font-size-small);
  margin-top: 0.75rem;
}

.repeatOrderErrorMessage {
  font-size: var(--font-size-small);
  margin-top: 1rem;
}

.repeatOrderPauseControl {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.repeatOrderPauseControl .repeatOrderCancel {
  margin: 0;
}

.aiHiddenInfo {
  text-align: center;
  margin-top: 2rem;
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
}

.aiHiddenInfo button {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.cardFetchingError {
  margin-top: 2rem;
  text-align: center;
  max-width: 32em;
  margin: 0 auto;
}

.cardFetchingErrorMessage {
  margin-top: 0.5rem;
}

.cardFetchingErrorMessageSub {
  margin-top: 0.5rem;
  font-size: var(--font-size-small);
  color: var(--color-blue-300);
}
