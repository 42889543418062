.contentHeaderButtons {
  grid-column: span 2;
  margin-top: 0.5rem;
  gap: 0.75rem;
  display: flex;
  align-items: center;
}

.editTestButton,
.previewTestButton {
  display: none;
}

.getTestersButton {
  width: 100%;
}

.overflowMenuTriggerButton {
  background: none;
  border: 0;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  color: var(--color-text-body);
  border-radius: var(--border-radius-button);
  margin-left: -0.25rem;
}

.overflowMenuTriggerButton:hover {
  background-color: var(--color-secondary-2-darker);
}

.overflowMenu hr {
  border-width: 1px;
  border-color: var(--color-border-transparent);
  margin: 0.5rem 0;
}

@media (min-width: 42rem) {
  .contentHeaderButtons {
    grid-row: 1 / span 2;
    grid-column: 3;
    margin-top: 0;
    justify-self: flex-end;
  }

  .editTestButton,
  .previewTestButton {
    display: block;
  }

  .overflowMenuItemSmallScreenFallback {
    display: none;
  }

  .getTestersButton {
    width: auto;
  }
}
