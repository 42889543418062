.root {
  display: block;
  border-radius: var(--border-radius-1);
  cursor: pointer;
  padding: 0.5rem 0.88rem;
  line-height: 1.2;
}

.root + .root {
  margin-top: 0.2rem;
}

.root:hover {
  background: var(--color-blue-100);
}

.root,
.root a {
  text-decoration: none;
  color: inherit;
  font-weight: normal;
}
