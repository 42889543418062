.taskResultsAskCompleted {
}

.result {
  font-size: 1.125rem;
  display: flex;
  font-style: italic;
  gap: calc(0.334rem - (1.25rem - 0.875rem) / 2);
  margin-left: calc((1.25rem - 0.875rem) / -2);
}

.icon {
  width: 1.25rem;
}

.result_notAsked {
}

.chart {
  margin-top: 2rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chartDonut {
  width: 10rem;
}

.chartLegend {
}

.chartLegendItem {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-blue-600);
}

.chartLegendItem:before {
  content: "";
  display: block;
  height: 1.25em;
  width: 1.25em;
  flex-shrink: 0;
  border-radius: var(--border-radius-1);
  position: relative;
  top: 0.2em;
}

.chartLegendItem + .chartLegendItem {
  margin-top: 1rem;
}

.chartLegendItem_successful:before {
  background: var(--color-primary-1);
}

.chartLegendItem_notSuccessful:before {
  background: var(--color-secondary-1);
}

.differingTeststepAskCompleted {
  margin-top: 0.5rem;
  font-style: italic;
  color: var(--color-blue-400);
}

.taskResponseItem_outdated .result {
  color: var(--color-blue-400);
}

.responses {
  padding: 0 var(--task-result-container-padding)
    var(--task-result-container-padding)
    calc(var(--task-result-container-padding) - 0.5rem);
}
