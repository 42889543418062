.centerContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 6rem 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.invitationForm {
  width: 100%;
  max-width: 24em;
  margin: 0 auto;
  box-sizing: border-box;
}

.form {
  composes: formContent from "../../helpers.css";
}

.form a {
  color: var(--color-gray-600);
}

.formTitle {
  margin: 0;
  text-align: center;
}

.signupAsATesterInfo {
  padding: 1rem;
  margin: 2rem 0 0 0;
  text-align: center;
  max-width: 100%;
  box-shadow: none;
  background: var(--color-gray-300);
}

.signupAsATesterInfo a {
  color: var(--color-gray-700);
}

.forgot-password {
  font-size: 0.875em;
  color: #666;
  margin-top: 0.5rem;
}

.checkboxTermsInfo {
  color: var(--color-blue-600);
  font-size: var(--font-size-small);
}

.decision .decisionButton:last-child {
  border-bottom: 2px solid var(--color-gray-300);
}

.inviteSubline {
  color: var(--color-gray-600);
  font-size: var(--font-size-small);
}

.buttonPromo {
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1em;
}

.belowButtonText a {
  white-space: nowrap;
}

.deleteAccountWarning {
  background-color: white;
  border-radius: var(--border-radius-card);
  padding: 1rem;
  margin: 1.5rem 0 0 0;
  border: 2px solid var(--color-red-200);
}

.loginBox {
  box-sizing: border-box;
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
}

.loginBox > *:first-child {
  margin-top: 0;
}

@media (min-width: 975px) {
  .centerContainer {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 6rem 2rem;
  }
}
