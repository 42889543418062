.taskResultsRedirect {
}

.redirectTo {
  color: var(--color-blue-400);
}

.differingTestStepRedirectTo {
  color: var(--color-blue-400);
}

.result {
  font-size: 1.125rem;
  display: flex;
  font-style: italic;
  gap: calc(0.334rem - (1.25rem - 0.875rem) / 2);
  margin-left: calc((1.25rem - 0.875rem) / -2);
}

.icon {
  width: 1.25rem;
}

.taskResponseItem_outdated .result {
  color: var(--color-blue-400);
}

.responses {
  padding: 0 var(--task-result-container-padding)
    var(--task-result-container-padding)
    calc(var(--task-result-container-padding) - 0.5rem);
}
