.featureGateMessage {
  font-size: 14px;
  background: var(--color-blue-100);
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem 1rem 1rem;
  border-radius: var(--border-radius-2);
  gap: 1rem;
}

.title {
  font-weight: bold;
  font-size: 1em;
  margin: 0;
}

.title + .text {
  margin-top: 0.25rem;
}

.button {
  font-size: 1em;
}

.icon {
  color: white;
  background-color: var(--color-blue-500);
  padding: 0.25em;
  border-radius: 1em;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.content > div {
  flex-basis: 15rem;
  min-width: 0;
  flex-grow: 1;
}

.icon > svg {
  height: 1em;
  width: 1em;
  display: block;
}
