.root {
  cursor: pointer;
  display: inline-block;
  padding: 0.2em 1em;
  border: 1px solid var(--color-border-gray);
  border-right: 0;
  font-size: 0.875em;
}

.root:last-child {
  border: 1px solid var(--color-border-gray);
}

.selected {
  font-weight: bold;
  background: var(--color-background-light-gray);
}
