.content {
  padding: 2rem 1rem;
}

@media (min-width: 975px) {
  .content {
    padding: 2rem 2rem 2rem 0;
  }
}

.contentWithoutSubNav {
  padding: 2rem 0.5rem;
  max-width: 60rem;
  margin: 0 auto;
}

@media (min-width: 25rem) {
  .contentWithoutSubNav {
    padding: 2rem 1em;
  }
}

.formContent {
  --container-padding-left: 1.5rem;
  --container-padding-right: 1.5rem;
  --container-padding-bottom: 1.5rem;
  --container-padding-top: 1.5rem;
  padding: var(--container-padding-left) var(--container-padding-right)
    var(--container-padding-bottom) var(--container-padding-top);
}
