.modalContent {
  padding: 0 1.25rem 0 1.25rem;
}

.modalContent > :first-child {
  margin-top: 0;
}

.additionalInfoCharge {
  color: var(--color-gray-600);
}

.footer {
  padding: 0 1rem 1rem 1rem;
  margin-top: 3rem;
}

.footerThankYou {
  padding: 0 1rem 1rem 1rem;
}

.thankYouSubline {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.termsAndConditions,
.termsAndConditions a {
  color: var(--color-gray-600);
}

.termsAndConditions {
  font-size: var(--font-size-small);
}
