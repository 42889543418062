.modalContent {
  padding: 0 1.5rem 0 1.5rem;
}

.modalContent > :first-child {
  margin-top: 0;
}

.footer {
  padding: 0 1rem 1rem 1rem;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}

.subline {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}
