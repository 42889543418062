.dropDownVideoCount {
  width: 5em;
  margin-right: 1rem;
  flex-shrink: 0;
}

.dropDownVideoCountContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.dropDownVideoCountLabel {
}

/*.root {
  margin: 0 -0.5em -0.5em 0;
}

.number {
  background-color: var(--color-background-gray);
  color: var(--color-text-on-background-gray);
  cursor: pointer;
  display: inline-block;
  height: 2em;
  min-width: 2em;
  border-radius: 2em;
  line-height: 2em;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.selected {
  background-color: var(--color-background-highligt1);
  color: var(--color-text-on-background-highligt1);
  cursor: default;
}

.description {
  font-size: 0.875em;
  color: var(--color-text-gray);
}*/
