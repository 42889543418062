.header {
  height: var(--main-navigation-height);
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.logoSvg {
  position: relative;
  height: 1.5rem;
  width: auto;
  display: block;
}
