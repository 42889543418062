.skeleton {
  height: 1.25rem;
  border-radius: 0.25rem;
  width: 100%;
  background: linear-gradient(
    90deg,
    var(--color-blue-050) 0,
    var(--color-blue-100) 25%,
    var(--color-blue-050) 50%,
    var(--color-blue-100) 75%,
    var(--color-blue-050) 100%
  );
  background-position: 0 0;
  background-size: 200% 100%;
  animation: loading 1s linear 0s infinite;
}

.skeleton + .skeleton {
  margin-top: 0.625rem;
}

@keyframes loading {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -200% 0;
  }
}
