.container {
  background: var(--color-blue-600);
  padding: 0.5rem;
  border-radius: var(--border-radius-button);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overflowMenu {
  margin-left: auto;
  --overflow-menu-offset-top: 2.25rem;
  --inline-icon-size: 0.889rem;
}

.overflowMenuTriggerButton {
  padding: 0.45rem 0.5rem 0.45rem 0.5rem;
  font-size: var(--font-size-small);
  position: relative;
  border: 0;
  background: none;
  cursor: pointer;
  color: var(--color-blue-100);
  border-radius: var(--border-radius-button);
}

.overflowMenuTriggerButton:disabled {
  color: var(--color-blue-400);
}

.overflowMenuTriggerButton:hover,
.overflowMenuTriggerButton_open {
  background: var(--color-blue-700);
}

.headerTitle {
  display: flex;
  gap: 0.5rem;
}

.headerTitle svg {
  color: var(--color-primary-1);
}

.headerTitle_note svg {
  color: #777dfb;
}

.headerButton,
.footerPreviewButton {
  --inline-icon-size: 0.889rem;
  border: 0;
  padding: 0;
  background: none;
  color: var(--color-blue-300);
  cursor: pointer;
}

.footerPreviewButton > svg {
  margin-right: 0.25em;
}

.footerPreviewButton:disabled {
  color: var(--color-blue-400);
}

.headerButton:disabled {
  color: var(--color-blue-400);
}

.headerButton:hover,
.footerPreviewButton:hover {
  color: var(--color-blue-100);
}

.footer {
  color: var(--color-blue-100);
  display: flex;
  align-items: baseline;
  padding-top: 0.5rem;
}

.footerCancel {
  border: 0;
  background: 0;
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}

.footerCancel:disabled {
  color: var(--color-gray-400);
}

.footerActions {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  margin-left: auto;
}
