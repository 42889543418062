.accountDataBox {
  padding: 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.content {
  margin-top: 2rem;
}
