.root {
  display: inline-block;
}

.root a,
.root button {
  display: inline-block;
  padding: 0.35em 0.5em;
  margin: 0;
  text-decoration: none;
  color: var(--color-blue-600);
  position: relative;
  left: -0.5em;
}

@media (min-width: 975px) {
  .root,
  .root a,
  .root button {
    display: block;
  }

  .root a,
  .root button {
    padding-left: 0;
    padding-right: 0;
    left: 0;
  }

  .root a:hover,
  .root button:hover {
    margin-left: -0.5em;
  }
}

.root a:hover,
.root button:hover {
  background: var(--color-secondary-2-darker);
  border-radius: var(--border-radius-button);
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.root--active a,
.root--active button {
  color: var(--color-blue-900);
  font-weight: 600;
}
