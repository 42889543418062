.modalForm > *:first-child {
  margin-top: 0;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.buttonGroup {
  margin-top: 1.5rem;
}
