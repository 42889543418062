.tabNavigation {
  display: flex;
  font-size: 1rem;
  background: var(--color-secondary-2-darker);
  border-radius: var(--border-radius-card);
  width: fit-content;
  max-width: 100%;
}

.tab {
  border: 0;
  background: none;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  min-width: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  cursor: pointer;
  color: var(--color-primary-2);
}

.icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 1.5em;
  width: 1.5em;
  flex-shrink: 0;
  position: relative;
  margin-right: 0.25rem;
}

.lockIcon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 1em;
  width: 1em;
  position: relative;
}

.tab_disabled {
  cursor: default;
}

.tab_disabled .icon,
.tab_disabled .label {
  opacity: 0.5;
}

.tab_notification .icon:before {
  content: "";
  display: block;
  position: absolute;
  right: -0.2em;
  top: -0.2em;
  border-radius: 1em;
  height: 0.5em;
  width: 0.5em;
  background-color: var(--color-secondary-1);
  border: 0.12em solid var(--color-secondary-2-darker);
}

.tab_active .icon:before {
  border-color: white;
}

.tab_notification img:after {
  content: "";
  display: block;
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
  background: var(--color-secondary-1);
}

.tab_active {
  flex-shrink: 0;
  background: white;
  border-radius: var(--border-radius-card);
  box-shadow:
    0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.04);
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  line-height: 1.25; /* Minimium to fix 'g', 'y', 'j', … not getting fully displayed */
}
