.centerContainer {
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  padding: 6.6rem 1rem;
}

@media (min-width: 975px) {
  .centerContainer {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 6.6rem 2rem;
  }
}
