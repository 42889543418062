.featureList {
  --line-height: 1.5em;
  margin: 0;
  text-align: left;
  font-size: 1em;
  line-height: var(--line-height);
  list-style: none;
  padding: 0;
}

.featureList li + li {
  margin-top: 0.8em;
}

.featureList li {
  padding: 0 0 0 1.8em;
  background-image: url(./check.svg);
  background-repeat: no-repeat;
  background-position: 0 0.15em;
  background-size: calc(var(--line-height) * 0.8);
}
