.root {
  background-color: rgba(0, 0, 0, 0.32);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
  overflow: auto;
  display: flex;
  padding: 0.5rem;
  will-change: opacity;
  align-items: flex-start;
}

.content {
  background: white;
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-card-elevated);
  position: relative;
  margin: 3rem auto 0;
  box-sizing: border-box;
  z-index: 2;
  max-width: 100%;
}

.content_centered {
  margin: auto;
}

.actions {
  display: flex;
}

.actions > * {
  margin-left: 2rem;
}

.actions *:first-child {
  margin-left: auto;
}

.enter {
  opacity: 0;
}

.enterActive {
  transition: opacity 200ms ease;
  opacity: 1;
}

.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  transition: opacity 200ms ease;
  opacity: 0;
}

.exitDone {
  opacity: 0;
}
