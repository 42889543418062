.taskResultsSingleChoice {
}

.result {
  font-size: 1.125rem;
  display: flex;
  font-style: italic;
  gap: calc(0.334rem - (1.25rem - 0.875rem) / 2);
  margin-left: calc((1.25rem - 0.875rem) / -2);
  min-width: 0;
}

.resultText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  width: 1.25rem;
}

.result_skipped {
  font-style: italic;
}

.barGraph {
  margin-top: 1.75rem;
}

.differingTeststepOptionsList {
  margin: 0.5rem 0 0 0;
  padding-left: 1.5em;
}

.taskResponseItem_outdated .result {
  color: var(--color-blue-400);
}

.responses {
  padding: 0 var(--task-result-container-padding)
    var(--task-result-container-padding)
    calc(var(--task-result-container-padding) - 0.5rem);
}
