.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: white;
  border: none;
  border-radius: 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.25s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.75);
  }

  &:active {
    filter: brightness(0.5);
    transition: none;
  }
}

.image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1.8/1;
}

.content {
  padding: 2.5rem 2.5rem 2rem 2.5rem;
}

.title {
  margin: 0;
  color: var(--color-primary-2);
  font-size: 1.5rem;
  line-height: 2rem;
}

.introduction {
  margin-top: 0.5rem;
}

.featuresListIntroduction {
  margin-top: 1.5rem;
}

.featureList {
  --line-height: 1.5em;
  margin: 0;
  margin-top: 12px;
  text-align: left;
  font-size: 1em;
  line-height: var(--line-height);
  list-style: none;
  padding: 0;
}

.featureList li + li {
  margin-top: 0.8em;
}

.featureList li {
  padding: 0 0 0 1.8em;
  background-image: url(./check.svg);
  background-repeat: no-repeat;
  background-position: 0 0.15em;
  background-size: calc(var(--line-height) * 0.8);
}

.disclaimer {
  color: var(--color-blue-600);
  font-size: 13px;
  line-height: 20px;
  margin-top: 12px;
}

.footerFinePrint,
.footerNoSessions {
  display: block;
  color: var(--color-blue-600);
  text-align: center;
}

.footerFinePrint {
  margin-top: 1rem;
}

.footerButton {
  width: 100%;
  margin-top: 1.5rem;
  padding: 0.625rem 1rem;
}
