.content {
  composes: contentWithoutSubNav from "../../helpers.css";
}

.card {
  padding: var(--padding-card);
}

.cardHeading {
  margin: 0;
  font-size: 2em;
}

.releaseNotesItem {
  margin-top: 1rem;
}

.releaseNotesItem h2 {
  margin: 0;
  font-weight: bold;
  font-size: 1.5em;
}

.releaseNotesItem ul {
  margin: 0.5em 0 0 0;
  padding: 0 0 0 1em;
}

.releaseNotesItem li {
  margin-top: 0.3rem;
}

.readMore {
  margin: 0.5em 0 0 0;
  font-size: 0.875em;
}
