.taskResponseList > * {
  margin-top: 1rem;
}

.showMoreTesters {
  margin-top: 2rem;
  background: none;
  border: 1px solid var(--color-border-transparent);
  border-radius: var(--border-radius-button);
  padding: var(--padding-button);
  padding-top: 0.25em;
  padding-bottom: 0.25em;

  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-blue-400);
  font-weight: 600;
  text-align: left;
}

.showMoreTesters:hover {
  color: var(--color-primary-2);
  background-color: var(--color-blue-050);
  --avatarGroup-border-color: var(--color-blue-050);
  --avatar-background-color: var(--color-blue-200);
}

.avatarGroup {
  font-size: 0.75rem;
}
