.root {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;
}

.alert {
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: white;
  padding: 1.25rem;
  border-radius: var(--border-radius-card);
  box-shadow: var(--shadow-card-elevated);
  width: 22rem;
  max-width: 100%;
}

.actions {
  margin-top: 1rem;
}
