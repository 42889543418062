.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain; /* better for mobile videos… */
  background-repeat: no-repeat;
  transition: opacity 1s;
  opacity: 0;
}

.image_success_network {
  opacity: 1;
}

.image_success_cache {
  transition: none;
  opacity: 1;
}

.childrenContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fallbackIcon {
  color: var(--color-blue-400);
}

.fallback {
  opacity: 0;
  transition: opacity 0.5s;
}

.fallback_display {
  opacity: 1;
}
