.planInfoBox {
  background: #ffffff;
  box-shadow:
    0 3px 2px -2px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 1.77rem;
  width: 100%;
}

.planHeader {
  text-align: left;
  display: flex;
  align-items: center;
}

.planHeader img {
  width: 64px;
  margin-right: 16px;
  flex-shrink: 0;
}

.planTitleWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;
  row-gap: 0.1rem;
}

.planTitle {
  margin: 0;
  font-size: 2rem;
  line-height: 1.2;
}

.planTitle_small {
  font-size: 1.334rem;
}

.mostPopular {
  display: block;
  padding: 0.3em 0.75em;
  font-size: 0.875rem;
  background-image: url(./line-most-popular.svg);
  background-size: cover;
  white-space: nowrap;
}

.planPrice {
  margin-top: 1.25rem;
  text-align: center;
}

.planPricePrice {
  font-size: 1.5rem;
  font-weight: 600;
}

.planPricePeriod {
  font-size: 0.875rem;
  color: var(--color-blue-600);
  margin-left: 6px;
}

.startFreeTrialButton {
  padding: 0.875rem 0;
  margin-top: 1.25rem;
  white-space: nowrap;
}

.featureList {
  --line-and-icon-height: var(--font-size-small);
  margin: 0;
  text-align: left;
  font-size: var(--font-size-small);
  line-height: var(--line-and-icon-height);
  list-style: none;
  padding: 0;
}

.featureList li + li {
  margin-top: 12px;
}

.featureList li {
  background-repeat: no-repeat;
  background-size: var(--line-and-icon-height);
  background-image: url(./featureListIconGreen.svg);
  padding-left: 32px;
}

.mainFeatures {
  border-top: 1px solid var(--color-blue-200);
  padding-top: 24px;
  margin-top: 24px;
}

.startFreeTrialButton + .paygSummaryRow {
  margin-top: 24px;
}

.paygSummaryRow {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  flex-wrap: wrap;
  border-top: 1px solid var(--color-border-on-light);
  font-size: var(--font-size-small);
  gap: 0.5rem;
}

.paygSummaryRowPricePerTester {
  color: var(--color-blue-500);
}

.paygSummaryRow_total {
  font-size: 1.25rem;
  font-weight: bold;
}

.cancelLinkContainer {
  margin-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

.cancelLink {
  font-size: var(--font-size-small);

  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;

  font-weight: 600;
  border: 0;
  color: var(--color-secondary-1);
  background: none;
  position: relative;

  white-space: nowrap;
  padding: 0;
  user-select: text;
  letter-spacing: 0.005em;
}

.cancelLink:before {
  content: "";
  background: url(./line-underline-cancel.svg) no-repeat center bottom;
  background-size: calc(105% - 5px) 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: -1px;
  right: -2px;
}

.onlyOwnerCanCancelInfo {
  font-size: var(--font-size-small);
  font-style: italic;
  color: var(--color-blue-400);
}

@media (min-width: 975px) {
  .planHeader img {
    width: 72px;
    margin-right: 20px;
  }

  .featureList {
    --line-and-icon-height: 22px;
  }

  .featureList li + li {
    margin-top: 18px;
  }
}

.outdatedInfo {
  font-size: var(--font-size-small);
  color: var(--color-blue-500);
  background: var(--color-blue-050);
  padding: 0.875rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
}

.planInfoBox_outdated {
  position: relative;
  overflow: hidden;
}

.planInfoBox_outdated:before {
  content: "Legacy plan";
  display: block;
  width: 13em;
  background: var(--color-blue-050);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  padding: 0.5em;
  transform: rotate(45deg);
  position: absolute;
  right: -3.6em;
  top: 2em;
}

@media (min-width: 975px) {
  .planInfoBox_outdated {
    grid-column: span 3;
    display: flex;
    column-gap: 5.54rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .planInfoBox_outdated > * {
    width: calc((100% - (2 * 2rem + 4 * 1.77rem)) / 3);
    border-top: 0;
    box-sizing: border-box;
  }

  .planInfoBox_outdated > *:nth-child(2) {
    display: none;
  }

  .outdatedInfo {
    margin-top: 0;
    order: 100;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
