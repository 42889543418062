.modal {
  width: 100%;
  max-width: 30rem;
}

.modalContent {
  padding: 0 1.25rem;
}

.modalContent > :first-child {
  margin-top: 0;
}

.roleDescription {
  margin-top: 0.1rem;
  color: var(--color-text-gray-on-white);
  font-size: var(--font-size-small);
}

.footer {
  padding: 0 1rem 1rem 1rem;
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}

.subline {
  font-size: 0.875rem;
  color: var(--color-gray-600);
}

.usage {
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
}
