.appTestingInfo {
  background: var(--color-gray-200);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  padding: 0.5rem;
}

.inputUrl {
  padding-right: calc(var(--padding-input-horizontal) + 1.2em);
}

.appTestingInfoIcon {
  margin-right: 0.5rem;
}

.headingTestInstructions,
.subHeadingTestInstructions {
  text-align: center;
}

.headingTestInstructions {
  font-family: var(--font-family-serif);
  font-size: 38px;
  font-weight: normal;
  margin: 40px 0 0 0;
}

.subHeadingTestInstructions {
  margin: 10px 0 0 0;
  font-size: 18px;
  color: var(--color-blue-600);
}

.card {
  padding: 1.5rem;
}

.lengthFakeInput {
}

.openUrlIcon {
  position: relative;
  left: 0.3em;
  top: -0.2em;
  font-size: 0.75em;
  opacity: 0.7;
}

a:hover .openUrlIcon {
  opacity: 1;
}

.inputWithUrlChecker {
  position: relative;
}

.urlChecker {
  position: absolute;
  right: var(--padding-input-horizontal);
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
}

.urlCheckerIcon {
}

.urlCheckerIcon_success {
  color: var(--color-primary-1);
}

.urlCheckerIcon_error {
  color: var(--color-secondary-1);
}

.spaceSm {
  margin-top: 2.5rem;
}

.spaceMd {
  margin-top: 3rem;
}

.spaceLg {
  margin-top: 3.5rem;
}

.divider {
  margin-top: 59px;
  border-width: 1px;
  border-color: var(--color-border-transparent);
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 130%;
}

.modalDescription {
  margin-top: 16px;
  font-size: 16px;
}

.autoModeLoading {
  margin-top: 3rem;
}

.autoModeLoading + .autoModeSkeleton {
  margin-top: 2.875rem;
}
