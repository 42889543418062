/* START Grid */

.videoListItem {
  display: grid;
  align-items: flex-start;
  gap: 1rem;
  grid-template-columns: 2.667rem 1fr;
  cursor: pointer;
}

.videoListItem_testing {
  grid-template-columns: 2.667rem 1fr;
  cursor: initial;
}

.statusInfo,
.buttonPlayVideo {
  grid-column: 2;
  width: 100%;
  justify-content: center;
  text-align: center;
}

@media (min-width: 30rem) {
  .videoListItem {
    grid-template-columns: 2.667rem 1fr 5rem;
  }

  .videoListItem_testing {
    grid-template-columns: 2.667rem 1fr 8.5rem;
  }

  .statusInfo,
  .buttonPlayVideo {
    justify-self: flex-end;
    grid-column: auto;
  }

  .buttonPlayVideo {
    width: fit-content;
  }

  .statusInfo {
    width: 8.5rem;
    text-align: left;
  }
}

@media (min-width: 45rem) {
  .videoListItem_testing {
    grid-template-columns: 2.667rem 1fr 5rem;
  }
}

.testDetails {
  overflow: hidden;
}

@media (min-width: 45rem) {
  .videoListItem {
    align-items: center;
  }
  .testDetails {
    display: grid;
    gap: 1rem;
    grid-template-columns:
      minmax(6rem, 2fr) minmax(5rem, 1fr) minmax(5rem, 1fr)
      1fr;
    align-items: center;
  }
  .videoListItem_withTitle .testDetails {
    grid-template-columns:
      minmax(6rem, 1.5fr) minmax(4rem, 1.5fr) minmax(5rem, 1fr)
      minmax(5rem, 1fr) 1fr;
  }
}

/* END Grid*/

.videoListItem + .videoListItem {
  border-top: 1px solid var(--color-border-gray);
}

.videoListItem {
  position: relative;
  padding: 2rem 0;
}

@media (min-width: 30rem) {
  .videoListItem {
    padding: 2rem 0.5rem;
  }
}

.statusInfo,
.buttonPlayVideo {
  padding: 0.5rem 0.875rem;
  border-radius: 2rem;
  white-space: nowrap;
  box-sizing: border-box;
}

.statusInfo {
  background: white;
  border: 2px solid var(--color-blue-200);
  padding: calc(0.5rem - 2px) calc(0.875rem - 2px);
  color: var(--color-blue-300);
}

.statusInfo_recording {
  border: 2px solid var(--color-orange-200);
  color: var(--color-orange-200);
}

.statusIcon {
  margin-right: 0.2rem;
  font-size: var(--font-size-small);
}

.buttonPlayVideo {
  color: white;
  background: var(--color-teal-500);
  border: 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 1.15;
}

.buttonPlayVideo:hover {
  background: var(--color-teal-400);
}

.buttonPlayVideoIcon {
  font-size: 0.675rem;
}

.videoListItem_new {
  position: relative;
}

.videoListItem_new:before {
  content: "";
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.5rem;
  left: -0.75rem;
  margin-top: -0.25rem;
  top: 3.33rem;
  background: var(--color-primary-1);
  position: absolute;
}

@media (min-width: 30rem) {
  .videoListItem_new:before {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 0.75rem;
    left: -0.75rem;
    margin-top: -0.375rem;
  }
}

@media (min-width: 45rem) {
  .videoListItem_new:before {
    top: 50%;
  }
}

.date,
.device,
.notesAndClips,
.title {
  font-size: var(--font-size-small);
  white-space: nowrap;
  color: var(--color-blue-400);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.testerName {
  font-size: 1.125rem;
  display: block;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 45rem) {
  .testerName,
  .targetingSummary {
    white-space: nowrap;
    width: 12rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 45rem) {
  /* Smaller size if table layout is active */
  .testDetails .targetingSummary {
    font-size: 0.75rem;
  }
}

.ratingStars {
  font-size: 0.75rem;
}

.notesAndClips {
  display: flex;
  gap: 0 0.5rem;
  min-width: 0;
  flex-wrap: wrap;
  text-decoration: none;
}

.noteOrClipCount {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.notesAndClips:hover,
.notesAndClips:hover .noteOrClipIcon {
  cursor: pointer;
  color: var(--color-primary-2);
}

.noteOrClipIcon {
  height: 1rem;
  width: 1rem;
}
