.header {
  grid-column: 1;
}

.headerLoginButton {
  margin-left: auto;
}

.videoPlayer {
  grid-column: 1;
}

.sidebar {
  grid-column: 2;
  grid-row: 1 / span 2;
  overflow: auto;
}
