.root {
  width: 100%;
}

.fullWidth {
  max-width: none;
}

.root > label + label {
  margin-top: 0.75rem;
}
