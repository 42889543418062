.existingCardHeader {
  display: flex;
  justify-content: space-between;
  font-size: 0.938rem;
}

.existingCardHeaderPaymentMethod {
  color: var(--color-blue-900);
  font-weight: bold;
}

.securedByStripe {
  font-size: 0.938rem;
  font-weight: lighter;
  text-transform: initial;
  color: var(--color-blue-400);
  letter-spacing: normal;
}

.securedByStripe > svg {
  margin-right: 0.35rem;
}

.existingCardBody {
  display: flex;
  align-items: center;
  background: var(--color-blue-100);
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-top: 0.25rem;
}

.existingCardNumberAndExpiry {
  margin-left: 0.75rem;
}

.existingCardChange {
  margin-left: auto;
  background: none;
  border: none;
  text-decoration: underline;
  height: fit-content;
  width: fit-content;
  color: var(--color-blue-700);
}

.existingCardChange:hover {
  cursor: pointer;
  color: var(--color-blue-900);
}

.existingCardNumber {
  color: var(--color-blue-700);
  font-weight: bold;
  font-size: 0.938rem;
}

.existingCardExpiry {
  font-size: 0.938rem;
  color: var(--color-blue-400);
}

.existingCardExpiry_expired {
  color: var(--color-text-error);
}

.paymentCards {
  display: flex;
  justify-content: space-between;
}

.paymentCardsIcon {
  --card-brand-icon-size: 1.75;
}

.newCardInputNumber,
.newCardInputExpiry,
.newCardInputCvc {
  padding: 1rem;
  border: 2px solid var(--color-gray-200);
  border-radius: var(--border-radius-1);
}

.newCardInputNumber:global(.StripeElement--focus),
.newCardInputExpiry:global(.StripeElement--focus),
.newCardInputCvc:global(.StripeElement--focus) {
  outline: 0;
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
}

/* I don not exactly know why we added this but I guess it was important: */
.newCardInputNumber:global(.StripeElement--webkit-autofill),
.newCardInputExpiry:global(.StripeElement--webkit-autofill),
.newCardInputCvc:global(.StripeElement--webkit-autofill) {
  background-color: #fefde5 !important;
}

.newCardInputNumberLabel {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.newCardInputExpiry {
  margin-right: -1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.newCardInput:global(.StripeElement--invalid),
.newCardInputExpiry:global(.StripeElement--invalid),
.newCardInputCvc:global(.StripeElement--invalid),
.newCardInputNumber_error,
.newCardInputExpiry_error,
.newCardInputCvc_error {
  position: relative;
  z-index: 1;
  border-color: var(--color-text-error);
}

.newCardInputCvc {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.newCardInputExpiryAndCvcFormFields {
  display: flex;
  justify-content: space-between;
  margin-top: 2.125rem;
}

.newCardInputExpiryFormField,
.newCardInputCvcFormField {
  width: 100%;
}

.newCardInputNumberFormField {
  margin-top: 3rem;
}

.newCardInputNumberCard {
  position: absolute;
  right: 0.85rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  --card-brand-icon-size: 1.75;
  background-color: var(--color-blue-100);
}

.inputWrapper {
  position: relative;
}

.tooltipIcon {
  color: var(--color-blue-400);
}
