.answer {
  margin-top: 1rem;
  margin-left: -1rem;
}

.questionType {
  margin-top: 0;
  max-width: 13em;
}

.questionTextArea {
  margin-top: 1rem;
  padding: 0.5em 0.75em;
}

.questionHeader {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0.5rem;
}

.removeQuestion {
  font-size: 0.875rem;
  color: var(--color-blue-400);
  text-decoration: none;
}

.removeQuestion:hover {
  text-decoration: underline;
}

.typeSelector {
  max-width: none;
}

.headingAnswers {
  color: var(--color-blue-600);
  font-size: 1.125rem;
}

.answerInfo {
  margin-top: 0.25rem;
  font-size: var(--font-size-small);
}
