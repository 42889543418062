.usageBoxItem h2 {
  font-size: 1rem;
  font-weight: normal;
  white-space: nowrap;
  margin: 0 0 0 0;
}

.info {
  white-space: nowrap;
  color: var(--color-blue-600);
  margin-top: 16px;
}

.hr {
  margin: 0;
  border: 0;
  border-top: 1px solid var(--color-border-on-light);
  padding-bottom: calc(0.5rem + 3px);
}

.hr,
.meter {
  margin-top: 1rem;
}

.number {
  font-weight: 600;
  color: var(--color-primary-2);
  font-size: 1.5rem;
}
