.content {
  padding: 0 1rem 1rem 1rem;
}

.content > *:first-child {
  margin-top: 0;
}

.buttonGroup {
  margin-top: 1.5rem;
}

.screenerQuestion {
  margin-top: 1.5rem;
}

.screenerAnswer {
  color: var(--color-blue-600);
  font-size: var(--font-size-small);
  margin-top: 1rem;
  display: flex;
}

.screenerAnswer > *:first-child {
  position: relative;
  top: 0.2em;
}

.screenerAnswer > *:last-child {
  margin-left: 0.5rem;
}

.screenerAnswer_selected {
  font-weight: bold;
  color: var(--color-blue-900);
}
