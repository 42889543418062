.aiTaskCreatorFeedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--color-blue-600);
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 160%;
}

.buttons {
  margin-top: 24px;
  display: flex;
  gap: 1.5rem;
}

.button {
  border: 1px solid var(--color-border-transparent);
  background: var(--color-secondary-2-darker);
  outline: none;
  border-radius: 12px;
  flex-shrink: 0;
  padding: 8px 32px;
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-blue-500);

  &:hover {
    cursor: pointer;
  }

  &:after {
    padding: 4.5px 10px;
    display: none;
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + 8px));
    text-align: center;
    border-radius: 8px;
    background: var(--color-blue-600);
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-wrap: nowrap;
  }

  &:hover::after {
    display: block;
  }
}

.button_sad {
  &:after {
    content: "Bad";
  }

  &:hover {
    background: var(--color-orange-200);
    color: #8b1a06;
  }
}

.button_neutral {
  &:after {
    content: "Okay";
  }

  &:hover {
    background: #ccc4c6;
    color: var(--color-blue-600);
  }
}

.button_happy {
  &:after {
    content: "Great";
  }

  &:hover {
    background: #67f9d4;
    color: var(--color-teal-600);
  }
}
