.root {
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;

  font-weight: 600;
  border: 0;
  color: var(--color-primary-2);
  background: none;
  position: relative;
  text-align: left;
  white-space: nowrap;
  padding: 0;
  user-select: text;
  letter-spacing: 0.005em;
}

.root:after {
  content: "";
  background: url(./arrow-right.svg);
  height: 8px;
  width: 5px;
  display: inline-block;
  margin-left: 5px;
}

.root:before {
  content: "";
  background: url(./line-underline-link.svg) no-repeat center bottom;
  background-size: calc(105% - 5px) 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: -1px;
  right: -2px;
}
