.form1 {
  padding: var(--padding-card);
  max-width: 30rem;
}

.form2 {
  padding: var(--padding-card);
}

.additionalDetails textarea {
  max-width: none;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.options {
  margin-top: 1rem;
}

.buttonGroup {
  margin-top: 1.5rem;
}
