.pendingDeliveryListItem {
  padding: 2rem 0.5rem;
}

.pendingDeliveryListItem + .pendingDeliveryListItem {
  border-top: 1px solid var(--color-border-transparent);
}

.statusInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.content {
  width: 100%;
}

.text {
  font-size: 1.125rem;
  color: var(--color-blue-400);
}

.statusIndicator {
  flex-shrink: 0;
  background-image: url("./squircle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.targetingSummary {
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
}

.overflowMenu {
}

/* Fix for repeats at tooltip not visible */
@media (min-width: 45rem) {
  .testerDetails {
    overflow: visible;
  }
}
