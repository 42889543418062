.registrationContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
}

.registrationBoxContainer {
  order: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.registrationBox {
  box-sizing: border-box;
  width: 100%;
  max-width: 30rem;
}

.haveAnAccountInRegistrationBox {
  margin-top: 2rem;
  border-top: 1px solid var(--color-border-on-light);
  padding-top: var(--container-padding-bottom);
}

.haveAnAccountOutsideRegistrationBox {
  display: none;
  margin-left: auto;
}

.startFreeTrialTitle {
  margin: 0;
  width: 100%;
  padding: 0 2rem;
  max-width: 34rem;
  text-align: center;
  box-sizing: border-box;
  align-self: center;
  font-size: 2rem;
  font-weight: normal;
  line-height: 2.44rem;
  font-family: var(--font-family-serif);
  order: 1;
}

.startFreeTrialTitle em {
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-base);
}

.startFreeTrialTitle em:after {
  content: "";
  position: absolute;
  display: block;
  left: -0.4em;
  right: -0.2em;
  height: 0.5em;
  bottom: -0.4em;
  background-size: contain;
  background-image: url(./underline.svg);
  background-repeat: no-repeat;
}

.haveAnAccount {
  font-weight: bold;
  font-size: var(--font-size-small);
}

.haveAnAccountAndButton {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.allFreeTrialsIncludeTitle {
  font-size: 1.25rem;
  margin: 0;
}

.allFreeTrialsInclude {
  order: 3;
  width: 100%;
  padding: 0 2rem;
  max-width: 34rem;
  box-sizing: border-box;
  align-self: center;
}

.featureList {
  margin-top: 1rem;
}

.form {
  composes: formContent from "../../../helpers.css";
}

.buttonPromo {
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1em;
}

/* .form a {
  color: var(--color-gray-600);
} */

@media (min-width: 640px) {
  .haveAnAccountOutsideRegistrationBox {
    display: block;
  }
  .haveAnAccountInRegistrationBox {
    display: none;
  }
}

@media (min-width: 975px) {
  .centerContainer_registration {
    padding: 8rem 2rem 6rem 2rem;
  }

  .registrationBoxContainer {
    background-image: none;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    position: relative;
  }

  .registrationContainer {
    margin: 0 auto;
    width: 100%;
    max-width: 57rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    row-gap: 2rem;
  }

  .registrationBox {
    margin: 0;
  }

  .startFreeTrialTitle {
    grid-column-start: 1;
    align-self: end;
    text-align: left;
    padding: 0;
    position: relative;
  }

  .allFreeTrialsInclude {
    grid-column-start: 1;
    align-self: start;
    padding: 0;
  }

  .layoutCenterWithLogo_freePlan {
    background-image: url(./line-free-plan.svg);
    background-repeat: no-repeat;
    background-position: calc(50%) calc(50% - 110px);
  }
}

@media (min-width: 1660px) {
  .layoutCenterWithLogo_freePlan {
    background-image: none;
  }
}

/* Free plan */

.freePlan {
  text-align: center;
  grid-row-start: 1;
  grid-row-end: 3;
}

.freePlanImage {
  display: none;
  margin: 0 auto;
}

.freePlanTitle {
  margin: 1.5rem auto 0;
  font-weight: normal;
  line-height: 2.44rem;
  font-family: var(--font-family-serif);
}

.freePlan em {
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-base);

  display: inline-block;
  padding: 0 0.35em 0 0.37em;
  background-image: url(./line.svg);
  background-size: 100% 100%;
  white-space: nowrap;
}
/* 
.freePlan em:after {
  content: "";
  position: absolute;
  display: block;
  left: -0.4em;
  right: -0.2em;
  height: 0.5em;
  bottom: -0.4em;
  background-size: contain;
  background-image: url(./underline.svg);
  background-repeat: no-repeat;
} */

.freePlanSubtitle {
  margin-top: 1rem;
}

@media (min-width: 975px) {
  .freePlanImage {
    display: block;
  }
}
