.testResults {
}

/* START Container, similar to .videos in Test */

.taskResultContainer {
  --task-result-container-padding: 1rem;
}

.taskResultContainer_withPadding {
  padding: var(--task-result-container-padding);
}

.taskResultContainer,
.infoBox {
  margin-top: 1.5rem;
}

@media (min-width: 30rem) {
  .taskResultContainer {
    --task-result-container-padding: 2rem;
  }
}

@media (min-width: 42rem) {
  .taskResultContainer,
  .infoBox {
    margin-top: 2rem;
  }
  .taskResultContainer {
    --task-result-container-padding: 4rem;
  }
}

/* END */

.infoBoxLink {
  font-size: var(--font-size-small);
  color: var(--color-blue-500);
}
