.textarea:focus {
  box-shadow: none;
}

.taskTypeSelect {
  float: left;
  width: 100%;
}

.customizeTaskButtonWrapper {
  position: relative;
}

.customizeTaskButton {
  position: absolute;
  font-size: 0.875em;
  bottom: 0.25em;
  right: 0;
}

.customizeTaskButton__caret {
  margin-left: 0.33em;
}

.taskTypeSelectItems {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
  margin: -0.5rem;
}

.taskTypeSelectItems:after {
  /* clearfix */
  content: "";
  clear: both;
  display: table;
}

.taskTypeSelect__item {
  text-align: center;
  width: calc(20% - 1rem);
  margin: 0.5rem;
  min-width: 6rem;
  white-space: nowrap;
  cursor: pointer;
  border: 2px solid var(--color-gray-300);
  border-radius: var(--border-radius-card);
  font-size: 0.875em;
  float: left;
  box-sizing: border-box;
  line-height: 2;
  padding: 0.5em 0.5em 0 0.5em;
}

.taskTypeSelect__item--active {
  border-color: var(--color-logo-cyan);
}

.taskTypeSelect__item:before {
  content: "";
  display: block;
  width: calc(100%);
  padding-bottom: calc(71.239%);
  background-repeat: no-repeat;
  background-size: contain;
}

.taskTypeSelect__itemCustom {
}

.taskTypeSelect__itemDefault:before {
  background-image: url(./task-icon-others.svg);
}

.taskTypeSelect__itemEcommerce:before {
  background-image: url(./task-icon-ecommerce.svg);
}

.taskTypeSelect__itemBooking:before {
  background-image: url(./task-icon-booking.svg);
}

.taskTypeSelect__itemSaas:before {
  background-image: url(./task-icon-saas.svg);
}

.taskTypeSelect__itemCompany:before {
  background-image: url(./task-icon-company.svg);
}

.restoreTaskButton {
  font-size: 0.875em;
  margin-left: 0.5rem;
}

.restoreTaskButtonIcon {
  margin-right: 0.2em;
  font-size: 0.9em;
}

.taskToolbar {
  background-color: var(--color-gray-200);
  padding: 0.75rem 0.75rem 0.95rem 0.75rem;
  display: flex;
  border-radius: 0.2rem 0.2rem 0 0;
  margin-bottom: -0.2rem;
}

.taskToolbarButton {
  cursor: pointer;
  color: var(--color-gray-800);
  background: var(--color-gray-200);
  border: 1px solid var(--color-gray-400);
  border-radius: 0.2rem;
  margin-right: 0.5em;
  font-size: var(--font-size-small);
  padding: 0.33em 0.5em;
  box-shadow: var(--shadow-button);
}

.taskToolbarButton:hover {
  background: var(--color-gray-100);
  border: 1px solid var(--color-gray-500);
}

.taskToolbarButtonPreview {
  margin-left: auto;
  margin-right: 0;
}
