.insights {
  margin-top: 2rem;
}

.regeneratePrompt {
  padding: 1rem;
  background: var(--color-blue-200);
  border-radius: var(--border-radius-2);
  display: flex;
  gap: 1rem;
  /* XXX: When the button is hidden baseline does not work? */
  align-items: center;
}

.regeneratePromptIcon {
  align-self: center;
}

.regeneratePromptButton {
  margin-left: auto;
}

.regeneratePrompt + .summary {
  margin-top: 2rem;
}

.regeneratePromptSpinner {
  margin-left: auto;
  align-self: center;
  animation: spin 1s linear infinite;
}

.testerOverviewEmptyState {
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-border-transparent);
  color: var(--color-primary-2);
  font-size: 1.25rem;
  font-style: italic;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testerOverviewEmptyStateButton {
  margin-top: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.summary,
.emptyState {
  padding: var(--container-padding-left) var(--container-padding-right)
    var(--container-padding-bottom) var(--container-padding-top);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border-transparent);
}

.tabsTab {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 0.9rem;
  color: var(--color-blue-600);
  position: relative; /* Needed to position the count properly */
  padding: 0.75em 1em;
  font-weight: 600;
  margin-bottom: -1px; /* to move the tab over the border bottom of .tabs */
  border-bottom: 3px solid transparent;
}

.tabsTab:hover {
  color: var(--color-blue-700);
}

.tabsTab_active {
  color: var(--color-primary-2);
  border-bottom: 3px solid var(--color-primary-1);
}

.tabsTabCount {
  background-color: var(--color-blue-050);
  border-radius: var(--border-radius-1);
  padding: 0.2rem 0.3rem;
  font-size: var(--font-size-small);
  display: inline-block;
  margin-left: 0.5rem;
}

.tab {
  display: none;
}

.tab_active {
  display: block;
}

.tab {
  margin-top: 2rem;
}

.insightsSection,
.emptyState {
  margin-top: 2rem;
}

.emptyStateButton {
  margin-top: 0.75rem;
}

.welcomeMessage + .summary {
  margin-top: 2rem;
}

.regeneratePrompt + .welcomeMessage {
  margin-top: 2rem;
}

.welcomeMessage {
  --welcome-message-close-button-margin: 1rem;
  --welcome-message-close-button-size: 2rem;
  position: relative;
  border-radius: var(--border-radius-card);
  border: 1px solid var(--color-border-transparent);
  background: var(--color-secondary-2-darker);
  padding: var(--container-padding-top) var(--container-padding-right)
    var(--container-padding-bottom) var(--container-padding-top);
}

.welcomeMessageHeader svg {
  height: 3.5rem;
  width: 3.5rem;
}

.welcomeMessageHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.welcomeMessageHeading,
.payWallHeading {
  color: var(--color-primary-2);
  font-family: var(--font-family-serif);
  font-weight: 400;
}

.welcomeMessageHeading {
  --margin-right: calc(
    var(--welcome-message-close-button-margin) +
      var(--welcome-message-close-button-size)
  );
  margin: 0 var(--margin-right) 0 0;
}

.welcomeMessageText {
  color: var(--color-primary-2);
  font-size: 1.125rem;
  margin-top: 1.5rem;
}

.welcomeMessageButtons {
  margin-top: 1.5rem;
}

.welcomeMessageCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--welcome-message-close-button-size);
  width: var(--welcome-message-close-button-size);
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: var(--welcome-message-close-button-margin);
  top: var(--welcome-message-close-button-margin);
  border-radius: 0.25rem;
  transition: background 0.25s;
}

.welcomeMessageCloseButton svg {
  flex-shrink: 0;
}

.welcomeMessageCloseButton:hover {
  cursor: pointer;
  background: white;
}

.payWall {
  margin-top: 2rem;
  padding: var(--container-padding-top) var(--container-padding-right)
    var(--container-padding-bottom) var(--container-padding-top);
}

/* START Container, similar to .videos in Test */

.insights {
  --container-padding-left: 1rem;
  --container-padding-right: 1rem;
  --container-padding-top: 1rem;
  --container-padding-bottom: 1rem;
}

@media (min-width: 30rem) {
  .insights {
    --container-padding-left: 2rem;
    --container-padding-right: 2rem;
    --container-padding-top: 2rem;
    --container-padding-bottom: 2rem;
  }
}

@media (min-width: 42rem) {
  .insights {
    --container-padding-left: 4rem;
    --container-padding-right: 4rem;
    --container-padding-top: 4rem;
    --container-padding-bottom: 4rem;
  }
  .welcomeMessage {
    --welcome-message-close-button-margin: 2rem;
  }
  .welcomeMessageText {
    margin-top: 2.5rem;
  }
}

.footerLinkContainer {
  margin-top: 2rem;
  text-align: center;
}
