.image {
  width: 100%;
}

.content {
  padding: 0 2rem 2rem 2rem;
}

.overline {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 1rem;
}

.title {
  font-family: var(--font-family-serif);
  font-size: 1.5rem;
  margin: 0.75rem 0 0 0;
  font-weight: normal;
}

.description {
  margin-top: 0.75rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.link {
  display: block;
  margin-top: 1rem;
}

.closeButtonIcon {
  height: 1em;
  width: 1em;
  display: block;
}

.closeButton {
  padding: 0.7rem;
  box-sizing: border-box;
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: 0;
  background: 0;
  cursor: pointer;
  display: block;
  background: white;
  border-radius: 50%;
}

.image {
}

.overline {
}

.title {
}

.description {
}

.buttons {
}
