.barGraphRatingScale {
  font-size: 0.875rem;
  color: var(--color-blue-600);
}

.labels {
  display: flex;
  justify-content: space-between;
}

.labelStart {
}

.labelEnd {
}

.labels + .bars {
  margin-top: 0.5rem;
}

.bars {
  display: flex;
  gap: 1rem;
}

.barLabel {
  margin-top: 0.25rem;
  text-align: center;
  color: var(--color-blue-400);
}

.barCol {
  width: 100%;
}

.bar {
  height: 5rem;
  border-radius: var(--border-radius-1);
  background: var(--color-blue-100);
  position: relative;
  overflow: hidden;
}

.barBar {
  background: var(--color-primary-1);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
