.root {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-button);
  background: var(--color-button-gray);
  padding: var(--padding-button);
  color: var(--color-blue-600);
  font-weight: 600;
  white-space: nowrap;
  font-size: var(--font-size-uppercase);
  box-shadow: var(--shadow-button);
  line-height: var(--line-height-button);
  transition: var(--transition-button);
  text-align: center;
}

.root:focus {
  outline: 0;
}

.root:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.root:hover {
  background-color: var(--color-button-gray-hover);
  box-shadow: var(--shadow-button-hover);
  transition: var(--transition-button-hover);
}

.root:disabled,
.root:disabled:hover {
  background: var(--color-blue-050);
  border: 1px solid var(--color-blue-050);

  box-shadow: none;
}

.root:disabled {
  color: var(--color-blue-300);
}

.fullWidth {
  width: 100%;
}

.big {
  border-radius: var(--border-radius-2);
  padding: var(--padding-button-big);
}
