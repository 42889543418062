.notificationFixed {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1;
}

.notification {
  background: white;
  box-shadow: var(--shadow-button);
}

.notification___inner {
  margin: 0 auto;
  max-width: 60rem;
  display: flex;
  padding: 0 1rem;
  align-items: center;
}

.notification--error {
  background: #e9b6b0;
  color: #904848;
}

.notification--error .notification__action button {
  color: #904848;
}

.notification__content {
  padding: 0.6rem 0;
  font-size: var(--font-size-small);
}

.notification__action {
  margin-left: auto;
}

/* Not an ideal transition solution :-/ */

.notification--enter {
  margin-top: -2.5rem;
}

.notification--enter-active {
  margin-top: 0;
  transition: margin-top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.notification--exit {
  margin-top: 0;
  opacity: 1;
  transform: scaleY(1);
}

.notification--exit-active {
  margin-top: -2.5rem;
  opacity: 0;
  transition:
    margin-top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    opacity 225ms ease-out;
}

.notificationWrapper {
  height: 2.5rem;
}

.snackbar {
  z-index: 1400;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: flex-end;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  pointer-events: none;
}

.snackbar__snackbar {
  color: #fff;
  display: flex;
  padding: 0.5em 1em;
  min-width: 15rem;
  max-width: 25rem;
  background-color: var(--color-gray-800);
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  flex-wrap: wrap;
  align-items: baseline;
  border-radius: var(--border-radius-card);
  pointer-events: auto;
}

.snackbar__content {
}

.snackbar__action {
  margin-left: auto;
  padding-left: 1em;
}

.snackbar__dismiss {
  color: var(--color-gray-500);
}

.snackbar__dismiss:hover {
  color: var(--color-gray-300);
}

.snackbar--enter {
  transform: translate(0, 5rem);
}

.snackbar--enter-active {
  transform: translate(0, 0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.snackbar--exit {
  transform: translate(0, 0);
}

.snackbar--exit-active {
  transform: translate(0, 5rem);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
