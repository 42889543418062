.userbrainPlans {
}

.infoBox {
  font-size: var(--font-size-small);
  color: var(--color-primary-2);
  background-color: rgba(10, 10, 45, 0.09);
  border-radius: var(--border-radius-card);
  padding: 0.875rem;
  width: fit-content;
  max-width: 1280px;
  margin: 1.5rem auto 0 auto;
}

.infoBox + .plans {
  margin-top: 2rem;
}

.title {
  margin: 2rem 0 0 0;
  text-align: center;
}

.toggleBillingCycle {
  position: relative;
  margin: 4rem auto 0 auto;
}

.plans {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  margin: 3rem auto 0 auto;
  max-width: 1280px;
  justify-content: space-around;
}

.viewAllFeatures {
  max-width: 1280px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  margin: 1.5rem auto 0 auto;
  padding: 1.5rem 0;
  font-size: var(--font-size-small);
}

.viewAllFeaturesLink {
  color: var(--color-primary-2);
}

.bullets {
  margin: 3rem auto 0 auto;
  max-width: 1280px;
}

.bulletsItem {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.bulletsItem > div {
  margin-left: 0.875rem;
}

.bulletsItem img {
  width: 5rem;
  flex-shrink: 0;
}

.bulletsItem h2 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.bulletsItem p {
  color: var(--color-blue-700);
  margin: 4px 0 0 0;
  font-size: 13px;
  line-height: 18px;
}

.fancyLink {
  font-size: 1em;
  font-weight: 600;
  color: var(--color-primary-2);
  text-decoration: underline var(--color-primary-1) 0.1em;
  text-underline-offset: 0.3em;
}

@media (min-width: 975px) {
  .plans {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .userbrainPlans_twoColLayout .plans {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    max-width: 840px;
  }

  .toggleBillingCycle {
    margin: 2rem auto 0 auto;
  }

  .toggleBillingCycle:before {
    background-image: url(./billingCycleArrowLeft.svg);
    background-position: left bottom;
    background-size: 38px 31px;
    padding: 0 0 40px 10px;
    top: 10px;
    right: -203px;
  }

  .bullets {
    display: flex;
  }

  .bullets {
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
  }

  .bulletsItem {
    flex: 1 0 0;
    max-width: 325px;
    margin-top: 0;
  }
}

@media (min-width: 1280px) {
  .bullets {
    gap: 48px;
  }
}
