.root {
  padding: 2em;
  border-bottom: 2px solid var(--color-border-gray);
  margin-bottom: 2em;
}

/* Clearfix */
.root::after {
  content: "";
  clear: both;
  display: table;
}

.root h1 {
  margin: 0;
  float: left;
  display: block;
}

.actions {
  display: inline-block;
  float: right;
}
