.Popup {
  box-sizing: border-box;
}

.Popup--TestsUploadingWithoutLogin {
  width: 28rem;
  text-align: center;
}

.Popup__Title {
  margin: 0 0 1rem 0;
  font-weight: bold;
  font-size: 1.4rem;
}
.Popup--Loading {
  text-align: center;
}

.Popup__SignUp {
  font-size: 0.875em;
  margin: -1em 0 1.5rem;
}

.Popup__BelowForm,
.Popup__BelowTest {
  font-size: 0.875em;
  text-align: center;
  margin: 1rem 0 0 0;
}

.Popup__BelowTest {
  margin: 0 0 1rem 0;
}

.Popup__Subtitle {
  max-width: 22rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.8rem;
}

/* Available test */

.Popup__AvailableTest {
  text-align: center;
  margin-bottom: 1.5rem;
}

.Popup__AvailableTest h2 {
  font-size: 1.6rem;
  margin: 0 auto 0 auto;
  line-height: 1.2;
}

.Popup__AvailableTest h2 + .Popup__AvailableTestButton {
  margin-top: 1rem;
}

.Popup__RecordedTests {
  margin: 0;
  padding: 0;
}

.Popup__RecordedTests li {
  overflow: auto;
  border-top: 1px solid var(--color-gray-300);
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Popup__RecordedTests__recorded {
  width: 45%;
  float: right;
  font-size: 0.875em;
  color: var(--color-gray-600);
  padding: 8px 0 0 0;
  text-align: right;
}

.Popup__RecordedTests__recorded--one-line {
  padding-top: 18px;
}

.Popup__RecordedTests__recorded a {
  cursor: pointer;
  text-decoration: underline;
}

.Popup__TestingCodeInfo {
  color: var(--color-gray-600);
  font-size: 0.875em;
  margin-bottom: 2rem;
  position: relative;
}

.Popup__Logout {
  position: absolute;
  right: 0;
  top: 0;
}

.TestDetail__Task {
}

.TestDetail__TaskPaused,
.TestDetail__TaskPlaceholder {
  text-align: center;
  margin-bottom: 1.6rem;
  font-size: 1.125rem;
  color: #616161;
}

.TestDetail__TaskPaused {
  margin: 1.6rem 0;
  font-style: italic;
}

.TestDetail__TaskStepInfo {
  margin: 0;
  font-size: var(--font-size-small);
  color: var(--color-gray-500);
}

.TestDetail__Header {
  display: flex;
  justify-content: space-between;
}

.TestDetail__Navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.75rem 0 0 0;
  padding: 1rem 0 0;
  border-top: 1px solid var(--color-border-gray);
}

.TestDetail__Back {
  color: var(--color-gray-600);
  text-decoration: none;
  margin-bottom: 0.5em;
}

.TestDetail__Back:hover {
  text-decoration: underline;
}

.TestDetail__AvailableAudioSources {
  width: 80%;
  font-size: 1em;
}

.TestDetail__RecordingControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TestDetail__TopRecordingControls {
  font-size: 0.875rem;
  position: absolute;
  top: 0.95rem;
  right: 1rem;
}

.TestDetail__TopRecordingControls button {
  display: inline-block;
  margin-left: 0.5em;
}

.AudioSelection label {
  font-weight: bold;
  font-size: 0.875rem;
}

.AudioSelection {
  border-top: 1px solid var(--color-gray-300);
  padding: 0.6rem 0 0.9rem;
  display: flex;
}

.AudioSelection > div {
  width: 50%;
}

.selectAudioTitle {
  margin: 0;
  font-size: 1.125rem;
}

.AudioSelectionExample {
  display: flex;
  text-align: left;
  border: 1px solid #dedede;
  border-radius: 0.2rem;
  padding: 1rem;
  margin-top: 1rem;
}

.AudioSelectionExample > div {
  width: 50%;
}

.AudioSelectionExample label {
  display: block;
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: 0;
}

.AudioSelectionExample label + select {
  margin-top: 0.2rem;
  width: 90%;
}

.shareYourScreenTitle {
  margin: 0;
  font-size: 1.125rem;
}

.shareYourScreenTitle + p,
.selectAudioTitle + p {
  opacity: 0.8;
}

.shareYourScreen {
  display: block;
  margin: 1rem auto 0;
}

.buttonIcon {
  position: relative;
  left: -0.3rem;
}

.uploadingTests {
  font-size: var(--font-size-small);
  border-bottom: 1px solid var(--color-gray-300);
  padding-bottom: 1rem;
}
.uploadingTests + .Popup__Title {
  margin-top: 0.5rem;
}

.loginInputPassword {
  margin: 0 0 1rem 0;
}

.invitationTerms {
  text-align: center;
  font-size: var(--font-size-small);
  margin: 0.8rem 0 0 0;
  color: var(--color-gray-600);
}

.invitationFormBack {
  color: var(--color-gray-600);
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.invitationFormBack:hover {
  text-decoration: underline;
}

.invitationTerms a {
  color: var(--color-gray-600);
}

.forgotPassword,
.forgotPassword {
  font-size: 0.875em;
  margin-top: 0.5rem;
}

.forgotPassword a {
  color: var(--color-gray-600);
}

/* Ready to test */

.readyToTest {
  text-align: center;
  padding-bottom: 1rem;
}

.readyToTest p {
  margin-bottom: 0;
}

.readyToTestHeading {
  font-size: 1rem;
}

.readyToTestImage {
  display: block;
  margin: 1rem auto 0 auto;
}

.readyToTestImage + p {
  margin-top: 1rem;
}

.readyToTestNextButton {
  margin: 1rem 0 0 0;
}

.TestDetail__NavigationNext {
  margin-left: auto;
}
