.selectCreationType {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.selectCreationTypeCard {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  min-width: 175px;
  justify-content: space-between;
  border-radius: 0.75rem;
  border: 1px solid var(--color-border-transparent);
  background: var(--color-secondary-2-darker);
  box-sizing: content-box;

  &:hover {
    cursor: pointer;
  }
}

.selectCreationTypeCardTitle {
  color: var(--color-primary-2);
  text-align: center;
  font-family: var(--font-family-base);
  font-weight: bold;
  font-size: 17px;
  margin-top: 1rem;
}

.selectCreationTypeCardDescription {
  color: var(--color-blue-600);
  text-align: center;
  font-family: var(--font-family-base);
  font-size: 14px;
  line-height: 150%;
  margin-top: 0.5rem;
}

.selectCreationTypeFooter {
  margin-top: 2.5rem;
  color: var(--color-blue-600);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;

  & a {
    color: inherit;
  }
}

.selectCreationTypeCardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 2.75rem;
  height: 2.75rem;
  flex-shrink: 0;
  font-weight: bold;
  color: white;
  border-radius: 10rem;
  background: var(--color-primary-2);
  margin-top: 1.5rem;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }

  span {
    display: none;
  }
}

.selectCreationTypeCard:hover .selectCreationTypeCardButton {
  width: 100%;
  border-radius: 0.5rem;

  span {
    display: initial;
  }

  svg {
    display: none;
  }
}

.cardSecondary,
.cardPrimary,
.banner {
  display: flex;
  padding: 1.25rem 2rem 1.25rem 1.5rem;
  border-radius: 0.75rem;
}

.cardSecondary,
.cardPrimary {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.banner {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}

.cardSecondary {
  border: 1px solid var(--color-border-transparent);
  background: var(--color-secondary-2-darker);
}

.bannerIcon {
  display: none;
}

@media (min-width: 480px) {
  .bannerIcon {
    display: block;
  }
}

.bannerTitleAndDescription {
  font-family: var(--font-family-base);
}

.bannerTitle {
  color: var(--color-primary-2);
  font-size: 17px;
  font-weight: bold;
}

.bannerDescription {
  color: var(--color-blue-600);
  margin-top: 0.05rem;
  font-size: 15px;
  line-height: 150%;
}

.bannerDescription button {
  background: none;
  padding: 0;
  border: none;
  text-decoration-line: underline;
}

.bannerDescription button:hover {
  cursor: pointer;
}

.skeleton {
  display: flex;
  padding: 2rem 1.5rem 5rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.skeletonTitle {
  height: 2.75rem;
  width: 17.5rem;
}

.skeletonDescription {
  width: 100%;
  height: 7.5rem;
}

.promptTitle {
  color: var(--color-primary-2);
  font-size: 0.875rem;
  font-weight: bold;
}

.promptInputDescription {
  display: flex;
  margin-top: 0.5rem;
  padding: 1.125rem 1.25rem 3.5rem 1.25rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 2px solid var(--color-blue-200);
  outline: none;
  resize: none;
  overflow: hidden;

  &:focus {
    border-color: var(--color-primary-1);
  }
}

.promptInputDescriptionHint {
  color: var(--color-blue-600);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.promptErrorMessage ~ .promptTitle {
  margin-top: 1rem;
}

.promptButton {
  margin-left: auto;
  margin-top: 2.25rem;
}

.updateButton {
  margin-left: auto;
}

.examples {
  margin-top: 2rem;
  font-size: 0.875rem;

  & li {
    color: var(--color-blue-600);
    margin-top: 0.5rem;
  }
}

@media (min-width: 426px) {
  .selectCreationTypeCardTitle {
    margin-top: 1.5rem;
  }

  .selectCreationTypeCard {
    min-width: 150px;
    max-width: 200px;
  }
}

@media (min-width: 500px) {
  .selectCreationTypeCard {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 1025px) {
  .selectCreationType {
    gap: 2rem;
  }
}
