.taskResultsWrittenResponse {
}

.result {
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordCloud {
  margin: 1rem auto 0 auto;
}

.taskResponseItem_outdated .result {
  color: var(--color-blue-400);
}

.responses {
  padding: 0 var(--task-result-container-padding)
    var(--task-result-container-padding)
    calc(var(--task-result-container-padding) - 0.5rem);
}
