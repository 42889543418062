.brandTitle {
  text-align: center;
  font-weight: 400;
  font-size: var(--font-size-small);
  opacity: 0.5;
}

.brandTitle + .brandList,
.brandList + .brandTitle {
  margin-top: 1rem;
}

.brandBox {
  min-height: auto !important;
}

.brandBox .brandList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 767px) {
  .brandBox .brandItem_desktop {
    display: none;
  }
}

.brandBox a:focus {
  outline: none;
}

.brandBox .brandItem {
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  width: 20%;
  overflow: hidden;
  max-width: 10em;
}

.brandBox .brandItem img {
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
}
