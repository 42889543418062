.sidebarContentNotes:hover .listItem,
.listItem:hover ~ .listItem {
  opacity: 0.8;
}

.listItem:hover,
.sidebarContentNotes:hover .listItem:hover {
  opacity: 1;
  background: var(--color-blue-800);
}
