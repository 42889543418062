.cardBrandIcon {
  --card-brand-icon-size: 2.65;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--card-brand-icon-size) * 1rem);
  aspect-ratio: 1.5/1;
  background: white;
  border-radius: 0.3rem;
  border: 1px solid var(--color-blue-200);
  box-sizing: border-box;
}

.cardBrandIcon > svg {
  transform: scale(calc(var(--card-brand-icon-size) * 0.58));
}
