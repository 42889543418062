.items,
.labels {
  display: flex;
}

.labels {
  justify-content: space-between;
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.label + .label {
  text-align: right;
}

.items {
  margin-top: 0.5rem;
}

.item {
  flex-grow: 1;
  flex-basis: 1px;
  border: 0;
  background: var(--color-gray-100);
  padding: 0.5rem 0;
  cursor: pointer;
  border-right: 2px solid white;
}
.item:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}
.item:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
  border-right: 0;
}

.item_active {
  background: var(--color-teal-400);
  color: white;
}

.item:active,
.item:focus {
  outline-color: #1d867f;
  z-index: 1;
  border-color: transparent;
}
