.details {
  --transition-duration: 0.25s;
}

.summary {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--color-blue-500);
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  & svg {
    margin-left: 0.5rem;
    transform: rotate(180deg);
    transition: transform var(--transition-duration);
  }

  &:focus {
    outline: none;
  }
}

.summary_open {
  & svg {
    transform: rotate(0);
  }
}

.children {
  display: grid;
  grid-template-rows: 0fr;
  align-items: start;
  transition: grid-template-rows var(--transition-duration) ease-in-out;
  overflow: hidden;

  & :first-child {
    transition: opacity calc(var(--transition-duration) / 2) ease-in-out;
    transition-delay: 0;
    opacity: 0;
  }
}

.children_open {
  grid-template-rows: 1fr;

  & :first-child {
    opacity: 1;
    transition: opacity var(--transition-duration) ease-in-out;
    transition-delay: calc(var(--transition-duration) / 3);
  }
}

.childrenInner {
  overflow: hidden;
}
