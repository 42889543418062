.root {
  width: 100%;
  max-width: 20em;
  padding: 1em 1em;
  box-sizing: border-box;
  border: 2px solid var(--color-border-gray);
  display: block;
  border-radius: var(--border-radius-button);
  resize: none;
  line-height: 1.33;
}

.root:focus {
  /*border-color: var(--color-background-cyan-bravo);*/
  box-shadow: 0 0 0 2px var(--color-background-cyan-bravo);
  outline: none;
}

.error,
.root:focus .error {
  border-color: var(--color-border-error);
}

.fullWidth {
  max-width: none;
}
