.videoPlayer {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--color-gray-100);
}

.reactPlayerWrapper {
  position: relative;
  min-height: 0;
  height: 100%;
}

.videoProcessing {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  width: calc(100% - 2rem);
  max-width: 20em;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  background: var(--color-blue-700);
  padding: 1rem;
  border-radius: var(--border-radius-2);
}

.videoProcessionText {
  margin-top: 1rem;
}

.videoProcessingReloadButtonIcon {
  margin-right: 0.5rem;
}

.playerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.videoSubtitles {
  position: absolute;
  width: max-content;
  max-width: calc(100% - 2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.125rem 0.5rem;
  border-radius: var(--border-radius-2);
}

.statusFlash {
  animation: statusFlash3d 0.8s ease;
  animation-iteration-count: 1;
  user-select: none;
  background: rgba(0, 0, 0, 0.5);
  height: 1.5em;
  width: 1.5em;
  font-size: 4rem;
  border-radius: 50%;
  text-indent: -99em;
  position: absolute;
  top: 50%;
  left: 50%;

  /* Animated */
  opacity: 0;
}

@keyframes statusFlash3d {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0);
  }

  100% {
    transform: scale3d(1.25, 1.25, 1.25) translate3d(-40%, -40%, 0);
    opacity: 0;
  }
}

.statusFlash:after {
  content: "";
  display: block;
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(./PlayerControlBar/player-icon-sprite.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.statusFlash_play:after {
  background-position: 0 0;
}

.statusFlash_pause:after {
  background-position: -2em 0;
}
