.toggleBillingCycle {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 32px;
  background-color: white;
  box-shadow:
    0 6px 4px -4px rgba(0, 0, 0, 0.04),
    0 6px 28px rgba(0, 0, 0, 0.06);
  max-width: 13rem;
}

.toggleBillingCycle label {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--color-blue-600);
  font-size: var(--font-size-small);
  line-height: initial;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
  user-select: none;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  padding: 0.25rem;
}

.toggleBillingCycle label.activeAnnual {
  color: var(--color-primary-2);
  background: var(--color-primary-1);
}

.toggleBillingCycle label span {
  font-size: 0.75rem;
  color: hsla(168, 100%, 33%, 1);
}

.toggleBillingCycle label.activeAnnual span {
  color: hsla(170, 100%, 23%, 1);
}

.toggleBillingCycle label.activeMonthly {
  background: var(--color-primary-2);
  color: white;
}

.toggleBillingCycle input {
  display: none;
}
