.iconTextMessageWithButton {
  background: var(--color-blue-200);
  border-radius: var(--border-radius-2);
  color: var(--color-blue-800);
  padding: 1rem;
  position: relative;
}

.iconAndText {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconWrapper {
  background: var(--color-blue-600);
  border-radius: 1em;
  padding: 0.33em;
}

.icon {
  color: var(--color-blue-050);
  display: block;
}

.text {
  font-weight: normal;
}

.text a {
  color: inherit;
}

.title {
  font-size: 1em;
  color: inherit;
  margin: 0;
  font-weight: bold;
}

.title + .text {
  margin-top: 0.25rem;
}

.close {
  border: 0;
  padding: 0;
  top: 0.5rem;
  right: 0.5rem;
  position: absolute;
  color: var(--color-gray-600);
  cursor: pointer;
  overflow: hidden;
  text-indent: -10rem;
  background: url(./close.svg) no-repeat;
  background-size: contain;
  height: 1rem;
  width: 1rem;
}
