.avatar {
  height: 2.667em;
  width: 2.668em;
  border-radius: 2.668em;
  background: var(--avatar-background-color, var(--color-blue-100));
  color: var(--color-blue-600);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  flex-shrink: 0;
  position: relative;
  text-transform: uppercase;
  user-select: none;
}

.heartIcon {
  position: absolute;
  color: #f06363;
  font-size: 1em;
  bottom: 0;
  right: 0;
}
