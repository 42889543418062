.modalHeader {
  padding: 1.5rem 2.5rem 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
}

.collapsed {
  cursor: pointer;
}

.mainText {
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.info {
  margin: 0;
  font-size: 0.875rem;
  width: 100%;
}

.fakeLink {
  font-size: 0.875rem;
  margin-left: auto;
  color: var(--color-link);
}

.fakeLink:hover {
  text-decoration: underline;
}

.icon {
  font-size: 2rem;
  margin-right: 0.5rem;
  color: var(--color-logo-cyan);
}

.heading {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.heading_big {
  font-family: var(--font-family-serif);
  font-size: 1.5rem;
  font-weight: normal;
}

.credits {
  margin-left: auto;
  font-size: 0.875rem;
  color: var(--color-gray-600);
  white-space: nowrap;
}

.backButton {
  cursor: pointer;
  border: 0;
  padding: 0 0.5rem 0 0;
  color: var(--color-gray-600);
  background: 0;
}

.buttonCloseIcon {
  border: 0;
  padding: 0;
  top: 0.75rem;
  right: 0.75rem;
  position: absolute;
  color: var(--color-gray-600);
  cursor: pointer;
  overflow: hidden;
  text-indent: -10rem;
  background: url(./close.svg) no-repeat;
  background-size: contain;
  height: 1.375rem;
  width: 1.375rem;
}
