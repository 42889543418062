.videoPlayerWithSidebar {
  /* If value of --width-video-sidebar is updated, media query has to be updated manually as well */
  --width-video-sidebar: 23rem;
  /* Used in JS to query if mobile player is active */
  --is-mobile-player: 1;
  display: flex;
  flex-direction: column;
  background: var(--color-blue-900);
  color: var(--color-blue-100);
}

.videoPlayer {
  height: calc(100vh - 1.5 * var(--main-navigation-height));
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.header {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: var(--main-navigation-height);
}

.sidebar {
}

.videoPlayerLoading {
}

/*
 * Desktop player
 */

@media (min-width: calc(23rem * 1.8 + 23rem / 1.8)) {
  .videoPlayerWithSidebar {
    --is-mobile-player: 0;
    display: grid;
    grid-template-columns: 1fr var(--width-video-sidebar);
    grid-template-rows: auto 1fr;
    height: 100vh;
  }

  .tabs {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  .videoPlayer {
    grid-column: 1;
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;
    max-height: none;
    max-width: none;
    margin: 0;
  }

  /* Header spans video only, sidebar goes all the way up (on desktop) */

  .videoPlayerWithSidebar_headerSpansVideoOnly .header {
    grid-column: 1;
  }

  .videoPlayerWithSidebar_headerSpansVideoOnly .videoPlayer {
    grid-column: 1;
  }

  .videoPlayerWithSidebar_headerSpansVideoOnly .sidebar {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
}
