.sidebar {
  border-left: 1px solid #272742;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sidebarBody {
  margin-top: 2rem;
  height: 100%;
}

.clipLabeledIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  font-weight: 700;
  color: var(--color-blue-100);
}

.clipNote {
  margin-top: 0.5rem;
  color: var(--color-gray-100);
}

.clipTag {
  color: var(--color-gray-400);
}

.sidebarFooter {
  display: flex;
  flex-direction: column;
  background: var(--color-blue-700);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  align-items: center;
}

.watchEntireVideoDescription {
  font-size: var(--font-size-small);
  color: var(--color-gray-400);
  margin-top: 0.5rem;
  text-align: center;
}
