.editTimestamp {
  --line-height: 1.334;
  line-height: var(--line-height);
  display: flex;
}

.editTimestamp:hover {
  cursor: pointer;
}

.editTimestamp > button:first-child {
  margin-right: 0.5em;
}

.editTimestamp_empty {
  cursor: pointer;
}

.labelButton {
  /*clear button styles*/
  padding: 0;
  background: none;
  border: none;
  color: var(--color-blue-300);
  text-decoration: underline;
  cursor: pointer;
  line-height: inherit;
}

.labelButton:disabled {
  color: var(--color-blue-500);
  text-decoration: none;
}

.clearButton {
  border: 0;
  padding: 0;
  background: none;
  background-image: url(../icons/icon-clear.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: calc(1em * var(--line-height));
  width: calc(1em * var(--line-height));
  text-indent: -10em;
  overflow: hidden;
  cursor: pointer;
}

.clearButton:disabled {
  filter: brightness(0.5);
}
