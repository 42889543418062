.noteOrClipItem {
  cursor: pointer;
  --container-padding-left: 0.5rem;
  --container-padding-right: 0.5rem;
  padding: 0.5rem var(--container-padding-left) 0.5rem
    var(--container-padding-right);
  border-radius: var(--border-radius-2);
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
  display: flex;
  gap: 1.25rem;
  text-decoration: none;
}

.noteOrClipItem:focus-visible {
  outline: var(--focus-outline);
  outline-offset: 0;
}

.videoMeta {
  color: var(--color-blue-400);
  display: flex;
  gap: 0.25rem;
  align-items: baseline;
  margin-top: 0.5rem;
  line-height: 1;
}

.content {
}

.videoMetaIcon {
  align-self: center;
}

.videoMetaTimestamp {
  font-size: var(--font-size-small);
}

.ai {
  color: #6369ff;
  padding: 0.2rem 0.5rem;
  font-size: var(--font-size-small);
  font-weight: 600;
  border-radius: var(--border-radius-1);
  background: linear-gradient(
    36deg,
    #e9e2ff 1.62%,
    #d7f3ff 51.62%,
    #dbfffb 101.62%
  );
}

.avatarPlayIcon {
  transform: translateX(2px); /* Visual center */
  display: none;
}

.noteOrClipItem:hover {
  background-color: var(--color-blue-050);
  .videoMeta {
    color: var(--color-blue-600);
  }
  .avatarPlayIcon {
    display: block;
  }
  .avatarInitials {
    display: none;
  }
}
