.shareClip {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.header h4 {
  margin: 2rem;
  font-size: 24px;
  font-weight: 700;
}

.header button {
  background: none;
  align-items: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: none;
  margin: 1rem;
  color: var(--color-blue-500);
  transition: 0.25s;
}

.header button:hover {
  cursor: pointer;
  color: var(--color-blue-600);
  background: var(--color-blue-100);
}

.header button:active {
  background: var(--color-blue-200);
}

.link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
  background: var(--color-background);
  padding: 2rem;
  margin-top: 2.5rem;
  border-top: 1px solid #e9dfdf;
  box-sizing: border-box;
  border-bottom-right-radius: var(--border-radius-card);
  border-bottom-left-radius: var(--border-radius-card);
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 2rem;
}

.body_noFooter {
  padding-bottom: 2rem;
}

.bodyHeadingMetaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.bodyPosterButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.bodyPosterButtonContainer:hover * .videoThumbnailImageLoaderPlaceholder {
  opacity: 0;
}

.bodyPreviewButton {
  display: flex;
  align-items: flex-end;
  height: 1.5rem;
  color: var(--color-blue-400);
  font-size: var(--font-size-small);
  transition: 0.25s;
  text-decoration: underline;
}

.bodyPosterButtonContainer:hover > .bodyPreviewButton {
  color: var(--color-blue-900);
}

.videoPosterImage {
  filter: brightness(0.85);
  transition: filter 0.25s;
}

.bodyPosterButtonContainer:hover * .videoPosterImage {
  filter: brightness(0.75);
}

.bodyVideoMeta {
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
  margin-top: 0.5rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bodyNoteTag {
  color: var(--color-blue-400);
}

.videoPoster {
  width: 200px;
  aspect-ratio: 1.618/1;
  background: var(--color-blue-900);
  border-radius: var(--border-radius-button);
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  transition: 0.25s;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.videoPosterPlayIcon {
  position: absolute;
  z-index: 1;
}

.videoPosterGeneratingFeedback {
  color: var(--color-gray-400);
  font-size: var(--font-size-small);
}

.videoPosterDuration {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background: var(--color-gray-900);
  color: var(--color-gray-100);
  font-size: 10px;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  z-index: 1;
}

.footerIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4eae5;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 1.25rem;
  flex-shrink: 0;
  transition:
    background-color 0.5s,
    border-color 0.5s;
  border: 3px solid #e8deda;
  box-sizing: border-box;
}

.footerIconContainer > svg {
  transform: scale(1.25);
}

.footerIconContainer_public {
  background: #14e0af;
  border-color: #12d3a6;
}

.footerIconSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.isPublic {
  display: flex;
  flex-direction: row;
}

.selectIsPublicDropdownTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.selectIsPublicDropdownTrigger:hover {
  cursor: pointer;
}

.footerSelectDescriptionContainer {
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
}

.footerCopyLinkButton {
  border: none;
  background: var(--color-blue-900);
  color: var(--color-gray-100);
  font-weight: 700;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: var(--border-radius-button);
  padding: 0.75rem 1rem;
  transition: background-color 0.25s;
  flex-shrink: 0;
  gap: 0.5rem;
}

.footerCopyLinkButton:hover {
  cursor: pointer;
  background: var(--color-blue-700);
}

.footerCopyLinkButton:active {
  background: var(--color-blue-900);
}

.footerDescription {
  font-size: var(--font-size-small);
  color: var(--color-blue-400);
}

.selectIsPublicOption {
  padding: 0.5rem 1rem;
  transition: 0.25s;
  border-radius: var(--border-radius-card);
}

.selectIsPublicOption:not(:first-child) {
  margin-top: 0.25rem;
}

.selectIsPublicOption:hover {
  background: var(--color-blue-100);
  cursor: pointer;
}

.selectIsPublicDropdownTrigger:hover {
  cursor: pointer;
}

.selectIsPublicOption_active {
  background: #c7f6e7;
}

.selectIsPublicOption_active:hover {
  background: #a7f1d9;
}

.selectIsPublicOptionsContainer {
  padding: 0.25rem;
}

.selectIsPublicIconLabelContainer {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

@media (min-width: 500px) {
  .body {
    flex-direction: row;
  }
  .footer {
    flex-direction: row;
    align-items: center;
  }
}
