.content {
  padding: 1rem;
}

.title {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: -0.5rem;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  padding: 0.7rem;
  cursor: pointer;
  font-size: 2rem;
  font-weight: normal;
  width: 1rem;
  color: #868686;
  height: 1rem;
  box-sizing: content-box;
  line-height: 0.5;
}

.releaseNotesWrapper + .buttonGroup {
  margin-top: 2rem;
}

.releaseNotesWrapper {
  background: var(--color-gray-200);
  border: 2px solid var(--color-gray-300);
  border-radius: 0.25rem;
  max-height: 15rem;
  overflow: auto;
  margin-top: 2rem;
}

.releaseNotes {
  text-align: left;
  padding: 0 1rem 1rem 1rem;
}

.releaseNotes h2 {
  font-size: 1em;
  margin: 1rem 0 0 0;
}

.releaseNotes h3 {
  font-size: 0.875em;
  margin: 0.5rem 0 0 0;
}

.releaseNotes ul {
  font-size: 0.875em;
  margin: 0.5em 0 0 0;
  padding: 0 0 0 1em;
}

.releaseNotes li {
  margin-top: 0.3rem;
}

.readMore {
  margin: 0.5em 0 0 0;
  font-size: 0.875em;
}
