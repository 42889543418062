.menuNavigation {
  --icon-size: 24px;
}

.menu {
  background: white;
  border-radius: var(--border-radius-button);
  box-shadow: var(--shadow-dropdown);
  margin-top: 0.5rem;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.control {
  align-items: center;
  background: var(--color-secondary-2-darker);
  border-radius: var(--border-radius-button);
  display: flex;
  transition:
    background 0.25s,
    box-shadow 0.25s;
}

.control::after {
  background: url("./icon-dropdown.svg");
  content: "";
  height: var(--icon-size);
  margin-left: auto;
  margin-right: 1rem;
  transition: transform 0.25s;
  width: var(--icon-size);
}

.control[aria-expanded="true"] {
  box-shadow: var(--box-shadow-slight);
}

.control[aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.control[data-focused="true"] {
  background: white;
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}

.option,
.singleValue {
  color: var(--color-primary-2);
  display: flex;
  font-size: 1rem;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
}

.option[data-focused="true"] {
  background: var(--color-teal-050);
}

.option[aria-selected="true"] {
  background: var(--color-teal-400);
}

.singleValue {
  border-radius: var(--border-radius-button);
}

.option picture,
.option img,
.singleValue picture,
.singleValue img {
  height: var(--icon-size);
  width: var(--icon-size);
}

.option picture,
.singleValue picture {
  margin-right: 0.625rem;
  position: relative;
}

/* XXX: Img is wrapped in a picture to support pseudo-elements */
.option picture[data-notification="true"]::before,
.singleValue picture[data-notification="true"]::before {
  background-color: var(--color-secondary-1);
  border-radius: 100%;
  content: "";
  display: block;
  height: 0.5em;
  position: absolute;
  right: -0.2em;
  top: -0.2em;
  width: 0.5em;
}

/* XXX: Experimenting with data attributes. Is there a reason why
something like this is a bad idea? */
.option picture[data-notification="true"]::before {
  border: 0.05em solid white;
}

.singleValue picture[data-notification="true"]::before {
  border: 0.05em solid var(--color-secondary-2-darker);
}

.option[data-locked="true"]::after {
  background: url("./icon-lock-light.svg") no-repeat;
  content: "";
  height: var(--icon-size);
  margin-left: auto;
  width: var(--icon-size);
}

.option[data-locked="true"][aria-selected="true"]::after {
  background: url("./icon-lock-dark.svg") no-repeat;
}

.option:hover,
.control:hover {
  cursor: pointer;
}
