/*
  This could be just in Sidebar, but I wanted to do this as an experiment 
  having styles that are imported in the main component and used in a child component
  without importing the style modules multiple times.
 */
.buttonDarkmode {
  background: var(--color-blue-100);
}

.buttonDarkmode:hover {
  background: var(--color-blue-300);
}

.buttonDarkmode:active {
  background: var(--color-blue-100);
}
