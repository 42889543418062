.noHighlightsPlaceholder {
  opacity: 0.5;
  color: var(--color-blue-300);
  text-align: center;
  margin: auto 0;
  background: var(--color-blue-600);
  padding: 2rem;
  border-radius: var(--border-radius-2);
}

.title {
  font-size: 1rem;
}

.text {
  margin-top: 0.5rem;
  font-size: var(--font-size-small);
}
