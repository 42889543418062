.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  //border-bottom: 2px solid var(--color-gray-400);
  background: var(--color-border-on-light);
  border-radius: var(--border-radius-card);
}

.fieldLabel {
  font-size: var(--font-size-small);
}

.fieldTooltip {
  position: relative;
  bottom: -0.1em;
}

.fieldInput {
  width: 7em;
  border: 0;
  padding: 1.25em 1em;
  box-shadow: var(--box-shadow-slight);
}
