.ratingStars {
  display: block;
}

.star {
  display: inline-block;
  color: #fdd228;
}

.star + .star {
  margin-left: 0.05rem;
}

.star_empty {
  color: var(--color-gray-300);
}
