.content {
  padding: 0.5rem;
}
.test {
  border: 0;
  padding: 0;
  background: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  display: block;
  text-align: left;
  border-radius: var(--border-radius-1);
  cursor: pointer;
  padding: 0.5rem 0.88rem;
  line-height: 1.2;
}

.test + .test {
  margin-top: 0.2rem;
}

.test:focus {
  outline: none;
  background: var(--color-blue-050);
}

.test:hover {
  background: var(--color-blue-100);
}
