.root {
  text-align: center;
  margin: -0.3rem;
}

.page {
  display: inline-block;
  border-radius: 100rem;
  border: 0;
  min-width: 2.25em;
  min-height: 2.25em;
  line-height: 2.25em;
  font-size: 0.875rem;
  text-align: center;
  margin: 0.3rem;
  vertical-align: middle;
  padding: 0;
  cursor: pointer;
  background: var(--color-secondary-2-darker);
  color: var(--color-blue-600);
  font-weight: 600;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.2);
  /*box-shadow: var(--shadow-button);*/
  transition: var(--transition-button);
}

.page:hover {
  box-shadow:
    0 0 4px 0 rgba(0, 0, 0, 0.22),
    0 4px 8px 0 rgba(0, 0, 0, 0.22);
  transition: var(--transition-button-hover);
}

.page:focus {
  outline: none;
}

.page--current {
  cursor: default;
  color: #fff;
  background: var(--color-teal-600);
}

.page--placeholder {
  cursor: default;
  box-shadow: none;
  background: none;
  min-width: 1.25em;
}

.page--placeholder:hover {
  box-shadow: none;
}
