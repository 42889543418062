.content {
  padding: 0 1.25rem 1rem;
}

.content > *:first-child {
  margin-top: 0;
}

.buttonGroup {
  margin-top: 1.5rem;
}
