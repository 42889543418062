.testerOverview {
  /* Add your styles here */
}

.header {
  padding: 1rem 1.5rem;
  border: 1px solid var(--color-border-on-light);
  border-radius: var(--border-radius-2);
  display: flex;
  gap: 1rem;
  align-items: center;
}

.header_empty {
  justify-content: center;
}

.header_empty .headerText {
  font-style: italic;
}

.header_empty .headerContent {
  width: auto;
}

.headerContent {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.headerText {
  font-size: 1.25rem;
}

.headerIcon {
  /* Add your styles here */
}

.showScreenersButton {
  background: none;
  border: 0;
  padding: 0;
  font-size: 0.875rem;
  color: var(--color-blue-400);
  cursor: pointer;
  display: flex;
  gap: 0.25em;
  align-items: center;
}

.showScreenersButton:hover {
  color: inherit;
}

.statsSection {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .statsSection {
    grid-template-columns: repeat(2, 1fr);
  }
}

.statGroup {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid var(--color-border-on-light);
  border-radius: var(--border-radius-2);
}

.statTitle {
  margin: 0;
  font-size: 1.25rem;
  font-weight: normal;
}

.statTitle + .statItem {
  margin-top: 1rem;
}

.statItem {
  display: flex;
  gap: 1rem;
  align-items: baseline;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  font-size: var(--font-size-small);
}

.statItem + .statItem {
  border-top: 1px solid var(--color-border-on-light);
}

.statItemLabel {
  width: 40%;
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  color: var(--color-blue-600);
}

.statItemIcon {
  align-self: center;
  color: var(--color-blue-400);
}

.statItemMeter {
  --meter-height: 0.45rem;
  flex-grow: 1;
  align-self: center;
}

.statItemValue {
  color: var(--color-blue-400);
}

.footer {
  /* Add your styles here */
}

.goToTestersButton {
  margin-top: 1.5rem;
  width: 100%;
}

.flag {
  height: 1.25em;
  width: 1.25em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: sub;
  margin-right: 0.5em;
}
